import { render, staticRenderFns } from "./cz_history.vue?vue&type=template&id=bbf0b820&scoped=true"
import script from "./cz_history.vue?vue&type=script&lang=js"
export * from "./cz_history.vue?vue&type=script&lang=js"
import style0 from "./cz_history.vue?vue&type=style&index=0&id=bbf0b820&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bbf0b820",
  null
  
)

export default component.exports