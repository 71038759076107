<template>
<div class="about1212122">
  <div class="Site">
    <div  class="van-nav-bar van-nav-bar--fixed">
      <div class="van-nav-bar__left">
     
      </div>
      <div class="van-nav-bar__title van-ellipsis">My Task list</div>
      <div class="van-nav-bar__right"></div>
    </div>
    <van-tabs
      v-model="active"
      style="border-color: rgb(64, 135, 241); background: rgb(21, 29, 49);"
      @click="onClick"
    >
      <van-tab title="Doing" name=0>
        
      </van-tab>
      <van-tab title="Audit" name=1></van-tab>
      <van-tab title="Completed" name=2></van-tab>
      <van-tab title="Failed" name=3></van-tab>
    </van-tabs>

    <van-pull-refresh v-model="refreshing" @refresh="onRefresh" v-show="is_has" loading-text="lodding" lodding="lodding" loosing-text="Release can refresh" pulling-text="The drop-down to refresh">
          <van-list v-model="loading" :finished="finished" finished-text="No more" loading-text="lodding" @load="onLoad" >
            <!-- <van-cell  /> -->
            <div class="TaskItem van-cell van-cell--borderless"  v-for="item in list"
          :key="item.id"
          :title="item.title">
              <div  class="icon">
                <a  href="javascript:;">
                  <img
                    
                    src="../assets/images/202109071738498530674816.png"
                  />
                </a>
              </div>
              <div  class="van-cell__title record">
                <h4 >Task requirements：</h4>
                <!---->
                <p >Create：{{item.create_time}}</p>
                <p >Audit：{{item.update_time}}</p>
                <p  class="href">
                  <a
                    
                    :href="item.task.url"
                    target="_blank"
                  >Open the link</a>
                  <a  href="javascript:;">Copy the link</a>
                </p>
                <span
                  
                  id="IosLink0"
                  style="position: absolute; opacity: 0;"
                >{{item.task.url}}</span>
                <input
                  
                  id="AppLink0"
                  type="text"
                  style="position: absolute; opacity: 0;"
                />
                <div style="flex-direction: column; align-items: flex-start; padding: 0px; color: rgb(117, 138, 176); margin-top: 5px;" >
                  <div style="width: 100%; word-break: break-word;color: rgb(117, 138, 176);"
                  >{{item.task.url}}</div>
                  <div  style="margin-top: 3px;">
                    <button
                      v-on:click="to_link(item.task.url)"
                      class="van-button van-button--info van-button--mini van-button--round"
                    >
                      <div  class="van-button__content" >
                        <span  class="van-button__text">Task link</span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
              <div  class="van-cell__value">
                <div  class="state">
                  <div  class="price">
                    Unit Price
                    <p >₹{{item.task.price}}</p>
                  </div>
            
                  <button v-if="item.status==0" @click="to_sub(item.id)"
             class="van-button van-button--info van-button--mini van-button--round"
                  >
                    <div  class="van-button__content" >
                      <span  class="van-button__text" >Submit</span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </van-list>
        </van-pull-refresh>
        <div data-v-40838d58 role="tabpanel" class="van-tab__pane" style="padding: 0px 12px;" v-show="!is_has">
          <div data-v-40838d58 class="van-pull-refresh">
            <div class="van-pull-refresh__track" style="transition-duration: 0ms;">
              <div class="van-pull-refresh__head"></div>
              <div
                data-v-40838d58
                role="feed"
                class="van-list Empty"
                pulling-text="pulling"
                loosing-text="loosing"
              >
                <div data-v-40838d58></div>
                <div class="van-list__finished-text">No data</div>
                <div class="van-list__placeholder"></div>
              </div>
            </div>
          </div>
        </div>
        </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Tab, Tabs } from "vant";
import { List } from "vant";
import { Toast } from "vant";
import * as api from '@/api';
import * as local from "@/libs/local";
Vue.use(List);
Vue.use(Toast);
Vue.use(Tab);
Vue.use(Tabs);
export default {
  data() {
    return {
       is_has:1,
      active: 0,
      page:1,
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      is_jia:false
    };
  },
  methods: {
    to_link(d){
           Toast.loading({
        message: "loading...",
        forbidClick: true
      });
 window.location.href = d
    },
    to_sub(d){
           Toast.loading({
        message: "loading...",
        forbidClick: true
      });
    api.in_task({
          token: local.readInfo('jwt'),
          tid:d,
        }, (err, data) => {
          if (!err && data.code === 1) {
                 Toast(data.msg)
                  this.onLoad(true);
          } else {
              console.log(data)
           if(data&&'msg' in data){
             Toast(data.msg)
           }
           // this.logining = false
         
          }
        
        })

    },
    onLoad(name=false) {
         if(this.is_jia){return false}
      this.is_jia=true
      // setTimeout(() => {
      //   if (this.refreshing) {
      //     this.list = [];
      //     this.refreshing = false;
      //   }

      //   for (let i = 0; i < 10; i++) {
      //     this.list.push(this.list.length + 1);
      //   }
      //   this.loading = false;

      //   if (this.list.length >= 40) {
      //     this.finished = true;
      //   }
      // }, 1000);
      
      if(name){
        this.list=[];
        this.page = 1
         this.is_has=1
             this.finished = false;
      }
            api.get_my_task({
          token: local.readInfo('jwt'),
          type:this.active,
          page:this.page
        }, (err, data) => {
          this.loading= false
               this.refreshing = false;
               this.is_jia=false
          if (!err && data.code === 1) {
                   let oldArr = this.list
                   let newArr = data.data.data
                   this.list = oldArr.concat(newArr)
                   this.page++
                   if((data.data.last_page-data.data.current_page)<=0){
                       this.finished = true;//this.is_has=0
                   }
                  if(data.data.total<=0){
                      this.is_has=0
                   }
          } else {
              console.log(data)
           if(data&&'msg' in data){
             Toast(data.msg)
           }
           // this.logining = false
         
          }
        
        })
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
       if(this.logining){return}
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
     this.onLoad(true);
    },
    onClick(name) {
     // Toast(name + "已被禁用" + title);
          if(this.logining){return}
     this.list=[];
     this.active=name
      this.onLoad(true)
    }
  }
};
</script>

<style>

</style>