<template>
  <div class="about4433333">
    <div data-v-2ac32f0d data-v-4d9388c2 class="Site PageBox">
      <div data-v-2ac32f0d class="van-nav-bar van-nav-bar--fixed">
        <div class="van-nav-bar__left" v-on:click="back">
          <i class="van-icon van-icon-arrow-left van-nav-bar__arrow">
            <!---->
          </i>
        </div>
        <div class="van-nav-bar__title van-ellipsis">Announcement</div>
        <div class="van-nav-bar__right"></div>
      </div>
      <div data-v-31267190 class="ScrollBox">
        <div data-v-31267190 class="content" style="padding: 10px 20px 20px;">
 
     <p><strong>    Invite member top-up, obtain the top-up amount 10%</strong> </p>




 <p><strong> Invite secondary member top-up, obtain the top-up amount 5%</strong> </p>
  <p>
            <br />
          </p>
 <p><strong> Invited tertiary member top-up, obtain the top-up amount 3%</strong> </p>


  <p>
            <br />
          </p>
 <p><strong> Upgrade to any other levels only need to pay the difference</strong> </p>

  <p>
            <br />
          </p>
 <p><strong> Inviter level one member every day tasks 6% commission</strong> </p>

  <p>
            <br />
          </p>
 <p><strong> Every day for the secondary member task the task of our 3% commission</strong> </p>
  <p>
            <br />
          </p>
 <p><strong> Every day for level 3 members task the task of our 2% commission</strong> </p>

  <p>
            <br />
          </p>


 <p><strong> Silver level above task award, the inviter ranks below the invitee no quest rewards. If the invitation to rise to level (equal to or greater than) were invited, invited to get mission rewards.</strong> </p>

         
          <p>
            <br />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
 <script>
export default {
  data() {
    return {
      radio: "1"
    };
  },
  methods: {
    back() {
      this.$router.go(-1); //返回上一层
    }
  }
};
</script>
<style scoped>
.Content p{color:white;text-align: left;}
.Menu[data-v-bef8e3fc] {
  border-top: 10px solid #0e1526;
  margin-bottom: 15px;
}
.m0 {
  margin: 0 !important;
}
.van-grid-item__text {
  font-size: 0.875rem;
  margin-top: 0.5rem;
  color: #000;
}
.van-grid-item__text {
  color: #646566;
  font-size: 12px;
  line-height: 1.5;
  word-wrap: break-word;
}
.van-icon,
.van-icon:before {
  display: inline-block;
}
.van-icon {
  position: relative;
  font: normal normal normal 14px/1 vant-icon;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}
.van-icon__image {
  width: 1em;
  height: 1em;
  object-fit: contain;
}
.Menu[data-v-bef8e3fc] .van-grid-item__text {
  font-size: 15px;
  color: #fff;
  text-align: center;
}
.van-grid-item__icon + .van-grid-item__text {
  margin-top: 8px;
}
.MyEarnings .van-grid-item__content {
  color: #4087f1;
  font-size: 1.125rem;
  text-align: center;
  background-color: #151d31;
}
.Main .van-grid-item__content {
  background-color: #151d31;
}
.MyEarnings .van-grid-item__icon-wrapper {
  color: #bbb;
  margin-bottom: 0.3125rem;
  font-size: 0.875rem;
}
.van-grid-item__icon-wrapper {
  position: relative;
}
body {
  background-color: #0e1526;
}
.Main {
  position: relative;
}
.Main {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.IndexBox {
  overflow: hidden;
  padding-bottom: 3.25rem;
}
.IndexBox,
.ScrollBox {
  position: relative;
  width: 100%;
  height: 100%;
}
.IndexBox,
.ScrollBox {
  position: relative;
  width: 100%;
  height: 100%;
}
.ScrollBox {
  overflow-x: hidden;
  overflow-y: auto;
}
.van-nav-bar {
  background-color: transparent !important;
  color: white !important;
}
.van-nav-bar {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: 46px;
  line-height: 1.5;
  text-align: center;
  background-color: #fff;
  -webkit-user-select: none;
  user-select: none;
}
.van-nav-bar__left {
  left: 0;
  padding: 0 0.625rem;
}
.van-nav-bar__left {
  left: 0;
}
.van-nav-bar__left,
.van-nav-bar__right {
  position: absolute;
  top: 0;
  bottom: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 0 16px;
  font-size: 14px;
  cursor: pointer;
}
.van-nav-bar__title {
  max-width: 60%;
  margin: 0 auto;
  color: #323233;
  font-weight: 500;
  font-size: 16px;
}
.van-nav-bar__title {
  font-size: 1.125rem;
  color: #fff;
}
.van-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.van-nav-bar__right {
  right: 0;
  padding: 0 0.625rem;
}
.van-nav-bar__right {
  right: 0;
}
.van-nav-bar__left,
.van-nav-bar__right {
  position: absolute;
  top: 0;
  bottom: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 0 16px;
  font-size: 14px;
  cursor: pointer;
}
.UserInfo .info[data-v-bef8e3fc] {
  background-color: #151d31;
  color: #fff;
  position: relative;
  z-index: 3;
}
.ScrollBox .van-cell {
  line-height: 1;
}
.UserInfo .info .head[data-v-bef8e3fc] {
  margin-right: 10px;
}
img {
  display: inline-block;
  vertical-align: top;
}
.UserInfo .info .van-cell__title[data-v-bef8e3fc] {
  font-size: 16px;
  text-align: left;
}
.van-cell__title,
.van-cell__value {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
}
.UserInfo .info .van-cell__label[data-v-bef8e3fc] {
  font-size: 14px;
  color: #fff;
}
.van-cell__label {
  margin-top: 4px;
  color: #969799;
  font-size: 12px;
  line-height: 18px;
}
element.style {
}
.UserInfo .info .van-cell__value[data-v-bef8e3fc] {
  flex: none;
  line-height: 1.3;
}
.van-cell__value {
  position: relative;
  overflow: hidden;
  color: #969799;
  text-align: right;
  vertical-align: middle;
  word-wrap: break-word;
}
.van-cell__title,
.van-cell__value {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
}
.UserInfo .info .van-cell__value a[data-v-bef8e3fc] {
  text-align: center;
  color: #fff;
  display: block;
}

.UserInfo .money[data-v-bef8e3fc] {
  padding: 0 16px;
  position: relative;
  z-index: 2;
  width: 100%;
  box-sizing: border-box;
}
.UserInfo .money > div[data-v-bef8e3fc] {
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 5px #888;
  overflow: hidden;
  width: 100%;
  position: relative;
  z-index: 2;
  padding: 0 5px;
  box-sizing: border-box;
}
.UserInfo .money[data-v-bef8e3fc]:before {
  content: "";
  display: block;
  background-color: #151d31;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: -50%;
}
.UserInfo .money .van-cell[data-v-bef8e3fc] {
  background-color: #fff;
}
.van-cell__title,
.van-cell__value {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
}
.UserInfo .money .van-cell__title em[data-v-bef8e3fc] {
  font-size: 22px;
  color: #4087f1;
}
.van-button {
  cursor: pointer;
}
.van-button--mini {
  height: 1.625rem;
  background-color: #1989fa !important;
  border: 0.0625rem solid #1989fa !important;
}
em,
i {
  font-style: normal;
}
.Site .van-cell {
  align-items: flex-start;
}
.Site .van-cell {
  background-color: #151d31;
  color: #bbb;
}
.ScrollBox .van-cell {
  line-height: 1;
}
.Content img{
  display: block!important;
  width: 100%!important;
  margin: 20px auto;
}
</style>
