<template>
    <div class="content">
    <van-nav-bar
  :title="langs.t1"
  :left-text="langs.t5"
  left-arrow
  @click-left="onClickLeft"
/>
<!-- <div class="shuye">
        <img src="../assets/img1/nc_bg.png" />
      </div> -->
<div style="height: 10px;"></div>
<div class="zhifu public_flex" :class="'zhifu1'">
     <div class="zf1"  @click="show_z">
        {{ langs.t2 }}

     </div>
     <div class="zf2"  @click="cz">
      {{ langs.t3 }}
     </div>
</div>

<div style="height:20px"></div>

<div class="list">
  <div class="title">{{langs.t11}}</div>
  <van-pull-refresh v-model="refreshing" :loading-text="langs.tp13"
    lodding="lodding" @refresh="onRefresh" v-show="is_has" 
    :loosing-text="langs.tp14"
    :pulling-text="langs.tp16">

<van-list v-model="loading" :finished="finished" :finished-text="langs.tp15" @load="onLoad" 
:loading-text="langs.tp13" lodding="lodding" >
  <!-- <van-cell  /> -->

  <div
    data-v-40838d58
    class="cz_list"
    v-for="item in list"
    :key="item.id"
    :title="item.id"
  >
    <!-- <div class="icon tag0">I</div> -->
    <div  class="order_no public_flex">
      <div class="order">{{item.order_no}}
      </div>
       <div class="status">
         <div class="kuang one_hide">{{langs.t4}}:
        <span v-if="active==2&&item.status==0">{{ langs.t6 }}</span>
           <span v-else-if="active==2&&item.status==1">{{ langs.t7 }}</span>
           <span v-else-if="active==2&&item.status==2">{{ langs.t7 }}</span>
            <span v-else>{{ langs.t8}}</span>
          </div>
       </div>
       </div>
       <div class="date"> {{langs.t9}}: {{item.create_time}}</div>
      <div class="money public_flex">
        <div class="money1">{{langs.t10}}</div>
         <div class="money3">{{item.price}}</div>
      </div>
    </div>

</van-list>

</van-pull-refresh>
<van-empty :description="langs.tp15" v-if="list.length<=0"/>
</div>







<div style="height:80px"></div>

<Footer :lang_list="lang_list" :lang="langs" />

  </div>
  
</template>
 
<script>
import Vue from "vue";
import { Tab, Tabs } from "vant";
import { List } from "vant";
import { Toast } from "vant";
import * as api from '@/api';
import * as local from "@/libs/local";
import { Form } from 'vant';
import { Field } from 'vant';
import { Dialog } from 'vant';
import { NavBar } from 'vant';
import { Empty } from 'vant';
import Footer from './lyout/footer.vue';
Vue.use(Empty);
Vue.use(NavBar);
Vue.use(Form);
Vue.use(Field);
Vue.use(List);
Vue.use(Toast);
Vue.use(Tab);
Vue.use(Tabs);
export default {
    name: "Home123213",
    components: {
    //HelloWorld
    Footer,
  },
  data() {
    return {
       is_has:1,
      active: 2,
      col:["TRC wallet","Bank card"],
      showPicker1: false,
      value1:'Bank card',
      index1:1,
      page:1,
      list: [],
      huilv:{
        huilv:0
      },
      price2:10,
      loading: false,
      finished: false,
      refreshing: false,
      price:10,
      password:'',
      show1_1: false,
      is_jia:false,
      show12: false,
      user_info:{
        yue:0.00
        },
     sub: false,
     lang:0,
     text1:["back","يعود","retour"],
     text2:["deposit","ملء","recharger"],
     text3:["Detailed record","سجل الشحن","Enregistrement de recharge"],
     text4:["status","ولاية","statut"],
     text5:["Date","تواريخ","Date"],
     text6:["Amount","الأسعار","Prix"],
     text7:["No more","ديگه نه","Pas plus"],
     status1:["To be paid","انتظر الدفع","Être payé"],
     status2:["Pay to complete","دفع كامل","Payer pour terminer"],
     status3:["Pay for failure","دفع ثمن الفشل","Payer pour l'échec"],
     lang_list: [],
    select_lang: {},
    langs: {},
    cate: [],
 
    };
  },

  created() {
      this.lang=local.readInfo('setting_lang')==undefined||local.readInfo('setting_lang')==null?0:local.readInfo('setting_lang')
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1); //返回上一层
    },   
    onClickRight: function () {
      let _this = this;
      _this.$router.push({ name: "team", query: { redId: 1111 } });
    },
    
    onConfirm1(value, index) {
      this.value1 = value;
      this.index1=index
      this.showPicker1 = false;
      console.log(index)
      console.log(value)
        },
    onLoad(name=false) {
      if(this.is_jia){return false}
      this.is_jia=true
      // setTimeout(() => {
      //   if (this.refreshing) {
      //     this.list = [];
      //     this.refreshing = false;
      //   }

      //   for (let i = 0; i < 10; i++) {
      //     this.list.push(this.list.length + 1);
      //   }
      //   this.loading = false;

      //   if (this.list.length >= 40) {
      //     this.finished = true;
      //   }
      // }, 1000);
      
      if(name){
        this.list=[];
        this.page = 1;
         this.finished = false;
         this.is_has=1
      }
            api.get_my_mingxi({
          token: local.readInfo('jwt'),
          type:this.active,
          lang:local.readInfo('car_lang'),
          page:this.page
        }, (err, data) => {
               this.loading= false
               this.refreshing = false;
               this.is_jia=false
          if (!err && data.code === 1) {
            this.lang_list = data.lang_list;
              this.select_lang = data.select_lang;
               this.langs = data.lang;
                   let oldArr = this.list
                   let newArr = data.data.data
                   this.list = oldArr.concat(newArr)
                   this.user_info=data.data.user
                  this.page++
                   if((data.data.last_page-data.data.current_page)<=0){
                        this.finished = true;
                   }
                 
                   if(data.data.total<=0){
                      this.is_has=0
                   }
          
          } else {
              console.log(data)
           if(data&&'msg' in data){
             Toast(data.msg)
           }
          
         
          }
        
        })
    },
       back() {
      this.$router.go(-1); //返回上一层
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
       if(this.logining){return}
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad(true);
    },
    onClick(name) {
     // Toast(name + "已被禁用" + title);
        if(this.logining){return}
     this.active=name
     this.list=[];
     this.page=1
     console.log(name);
      this.onLoad(true)
    },
    show1:function(){
        this.show1_1=true
      },
      cz: function () {
      let _this = this;
      _this.$router.push({ name: "usdt", query: { redId: 1111 } });
    },
    show_c:function(){
        this.show12=true
      },
       show_z:function(){
      
        // Toast(this.lang==0?"The current system is not open for online payment":this.lang==1?"النظام الحالي لا يفتح الدفع عبر الإنترنت":
        // "Le système actuel n'est pas ouvert au paiement en ligne");
       let _this = this;
       _this.$router.push({ name: "top-up", query: { redId: 1111 } });
      },
    onSubmit(values) {
      //console.log('submit', values);
     if(this.sub){return}
     this.sub=true
       api.tixian({
          token: local.readInfo('jwt'),
          money:values.price,
          tx_type:this.index1,
          pwd:values.password
        }, (err, data) => {
          this.sub=false
          if (!err && data.code === 1) {
               
               Dialog.alert({
                message: data.msg,
                confirmButtonText:this.lang==0?'I know the':this.lang==1?"أنا أعرف":"Je connais le"
             }).then(() => {
                this.onLoad(true)
            });
          
          } else {
              console.log(data)
           if(data&&'msg' in data){
             Toast(data.msg)
           }
          
         
          }
        
        })
    },
  }
};
</script>

<style scoped>
.home222 {
  background:  #f7fbe1;
  max-width: 750px;
  margin: 0 auto;
}
.content {
  width: 100%;
  height: auto;
  min-height: 1624px;
  background:  #f7fbe1;
  background-size: 100%;
}
.people{
  width:700px;
  margin: 0 auto;
  background: white;
  border-radius: 20px;
  overflow: hidden;
  height: 280px;
}
.shuye {
  width: 100%;
  height: 122px;
  position: fixed;
  bottom: 0px;
  left: 0;
}
.shuye img {
  width: 100%;
}
.dajiange{
  height: 85px;
}
.zhifu{
  width: 100%;
  height: 280px;
  background-size: 100%;
}
.zhifu1{  background: url("../assets/img1/cz_btn.png") no-repeat;  background-size: 100%;}

.zhifu2{  background: url("../assets/img1/cz_alb.png") no-repeat;  background-size: 100%;}
.zhifu3{  background: url("../assets/img1/cz_bs.png") no-repeat;  background-size: 100%;}
.zhifu .zf1{
  width:50%;
  height: 203px;
  line-height: 28px;
  font-size: 24px;
  box-sizing: border-box;
  color: #8b00fd;
  font-weight: 700;
  padding: 20px 40px;
}
.zhifu .zf2{
  width:50%;
  height: 203px;
  line-height: 28px;
  font-size: 24px;
  color: #8b00fd;
  font-weight: 700;
  box-sizing: border-box;
  padding: 20px 40px;
}

.list{
  width:93%;
  margin: 0 auto;
  min-height: 400px;
  background: #fff;
  border-radius: 25px;

}
.list .title{
  text-align: center;
  color:#666;
  font-size: 28px;
  line-height: 40px;
}

.list .cz_list{
  width:95%;
  height: 200px;
  background: white;
  border-radius: 20px;
  margin: 0 auto;
  margin-top: 20px;
  padding: 5px;
  box-sizing: border-box;
  box-shadow: 3px 5px 10px 1px #dbdbdb;

}
.list .cz_list .order_no{
    width: 100%;
    height: 35px;
    padding-top: 20px;
  }
  .list .cz_list .order_no .order{
    width:50%;
    background: url("../assets/img1/dd.png") no-repeat;
   background-size: 24px 30px;
   padding-left: 30px;
   box-sizing: border-box;
   height: 35px;
   text-align: left;
   font-size: 24px;
   font-weight: 700;
   color:#333;
   line-height: 35px;
  }
  .list .cz_list .order_no .status{
    width: 50%;
    height: 35px;
  }
  .list .cz_list .order_no .status .kuang{
    width: 100%;
    height: 33px;
    border: 1px solid #e4b025;
    line-height: 33px;
    font-size: 12px;
    color:#e4b025;
    border-radius: 7px;
  }
  .list .cz_list .date{
    line-height: 35px;
    text-align: left;
    font-size: 24px;
   font-weight: 100;
   color:#999999;
   margin-top: 20px;
  }
  .list .cz_list .money{
    width: 100%;
    height: 35px;
    padding-top: 20px;
  }
  .list .cz_list .money .money1{
    width:50%;
    text-align: left;
    font-size: 24px;
   font-weight: 100;
   color:#999999;
 
  }
  .list .cz_list .money .money3{
    width:50%;
    text-align: right;
    font-size: 24px;
   font-weight: 100;
   color:#f13b17;
  }
</style>