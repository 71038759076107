<template>
  <div class="content">
  <van-nav-bar
  :title="text2[lang]"
:left-text="text1[lang]"
  left-arrow
  @click-left="onClickLeft"
/>
<div class="shuye">
      <img src="../assets/img1/nc_bg.png" />
    </div>

<!-- <div class="zhifu public_flex">
   <div class="zf1"  @click="show_z"></div>
   <div class="zf2"  @click="cz"></div>
</div> -->

<div style="height:20px"></div>
<div class="list">
  <div style="height:20px"></div>
<div class="title">{{text2[lang]}}</div>

<!-- <van-cell  /> -->
<van-pull-refresh v-model="refreshing" @refresh="onRefresh" :loading-text="lang==0?'lodding':lang==1?'لودنج':'soudure'"
lodding="lodding"      :loosing-text="lang==0?'Release can refresh':lang==1?'الإصدار يمكن أن تحديث':'La version peut rafraîchir'"
    :pulling-text="lang==0?'The drop-down to refresh':lang==1?'الدسم لأسفل لتحديث':'La liste déroulante pour rafraîchir'">
  <van-list
    v-model="loading"
    :finished="finished"
    finished-text="No more" @load="onLoad" :loading-text="lang==0?'lodding':lang==1?'لودنج':'soudure'"
 lodding="lodding"
  >
  <div class="cz_list"  v-for="(item,index) in list" :key="index">
  <!-- <div class="icon tag0">I</div> -->
  <div  class="order_no public_flex">
    <div class="order">{{lang==0?item.title:lang==1?item.alb_name:item.xby_name}}
    </div>
     <div class="status">
      <div class="kuang">{{ item.log }}star</div>
     </div>
     </div>
     
    <div class="money public_flex">
      <div class="money1">{{text4[lang]}}</div>
       <div class="money3">{{lang==0?item.yw_msg:lang==1?item.alb_msg:item.xby_msg}}</div>
    </div>
    <div class="date public_flex">  
      {{item.create_time}}
    </div>
  </div>

  </van-list>
</van-pull-refresh>


  <div style="height:20px"></div>
  <van-empty :description="lang==0?'No data':lang==1?'لايوجد بيانات':'Pas de données'" v-if="list.length<=0"/>

</div>







<div style="height:80px"></div>



</div>

</template>

<script>
import Vue from "vue";
import { Tab, Tabs } from "vant";
import { PullRefresh } from 'vant';
import { List } from "vant";
import { Toast } from "vant";
import * as api from '@/api';
import * as local from "@/libs/local";
import { Form } from 'vant';
import { Field } from 'vant';
import { Dialog } from 'vant';
import { NavBar } from 'vant';
import { Empty } from 'vant';
import { Loading } from 'vant';
Vue.use(NavBar);
Vue.use(Form);
Vue.use(Field);
Vue.use(List);
Vue.use(PullRefresh);
Vue.use(Toast);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Empty);
Vue.use(Dialog);
Vue.use(Loading);
export default {
  name: "Home123213",
components: {
  //HelloWorld
},
data() {
  return {
 
    user:{},
    lists:[],
    sub: false,
    list:[],
    loadin:false,
    loading: false,
    finished:false,
    refreshing:false,
    page:1,
    is_jia:false,
    is_has:0,
    lang:0,
    text1:["back","رد : .","retour"],
    text2:["Seed record","سجل البذور","Record de plantation"],
    text4:["Changes that","تغير","source"],
  };
},
created() {
 // this.get_imdex_data()
   this.lang=local.readInfo('setting_lang')==undefined||local.readInfo('setting_lang')==null?0:local.readInfo('setting_lang')
},
methods: {
  onLoad(name=false) {
          if(this.is_jia){return false}
      this.is_jia=true
      if(name){
        this.list=[];
        this.page = 1
         this.is_has=1
        this.finished = false;
      }
            api.myzz({
          token: local.readInfo('jwt'),
          page:this.page
        }, (err, data) => {
             this.loading= false
               this.refreshing = false;
               this.is_jia=false
          if (!err && data.code === 1) {
                   let oldArr = this.list
                   let newArr = data.data.data.data
                   this.list = oldArr.concat(newArr)
                   this.page++
                   if((data.data.data.last_page-data.data.data.current_page)<=0){
                       this.finished = true;//this.is_has=0
                   }
                  if(data.data.data.total<=0){
                      this.is_has=0
                   }
          } else {
              console.log(data)
           if(data&&'msg' in data){
             Toast(data.msg)
           }
           // this.logining = false
         
          }
        
        })
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
       if(this.logining){return}
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad(true);
    },
  // get_imdex_data:function(){
  //       api.myzz({
  //       token: local.readInfo('jwt'),
  //       }, (err, data) => {
  //         if (!err && data.code === 1) {
  //             this.user=data.data.user
  //             this.lists=data.data.list
  //             this.list=data.data.list
  //         } else {
  //             console.log(data)
  //             Toast("Network error")
  //             this.sub = false
         
  //         }
        
  //       })
  //   },
  onClickLeft() {
    this.$router.go(-1); //返回上一层
  },   
  onClickRight: function () {
    let _this = this;
    _this.$router.push({ name: "my_props", query: { redId: 1111 } });
  },
 
}
};
</script>

<style scoped>
.home222 {
background-color: #79c752;
max-width: 750px;
margin: 0 auto;
}
.content {
width: 100%;
height: auto;
min-height: 1624px;
background: url("../assets/img1/my_bg.jpg") no-repeat #79c752;
background-size: 100%;
}
.people{
width:700px;
margin: 0 auto;
background: white;
border-radius: 20px;
overflow: hidden;
height: 280px;
}
.shuye {
width: 100%;
height: 122px;
position: fixed;
bottom: 0px;
left: 0;
}
.shuye img {
width: 100%;
}
.dajiange{
height: 185px;
}
.zhifu{
width: 100%;
height: 203px;
background: url("../assets/img1/cz_btn.png") no-repeat;
background-size: 100%;
}
.zhifu .zf1{
width:50%;
height: 203px;
}
.zhifu .zf2{
width:50%;
height: 203px;
}

.list{
width:700px;
margin: 0 auto;
min-height: 400px;
background: #fff;
border-radius: 25px;

}
.list .title{
text-align: center;
color:#666;
font-size: 28px;
line-height: 40px;
}

.list .cz_list{
width:670px;
height: 200px;
background: white;
border-radius: 20px;
margin: 0 auto;
margin-top: 20px;
padding: 5px;
box-sizing: border-box;
box-shadow: 3px 5px 10px 1px #dbdbdb;

}
.list .cz_list .order_no{
  width: 100%;
  height: 35px;
  padding-top: 20px;
}
.list .cz_list .order_no .order{
  width:50%;
  background: url("../assets/img1/dd.png") no-repeat;
 background-size: 24px 30px;
 padding-left: 30px;
 box-sizing: border-box;
 height: 35px;
 text-align: left;
 font-size: 24px;
 font-weight: 700;
 color:#333;
 line-height: 35px;
}
.list .cz_list .order_no .status{
  width: 50%;
  height: 35px;
}
.list .cz_list .order_no .status .kuang{
  width: 230px;
  height: 33px;
  border: 1px solid #e4b025;
  padding:0 15px;
  line-height: 33px;
  font-size: 12px;
  color:#e4b025;
  margin-left: 60px;
  border-radius: 7px;
}
.list .cz_list .date{
  line-height: 35px;
  text-align: left;
  font-size: 24px;
 font-weight: 100;
 color:#999999;
 margin-top: 20px;
}
.list .cz_list .money{
  width: 100%;
  height: 35px;
  padding-top: 20px;
}
.list .cz_list .money .money1{
  width:50%;
  text-align: left;
  font-size: 24px;
 font-weight: 100;
 color:#999999;

}
.list .cz_list .money .money3{
  width:50%;
  text-align: right;
  font-size: 24px;
 font-weight: 100;
 color:#f13b17;
}
.tixian >>>.van-field__label{
width: 7.2em;
}
.tixian >>> .van-field__body {
border: 1px solid rgb(222, 221, 221);
}
.tixian{
width:700px;
margin: 0 auto;
min-height: 400px;
background: white;
border-radius: 25px;
padding: 25px 0;

}
</style>