/**
 * 计算元素距离文档顶部和文档左边的距离
 * @param {object} ele 要计算的元素
 * @returns {object} 分为top和left字段
 */
export const getOffsetSum = function(ele) {
    var top = 0
    var left = 0
    while (ele) {
        top += ele.offsetTop
        left += ele.offsetLeft
        ele = ele.offsetParent
    }
    return {
        top: top,
        left: left
    }
}

export function getUrlKey(name, url) {
    if (url.indexOf(name) != -1) {
        return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(url) || [""])[1].replace(/\+/g, '%20')) || null
    } else {
        return "0000"
    }
}
/**
 * 头像地址进行html转换
 * @export
 * @param {String} str 待转换html
 * @returns {String} 转换结果
 */
export function htmlEncode(str) {
    str = str.replace(/>/g, '&gt;')
    str = str.replace(/</g, '&lt;')
    str = str.replace(/"/g, '&quot;')
    str = str.replace(/'/g, '&#39;')
    str = str.replace(/%3c/i, '&lt;')
    str = str.replace(/%3e/i, '&gt;')
    return str
}

/**
 * 判断字符串是否是空，字符串是 null, undefind, 'null', ''四种情况都会返回true
 * @export
 * @param {String} str 待检测的字符串
 * @returns {Boolean}
 */
export function isStringEmpty(str) {
    return str === null || str === undefined || str === 'null' || str === ''
}

/**
 * 验证手机号码
 * @export
 * @param {Number} num 待判断的手机号码
 * @returns {Boolean}
 */
export function validatePhone(num) {
    return /^1\d{10}$/.test(num)
}

/**
 * 验证身份证号码
 * @export
 * @param {Number} idCard 待判断的身份证号码
 * @returns {Boolean}
 */
export function validateIdCard(idcard) {
    var vcity = { 11: '北京', 12: '天津', 13: '河北', 14: '山西', 15: '内蒙古', 21: '辽宁', 22: '吉林', 23: '黑龙江', 31: '上海', 32: '江苏', 33: '浙江', 34: '安徽', 35: '福建', 36: '江西', 37: '山东', 41: '河南', 42: '湖北', 43: '湖南', 44: '广东', 45: '广西', 46: '海南', 50: '重庆', 51: '四川', 52: '贵州', 53: '云南', 54: '西藏', 61: '陕西', 62: '甘肃', 63: '青海', 64: '宁夏', 65: '新疆', 71: '台湾', 81: '香港', 82: '澳门', 91: '国外' }
        // 检查号码是否符合规范，包括长度，类型
    var isCardNo = function(obj) {
            var reg = /(^\d{15}$)|(^\d{17}(\d|X)$)/
            if (reg.test(obj) === false) {
                return false
            }
            return true
        }
        // 取身份证前两位,校验省份
    var checkProvince = function(obj) {
            var province = obj.substr(0, 2)
            if (vcity[province] === undefined) {
                return false
            }
            return true
        }
        // 校验位的检测
    var checkParity = function(obj) {
            // 15位转18位
            obj = changeFivteenToEighteen(obj)
            var len = obj.length
            if (len === 18) {
                var arrInt = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]
                var arrCh = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2']
                var cardTemp = 0
                var valnum
                for (let i = 0; i < 17; i++) {
                    cardTemp += obj.substr(i, 1) * arrInt[i]
                }
                valnum = arrCh[cardTemp % 11]
                if (valnum === obj.substr(17, 1)) {
                    return true
                }
                return false
            }
            return false
        }
        // 15位转18位身份证号
    var changeFivteenToEighteen = function(obj) {
            if (obj.length === 15) {
                var arrInt = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]
                var arrCh = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2']
                var cardTemp = 0
                obj = obj.substr(0, 6) + '19' + obj.substr(6, obj.length - 6)
                for (let i = 0; i < 17; i++) {
                    cardTemp += obj.substr(i, 1) * arrInt[i]
                }
                obj += arrCh[cardTemp % 11]
                return obj
            }
            return obj
        }
        // 校验长度，类型
    if (isCardNo(idcard) === false) {
        return false
    }
    // 检查省份
    if (checkProvince(idcard) === false) {
        return false
    }
    // 检验位的检测
    if (checkParity(idcard) === false) {
        return false
    }
    return true
}

/**
 * 验证姓名
 * @export
 * @param {String} name 待判断的姓名
 * @returns {Boolean}
 */
export function validateName(name) {
    return /^[\u4e00-\u9fa5·]{2,20}$/.test(name)
}

/**
 * 验证微信号
 * @export
 * @param {String} name 待判断的微信号
 * @returns {Boolean}
 */
export function validateWeixin(name) {
    return /^[a-zA-Z0-9_-]{6,20}$/.test(name)
}

export function hasClass(ele, cls) {
    cls = cls || ''
    if (cls.replace(/\s/g, '').length === 0) return false
    return new RegExp(' ' + cls + ' ').test(' ' + ele.className + ' ')
}

export function addClass(ele, cls) {
    if (!hasClass(ele, cls)) {
        ele.className = ele.className === '' ? cls : ele.className + ' ' + cls
    }
}

export function removeClass(ele, cls) {
    if (hasClass(ele, cls)) {
        var newClass = ' ' + ele.className.replace(/[\t\r\n]/g, '') + ' '
        while (newClass.indexOf(' ' + cls + ' ') >= 0) {
            newClass = newClass.replace(' ' + cls + ' ', ' ')
        }
        ele.className = newClass.replace(/^\s+|\s+$/g, '')
    }
}

export function cloneObj(oldObj) {
    if (typeof(oldObj) !== 'object') return oldObj
    if (oldObj === null) return oldObj
    let newObj = {}
    for (const key in oldObj) {
        newObj[key] = cloneObj(oldObj[key])
    }
    return newObj
}
export function extendObj() {
    let args = arguments
    if (args.length < 2) return
    let temp = cloneObj(args[0])
    for (let n = 1; n < args.length; n++) {
        for (let i in args[n]) {
            temp[i] = args[n][i]
        }
    }
    return temp
}

// // 清除inout的内容
// export function clearInputFile (f) {
//   if (f.value) {
//     try {
//       f.value = ''
//     } catch (err) {
//     }
//     if (f.value) {
//       var form = document.createElement('form')
//       var ref = f.nextSibling
//       var p = f.parentNode
//       form.appendChild(f)
//       form.reset()
//       p.insertBefore(f, ref)
//     }
//   }
// }

/**
 * 处理时间
 * @export
 * @param {str} 待处理时间
 * @returns {str}
 */
export function formatTimes(str, type) {
    if (!str) return
    let nowTimeStamp = new Date().setHours(0, 0, 0, 0)
    let newStr = str.replace(/-/g, '/')
    let time = new Date(newStr)
    let timeStamp = time.getTime()
    let dayMsec = 24 * 60 * 60 * 1000
    let cha = nowTimeStamp - timeStamp
    let year = time.getFullYear()
    let month = time.getMonth() + 1
    let day = time.getDate()
    let hour = time.getHours() < 10 ? '0' + time.getHours() : time.getHours()
    let minute = time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes()
    if (!type) {
        if (cha < 0) {
            // 今天
            return hour + ':' + minute
        } else if (cha >= 0 && cha < dayMsec) {
            // 昨天
            return '昨天 ' + hour + ':' + minute
        } else {
            // 以前
            return year + '-' + month + '-' + day
        }
    } else if (type === 2) {
        return year + '-' + month + '-' + day
    } else {
        return year + '年' + month + '月' + day + '日'
    }
}

/**
 * 保存图片到相册
 * @export
 * @param {str} 图片链接
 */
// export function savePictureToAlbum (path) {
//   if (!path) return
//   if (plus) {
//     var dtask = plus.downloader.createDownload(path, {}, function (d, status) {
//       // 下载完成
//       if (status === 200) {
//         plus.gallery.save(d.url, function (e) {
//           plus.nativeUI.toast('图片已保存到相册', {
//             duration: 'short'
//           })
//         }, function () {
//           plus.nativeUI.toast('图片保存失败', {
//             duration: 'short'
//           })
//         })
//       } else {
//         plus.nativeUI.toast('图片保存失败', {
//           duration: 'short'
//         })
//       }
//     })
//     dtask.start()
//   }
// }