<template>
  <div class="home222">

    <div class="content">

  <!--顶部内容-->
        <div class="header">
            <div class="touxiang" @click="tomy">
                <img :src="user.head" style />
            </div>
            <div class="name">{{user.user_name}}</div>
            <div class="niunai"  @click="jiaoyi">
              {{user.niunai}}
            </div>
             <div class="jinbi"  @click="tochongzhi">
                {{user.jinbi}}
            </div>
        </div>

     
   <div class="main_container">
     <p style="font-size:18px;color:#333;text-align:center;line-height:30px;margin:0">Lucky magic box</p>
      <p style="font-size:14px;color:#666;text-align:center;line-height:20px;margin:0">milk:{{niunai}}</p>
       <p style="font-size:14px;color:#666;text-align:center;line-height:20px;margin:0">price:{{price}}</p>
    <div class="turntable_box">
      <ul id="rotary_table">
     
        <li v-for="(item,index) in awards" :key="index" :class="['award' + index, { active: index == current }]"
          class="award">
          <img :src="require('../assets/img/mh.png')" alt />
          <div class="mask" v-if="index == current"></div>
        </li>
        <div id="start-btn" @click="start"></div>
      </ul>
    </div>
  </div>

<!---ce-->
    </div>

<!--自制的tabbar-->

  <div class="tabbar">
     <van-row>
  <van-col span="8">        <van-image :src="require('../assets/img/images/home.png')"  @click="index"/>  </van-col>

    <van-col span="8">    <van-image :src="require('../assets/img/images/muchan.png')" @click="muchang" /> </van-col>
  <van-col span="8"><van-image :src="require('../assets/img/images/my.png')"  @click="tomy"/> </van-col>
    </van-row>
  </div>


<div class="bangzhu" @click="help230" ><van-image :src="require('../assets/img/mhbz.png')" /> </div>
  
<div class="jilu" @click="cjjl"><van-image :src="require('../assets/img/cjjl.png')" /> </div>
  

  

<!--牛的详情弹出层--->
<!-- <van-cell is-link @click="showPopup" >展示弹出层</van-cell> -->
<van-popup v-model="show" position="bottom" round :style="{ height: '60%' }" >
<div style="padding:20px;background:#ff6034;" class="help">
   <p>{{setting.help}}</p>
    <van-empty description="No help information" v-show="setting.help.length<=0"/>
</div>

</van-popup>
<!--抽奖记录--->
<van-popup v-model="show1" position="bottom" round  :style="{ height: '70%' }" >
<div style="padding:20px;background:#fff4d4;height:100%">
    <div class="tab_nairong" style="width:100%">
     <div class="li" v-for="(item,index) in mh_list" :key="index">
      <van-row>
  <van-col span="6">        <van-image :src="require('../assets/img/mh.png')" style="width:100%"/>  </van-col>
  <van-col span="1"><div style="width:100%;height:20px"></div></van-col>
  <van-col span="16">
    <p class="miumin1" v-if="item.manghe">Blind box:{{item.manghe.name}}</p>
    <p class="miumin1">date:{{item.create_time}}</p>
    <p class="miumin1">The milk:{{item.price}}g</p>
    <p class="miumin1">The gold COINS:{{item.jinbi}}</p>
    <p class="miumin1" v-if="item.nainiu">The prize:{{item.nainiu.name}}</p>
      <p class="miumin1" v-else>The prize:Not winning</p>
  </van-col>

    </van-row>
      </div>
  <van-empty description="No blind box drawing record" v-show="mh_list.length<=0"/>
   </div>
</div>

</van-popup>




    <!-- <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
  </div>
</template>

<script>
// @ is an alias to /src

import * as local from "@/libs/local";
//import HelloWorld from '@/components/HelloWorld.vue';
import { Toast } from "vant";
import Vue from "vue";
import { Swipe, SwipeItem } from "vant";
import { Tabbar, TabbarItem } from "vant";
import { Grid, GridItem } from 'vant';
import * as api from "@/api";
import { ref } from "vue";
import { Tab, Tabs } from 'vant';
import { Col, Row } from 'vant';
import { Empty } from 'vant';
import { Popup } from 'vant';
import { Cell, CellGroup } from 'vant';
import { Dialog } from 'vant';
//import * as loginUtil from '@/utils/loginUtil';
import * as update from '@/utils/update'
Vue.prototype.upd=update
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(local);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Col);
Vue.use(Row);
Vue.use(Empty);
Vue.use(Popup);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Dialog);
export default {
  name: "Home",
  components: {
    //HelloWorld
  },
  data() {
    return {
      is_lock:false,
      title: "商品列表",
      active: 0,
      productList: [],
      howExchangeItem: null,
      popHowExchange: false,
      isshow:0,
        mh_list:[],
      show: false,
       show1: false,
      setting:{
        help:''
      },
      price:0,
      niunai:0,
      manghe_setting:{
        count:0
      },
      manghe:[],
      awards: [
        // 图片名字与ID
        { id: 1, name: 10 },
        { id: 2, name: 100 },
        { id: 3, name: 2 },
        { id: 4, name: 1 },
        { id: 5, name: 5 },
        { id: 6, name: 50 },
        { id: 7, name: 0 },
        { id: 8, name: 5 }
      ],
      user:{},
      current: 0, // 当前索引值
      speed: 200, // 时间->速度
      diff: 15, // 基数
      award: {}, // 抽中的奖品
      time: 0, // 当前时间戳
      timer: null, // 定时器
      jiangping:{}
    };
  },
  setup() {
   const active = ref(0);
    return { active };
    
  },
  created: function() {
    let EXPIRESTIME = 5000
     let setting1 = local.readInfo('manghe_data')
    if(setting1 === null || setting1 === undefined || setting1 === ''){
        this.get_imdex_data()
    }else{
         let date = new Date().getTime();
      // 如果大于就是过期了，如果小于或等于就还没过期
      if ((date - (setting1.startTime?setting1.startTime:0)) > EXPIRESTIME) {
        this.get_imdex_data()
      }else{
        this.setting=setting1.setting
      
              this.user=setting1.user
                this.manghe=setting1.manghe
           
      }
    }
    this.price=this.$route.query.price
    this.niunai=this.$route.query.niunai
    let _this = this;
    console.log(_this.$store);
    //let _this = this
    //_this.$router.push({'name': 'About', query: {redId: 1111}})
  },
  methods: {
    start() {
      // 开始抽奖
      if(this.is_lock){
        return;
      }
      this.is_lock=true
       Dialog.confirm({
          title: 'magic box draw',
          message: 'magic box draw milk or COINS to pay and confirm the lottery',
          confirmButtonText:"confirm",
          cancelButtonText:"cancel"
       })
    .then(() => {
    //确认前往盲盒
        api.chou({
        token: local.readInfo('jwt'),
        id:this.$route.query.id
        }, (err, data) => {
          if (!err && data.code === 1) {
              this.getLottery();
             this.time = Date.now();
              this.speed = 200;
               this.diff = 12;
                 //Toast(data.msg)
                 this.jiangping=data.data
          } else {
              console.log(data)
                 Toast(data.msg)
            //this.block = false
         
          }
        
        })
     })
  .catch(() => {
    console.log('quxiao')
  });
    
    },
    // 调接口获取奖品
    getLottery() {
      this.award.name = "5";
      this.award.id = Math.floor(Math.random()*7)+1;
      this.move();
    },
    // 开始转动
    move() {
      this.timer = setTimeout(() => {
        this.current++;
        if (this.current > 7) {
          this.current = 0;
        }
        // 若抽中的奖品id存在，则开始减速转动
        if (this.award.id && (Date.now() - this.time) / 1000 > 2) {
          this.speed += this.diff; // 转动减速
          // 若转动时间超过4秒，并且奖品id等于小格子的奖品id，则停下来
          if (
            (Date.now() - this.time) / 1000 > 4 &&
            this.award.id == this.awards[this.current].id
          ) {
            clearTimeout(this.timer);
            setTimeout(() => {
              if(this.jiangping['is_cz']){
                       Dialog.confirm({
          title: 'Congratulations on your',
          message: 'Congratulations on you to get:'+this.jiangping.name,
          confirmButtonText:"confirm",
          cancelButtonText:"cancel"
       })
    .then(() => {
            
     })
  .catch(() => {
    console.log('quxiao')
  });
              }else{
                Toast('Thank you for your participation')
              }
              this.is_lock=false
              this.get_imdex_data()
            }, 0);
            return;
          }
        } 
        else {
          // 若抽中的奖品不存在，则加速转动
          this.speed -= this.diff;
        }

        this.move();
      }, this.speed);

    },

    help230: function() {
      this.show = true;
    },
    cjjl: function() {
        api.mh_list({
        token: local.readInfo('jwt'),
        }, (err, data) => {
          if (!err && data.code === 1) {
              this.mh_list=data.data.list
          } else {
              console.log(data)
          Toast(data.msg)
            //this.block = false
         
          }
        
        })
      this.show1 = true;
    },
    howExchange: function(item) {
      // 如何兑换
      this.howExchangeItem = item;
      this.popHowExchange = true;
    },
    get_imdex_data:function(){
        api.manghe_data({
        token: local.readInfo('jwt'),
        }, (err, data) => {
          if (!err && data.code === 1) {
            var datas = Object.assign(data.data, { startTime: new Date().getTime() });
             local.saveInfo('manghe_data',datas)
          
              this.user=data.data.user
                this.setting=data.data.setting
                 this.manghe=data.data.manghe
          } else {
              console.log(data)
          Toast(data.msg)
            //this.block = false
         
          }
        
        })
    },
    to_not_open:function(){
      Toast("A task is not open");
    },
    close:function(){
      this.isshow=1
    },
    toline: function() {
      let _this = this;
      _this.$router.push({ name: "line", query: { redId: 1111 } });
    },
   index: function() {
      let _this = this;
      _this.$router.push({ name: "Home", query: { redId: 1111 } });
    },
       tomy: function() {
      let _this = this;
      _this.$router.push({ name: "my", query: { redId: 1111 } });
    },
     jiaoyi: function() {
      let _this = this;
      _this.$router.push({ name: "jiaoyi", query: { redId: 1111 } });
    }
    ,
    tochongzhi: function() {
      let _this = this;
      _this.$router.push({ name: "cz_history", query: { redId: 1111 } });
    },
     gengduo: function() {
      let _this = this;
      _this.$router.push({ name: "buy", query: { redId: 1111 } });
    },
     muchang: function() {
      let _this = this;
      _this.$router.push({ name: "muchang", query: { redId: 1111 } });
    },
  
      showPopup() {
      this.show = true;
    },
 
  }
};
</script>
<style  scoped>

.my-swipe .van-swipe-item img {
  width: 100%;
  height: auto;
}
.van-tabs__nav--card{margin:0!important}
.home222 {
  background-color: #62a412;
  /* padding-bottom: 200px; */
    max-width: 750px;
  margin: 0 auto;
}
.content{
  width:100%;
  height: auto;
  min-height: 1624px;
  background: url('../assets/img/index.jpg') no-repeat #62a412;
   background-size: contain;
   background-color: #62a412;
}

.header{
  position: relative;
  width:100%;
  height:150px
}
.help p{
  color:white;
  font-size: 14px;
}
.header .touxiang{
  position: absolute;
  width:95px;
  height: 95px;
  border-radius: 100%;
  border: 2px solid white;
  overflow: hidden;
  top:30px;
  left:20px;
  box-sizing: border-box;
}
.header .touxiang img{width: 100%;}
.header .name{
    position: absolute;
  width:190px;
  height: 55px;
  top:49px;
  left:77px;
  line-height: 55px;
  font-size: 28px;
  color:#7b3c1f;
  overflow: hidden;
}
.header .niunai{
  background: url('../assets/img/images/niunai.png') no-repeat;
   background-size: contain;
   width: 220px;
   height: 75px;
   position: absolute;
   padding-left: 64px;
   box-sizing: border-box;
   line-height: 80px;
   color: #ffffff;
   left:270px;
   font-size: 13px!important;
   text-align: left;
    top:39px;

}
.header .jinbi{
  background: url('../assets/img/images/jinbi.png') no-repeat;
   background-size: contain;
   width: 220px;
   height: 75px;
   position: absolute;
   padding-left: 64px;
   box-sizing: border-box;
   line-height: 80px;
   color: #ffffff;
   left:500px;
   font-size: 13px;
   text-align: left;
    top:39px;

}

.lunbo{
  width:700px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 20px;
}
.gongge{
    width:700px;
  margin: 0 auto;
}

.van-tab__text{color: white!important;}
.tab_nairong{
    width:720px;
    margin: 0 auto;
    min-height: 300px;
    background: #ffffff;
    border: 5px solid #ffdd96;
    border-radius: 0 0 10px 10px;
    box-sizing: border-box;
    padding: 20px;
}
.tab_nairong .li{
  width:100%;
  padding: 10px;
  height: auto;
  box-sizing: border-box;
  border-radius: 10px;
  background: #fff4d4;
  margin-top: 15px;
}
.tab_nairong .miumin1{
  font-size: 14px;
  /* font-weight: bold; */
  word-wrap: break-word;
	word-break: break-all;
  color: #ba8a0a;
  text-align: left;
  margin:5px 0px;
  padding-left: 10px;
  /* white-space:nowrap;

       overflow:hidden;

       text-overflow:ellipsis; */

}
.tab_nairong .dengji{
  font-size: 16px;
  font-weight: bold;
  word-wrap: break-word;
	word-break: break-all;
  color: #ba8a0a;
}
.tab_nairong .jiage{
  font-size: 16px;
  font-weight: bold;
  word-wrap: break-word;
	word-break: break-all;
  color: #ba8a0a;
}
.gengduo{
  width: 100%;
  height: 40px;
  position: relative;
}
.gengduo img{
  display: block;
  position: absolute;
  width: 97px;
  height: 52px;
  top:-20px;
  left:323px;
}

.tabbar{
  position:fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 139px;
}

.bangzhu{
  width:94px;
  height: 94px;
  position: fixed;
  right: 40px;
  bottom: 190px;
}
.jilu{
  width:94px;
  height: 94px;
  position: fixed;
  right: 40px;
  bottom: 300px;
}

@white: #f4f4f4;
.main_container {
  width: 100%;
  min-height: 100%;
  /* background-color: #fb6010; */
  background-size: 100% auto;
  background-repeat: no-repeat;
  font-size: 26px;
}
  .prohibit {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  input::-webkit-input-placeholder {
    color: #a4a5a6;
    font-size: 26px;
  }
  /* padding: 100px 0px; */
  .turntable_box {
    margin: 0 auto;
    /* padding-top: 160px; */
    width: 90%;
    height: autoauto;
    background-color: #fb6010;
      border-radius: 20px;
    /* background: url("../assets/img/mh.png")
      no-repeat center/100%; */
  }
    #rotary_table {
      width: 621px;
      height: 621px;
      position: relative;
      margin: 20px auto;
    }
      /* // background-color: antiquewhite; */
      .award {
        width: 179px;
        height: 179px;
        text-align: center;
        float: left;
        position: absolute;
        overflow: hidden;
      }
       .award img {
          position: absolute;
          width: 179px;
          top: 0;
          left: 0;
          height: 179px;
        }
        .img {
          position: absolute;
          width: 179px;
          top: 0;
          left: 0;
          height: 179px;
        }
    
          .mask {
            width: 179px;
            height: 179px;
            position: absolute;
            border-radius: 10px;
            background-color: #fff0bd;
            opacity: 0.6;
          }
        

        .award0 {
          top: 21px;
          left: 21px;
        }
        .award1 {
          top: 21px;
          left: 221px;
        }
        .award2 {
          top: 21px;
          right: 21px;
        }
        .award3 {
          top: 221px;
          right: 22px;
        }
        .award4 {
          bottom: 22.5px;
          right: 22px;
        }
        .award5 {
          bottom: 22.5px;
          right: 221px;
        }
        .award6 {
          bottom: 22.5px;
          left: 21px;
        }
        .award7 {
          top: 221px;
          left: 21px;
        }
      
      #start-btn {
        position: absolute;
        width: 179px;
        height: 179px;
        top: 221px;
        left: 221px;
        border-radius: 50%;
        text-align: center;
        background: url("../assets/img/wheel-btn.png")
          no-repeat center/100%;
      }
    
  

</style>
