<template>
  <div class="content">
  <van-nav-bar
:title="text2[lang]"
:left-text="text1[lang]"
left-arrow
@click-left="onClickLeft"
/>
<div class="shuye">
      <img src="../assets/img1/nc_bg.png" />
    </div>
<div class="dajiange"></div>
<!-- <div class="zhifu public_flex">
   <div class="zf1"  @click="show_z"></div>
   <div class="zf2"  @click="cz"></div>
</div> -->

<div class="tixian">

<div>
      <van-form @submit="onSubmit">
<van-field
  v-model="price"
  name="price"
  :label="text3[lang]"
  type="digit"
  placeholder="text3[lang]"
  :rules="[{ required: true, message: '' }]" 
/>
<!-- <van-field
  v-model="price2"
  v-if="index1==0"
  name="price2"
  label="USDT"
   type="digit"
  placeholder="Please enter the withdrawal amount"
  :rules="[{ required: true, message: '' }]"
/> -->
<van-field
  v-model="password"
  type="password"
  name="password"
  :label="text4[lang]"
  placeholder="text4[lang]"
  :rules="[{ required: true, message: '' }]"
/>
<div style="color:#999">{{text5[lang]}}{{ huilv }}:1</div>

<div style="margin: 16px;">
  <van-button round block type="info" native-type="submit">{{text6[lang]}}</van-button>
</div>

</van-form>
</div>

</div>
<div style="height:20px"></div>

<div class="list">
<div class="title">{{text7[lang]}}</div>
<van-pull-refresh v-model="refreshing" loading-text="lodding"  lodding="lodding" @refresh="onRefresh" v-show="is_has" 
loosing-text="Release can refresh" pulling-text="The drop-down to refresh">

<van-list v-model="loading" :finished="finished" finished-text="No more" @load="onLoad" loading-text="lodding" lodding="lodding" >
<!-- <van-cell  /> -->

<div
  data-v-40838d58
  class="cz_list"
  v-for="item in list"
  :key="item.id"
  :title="item.id"
>
  <!-- <div class="icon tag0">I</div> -->
  <div  class="order_no public_flex">
    <div class="order">{{text6[lang]}}
    </div>
     <div class="status">
       <div class="kuang">{{text12[lang]}}:{{text14[lang]}}
        </div>
     </div>
     </div>
     <div class="date">  {{text13[lang]}}: {{item.create_time}}</div>
    <div class="money public_flex">
      <div class="money1">-{{item.jifen}}{{text41[lang]}}</div>
       <div class="money3">+${{item.price}}</div>
    </div>
  </div>

</van-list>

</van-pull-refresh>
<van-empty :description="text47[lang]" v-if="list.length<=0"/>
</div>







<div style="height:80px"></div>



</div>

</template>

<script>
import Vue from "vue";
import { Tab, Tabs } from "vant";
import { List } from "vant";
import { Toast } from "vant";
import * as api from '@/api';
import * as local from "@/libs/local";
import { Form } from 'vant';
import { Field } from 'vant';
import { Dialog } from 'vant';
import { NavBar } from 'vant';
import { Empty } from 'vant';
Vue.use(Empty);
Vue.use(NavBar);
Vue.use(Form);
Vue.use(Field);
Vue.use(List);
Vue.use(Toast);
Vue.use(Tab);
Vue.use(Tabs);
export default {
  name: "Home123213",
components: {
  //HelloWorld
},
data() {
  return {
     is_has:1,
    active:3,
    col:["TRC wallet","Bank card"],
    showPicker1: false,
    value1:'Bank card',
    index1:1,
    page:1,
    list: [],
    huilv:0,
    price2:10,
    loading: false,
    finished: false,
    refreshing: false,
    price:10,
    password:'',
    show1_1: false,
    is_jia:false,
    show12: false,
    user_info:{
      yue:0.00
      },
   sub: false,
   lang:0,
   text1:["back","رد : .","retour"],
   text2:["Points for","نقطة","Points pour"],
   text3:["integral","أساسي","intégral"],
   text4:["payment password","دفع كلمة السر","mot de passe de paiement"],
   text5:["What's the exchange rate the current integral for USDT","ما هو التكامل الحالي usdt","Quel est le taux de change de l'intégrale actuelle pour l'USDT"],
   text6:["Points for USDT","usdt جزءا لا يتجزأ من","Points pour USDT"],
   text7:["Prepaid phone records","سجل الهاتف المدفوعة مسبقا","Relevés téléphoniques prépayés"],
   text8:["Points for","نقطة","Points pour"],
   text41:["integral","أساسي","intégral"],
   text12:["status","مركز","statut"],
   text13:["date","تواريخ","Date"],
   text14:["For successful","النجاح","Pour réussir"],
   text47:["No data "," بيانات مؤقتة ","Pas de données"],
  };
},
created() {
  this.lang=local.readInfo('setting_lang')==undefined||local.readInfo('setting_lang')==null?0:local.readInfo('setting_lang')
},
methods: {
  onClickLeft() {
    this.$router.go(-1); //返回上一层
  },   
  onClickRight: function () {
    let _this = this;
    _this.$router.push({ name: "team", query: { redId: 1111 } });
  },

  onConfirm1(value, index) {
    this.value1 = value;
    this.index1=index
    this.showPicker1 = false;
    console.log(index)
    console.log(value)
      },
  onLoad(name=false) {
    if(this.is_jia){return false}
    this.is_jia=true
    // setTimeout(() => {
    //   if (this.refreshing) {
    //     this.list = [];
    //     this.refreshing = false;
    //   }

    //   for (let i = 0; i < 10; i++) {
    //     this.list.push(this.list.length + 1);
    //   }
    //   this.loading = false;

    //   if (this.list.length >= 40) {
    //     this.finished = true;
    //   }
    // }, 1000);
    
    if(name){
      this.list=[];
      this.page = 1;
       this.finished = false;
       this.is_has=1
    }
          api.jiaoyi({
        token: local.readInfo('jwt'),
        type:this.active,
        page:this.page
      }, (err, data) => {
             this.loading= false
             this.refreshing = false;
             this.is_jia=false
        if (!err && data.code === 1) {
             
                 let oldArr = this.list
                 let newArr = data.data.data.data
                 this.list = oldArr.concat(newArr)
                 this.user_info=data.data.user
                 this.huilv=data.data.huilv
                this.page++
                 if((data.data.data.last_page-data.data.data.current_page)<=0){
                      this.finished = true;
                 }
               
                 if(data.data.data.total<=0){
                    this.is_has=0
                 }
        
        } else {
            console.log(data)
         if(data&&'msg' in data){
          Toast(data.yy[this.lang])
         }
        
       
        }
      
      })
  },
     back() {
    this.$router.go(-1); //返回上一层
  },
  onRefresh() {
    // 清空列表数据
    this.finished = false;
     if(this.logining){return}
    // 重新加载数据
    // 将 loading 设置为 true，表示处于加载状态
    this.loading = true;
    this.onLoad(true);
  },
  onClick(name) {
   // Toast(name + "已被禁用" + title);
      if(this.logining){return}
   this.active=name
   this.list=[];
   this.page=1
   console.log(name);
    this.onLoad(true)
  },
  show1:function(){
      this.show1_1=true
    },
    cz: function () {
    let _this = this;
    _this.$router.push({ name: "usdt", query: { redId: 1111 } });
  },
  show_c:function(){
      this.show12=true
    },
     show_z:function(){
      let _this = this;
      _this.$router.push({ name: "chongzhi", query: { redId: 1111 } });
    },
  onSubmit(values) {
    //console.log('submit', values);
   if(this.sub){return}
   this.sub=true
     api.jfdh({
        token: local.readInfo('jwt'),
        money:values.price,
        pwd:values.password
      }, (err, data) => {
        this.sub=false
        if (!err && data.code === 1) {
             
             Dialog.alert({
              message:  data.yy[this.lang],
              confirmButtonText:this.lang==0?'I know the':this.lang==1?"أنا أعرف":"Je connais le"
           }).then(() => {
              this.onLoad(true)
          });
        
        } else {
            console.log(data)
         if(data&&'msg' in data){
          Toast(data.yy[this.lang])
         }
        
       
        }
      
      })
  },
}
};
</script>

<style scoped>
.home222 {
background-color: #79c752;
max-width: 750px;
margin: 0 auto;
}
.content {
width: 100%;
height: auto;
min-height: 1624px;
background: url("../assets/img1/my_bg.jpg") no-repeat #79c752;
background-size: 100%;
}
.people{
width:700px;
margin: 0 auto;
background: white;
border-radius: 20px;
overflow: hidden;
height: 280px;
}
.shuye {
width: 100%;
height: 122px;
position: fixed;
bottom: 0px;
left: 0;
}
.shuye img {
width: 100%;
}
.dajiange{
height: 185px;
}
.zhifu{
width: 100%;
height: 203px;
background: url("../assets/img1/cz_btn.png") no-repeat;
background-size: 100%;
}
.zhifu .zf1{
width:50%;
height: 203px;
}
.zhifu .zf2{
width:50%;
height: 203px;
}

.list{
width:700px;
margin: 0 auto;
min-height: 400px;
background: #fff;
border-radius: 25px;

}
.list .title{
text-align: center;
color:#333;
font-size: 28px;
line-height: 40px;
}

.list .cz_list{
width:670px;
height: 200px;
background: white;
border-radius: 20px;
margin: 0 auto;
margin-top: 20px;
padding: 5px;
box-sizing: border-box;
box-shadow: 3px 5px 10px 1px #dbdbdb;


}
.list .cz_list .order_no{
  width: 100%;
  height: 35px;
  padding-top: 20px;
}
.list .cz_list .order_no .order{
  width:50%;
  background: url("../assets/img1/dd.png") no-repeat;
 background-size: 24px 30px;
 padding-left: 30px;
 box-sizing: border-box;
 height: 35px;
 text-align: left;
 font-size: 24px;
 font-weight: 700;
 color:#333;
 line-height: 35px;
}
.list .cz_list .order_no .status{
  width: 50%;
  height: 35px;
}
.list .cz_list .order_no .status .kuang{
  width: 100%;
  height: 33px;
  border: 1px solid #e4b025;

  line-height: 33px;
  font-size: 12px;
  color:#e4b025;
  border-radius: 7px;
}
.list .cz_list .date{
  line-height: 35px;
  text-align: left;
  font-size: 24px;
 font-weight: 100;
 color:#999999;
 margin-top: 20px;
}
.list .cz_list .money{
  width: 100%;
  height: 35px;
  padding-top: 20px;
}
.list .cz_list .money .money1{
  width:50%;
  text-align: left;
  font-size: 24px;
 font-weight: 100;
 color:#999999;

}
.list .cz_list .money .money3{
  width:50%;
  text-align: right;
  font-size: 24px;
 font-weight: 100;
 color:#f13b17;
}
.tixian >>>.van-field__label{
width: 7.2em;
}
.tixian >>> .van-field__body {
border: 1px solid rgb(222, 221, 221);
}
.tixian{
width:700px;
margin: 0 auto;
min-height: 400px;
background: white;
border-radius: 25px;
padding: 25px 0;

}
</style>