<template>
  <div class="about77">
    <div data-v-2ac32f0d data-v-4d9388c2 class="Site PageBox">
      <div data-v-2ac32f0d class="van-nav-bar van-nav-bar--fixed">
        <div class="van-nav-bar__left" v-on:click="back">
          <i class="van-icon van-icon-arrow-left van-nav-bar__arrow">
            <!---->
          </i>
        </div>
        <div class="van-nav-bar__title van-ellipsis">Language</div>
        <div class="van-nav-bar__right"></div>
      </div>
      <div data-v-2ac32f0d class="ScrollBox" v-on:click="back">
        <!---->
        <div data-v-2ac32f0d role="button" tabindex="0" class="van-cell van-cell--clickable">
          <i data-v-2ac32f0d class="van-icon van-cell__left-icon">
            <img data-v-2ac32f0d src="../assets/images/en-US.png" class="van-icon__image" />
            <!---->
          </i>
          <div data-v-2ac32f0d class="van-cell__title">
            <span data-v-2ac32f0d>English</span>
          </div>
          <i data-v-2ac32f0d class="van-icon van-icon-arrow van-cell__right-icon">
            <!---->
          </i>
        </div>
        <!---->
        <!---->
        <!---->
        <!---->
        <!---->
        <!---->
        <!---->
      </div>
    </div>
  </div>
</template>
 <script>



export default {
  data() {
    return {
      radio: "1"
    };
  },
  methods:{
    back(){
        this.$router.go(-1);//返回上一层
    },
},
};
</script>
<style >

</style>
