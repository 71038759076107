<template>
  <div class="content">
  <van-nav-bar
:title="langs.t1"
:left-text="langs.t20"
left-arrow
@click-left="onClickLeft"
/>
<!-- <div class="shuye">
      <img src="../assets/img1/nc_bg.png" />
    </div> -->
<div style="height:10px"></div>
<!-- <div class="zhifu public_flex">
   <div class="zf1"  @click="show_z"></div>
   <div class="zf2"  @click="cz"></div>
</div> -->

<div class="tixian" v-if="redid=='1112'">

<div >
      <van-form @submit="onSubmit">
<van-field
 v-if="index1==1"
  v-model="price2"
  name="price"
  :label="langs.t2"
  type="digit"
  :placeholder="langs.t2"
  :formatter="formatter"
    @blur="init_price1"
  :rules="[{ required: true, message: '' }]" 
/>

<van-field
  v-model="price2"
  v-if="index1==0"
  name="price"
  :label="langs.t2"
   type="number"
   :placeholder="langs.t2"
   :formatter="formatter"
    @blur="init_price"
  :rules="[{ required: true, message: '' }]"
/>
<van-field
 v-if="index1==0"
  v-model="price"
  name="price3"
  readonly
  label="USDT"
  type="number"
  placeholder="The input amount of USDT withdrawal"
  :rules="[{ required: true, message: '' }]" 
/>
<van-field
  v-model="password"
  type="password"
  name="password"
  :label="langs.t4"
  :placeholder="langs.t4"
  :rules="[{ required: true, message: '' }]"
/>
<van-field
clickable
name="code1"
readonly
:value="value1"
:label="langs.t5"
placeholder="langs.t5"
@click="showPicker1 = true"
/>

<van-popup v-model="showPicker1" position="bottom" height="600px" style="">
<van-picker
  show-toolbar
  :confirm-button-text="langs.tp2"
  :cancel-button-text="langs.tp1"
  :title="langs.t2"
  :columns="col"
  :default-index="index1"
  @confirm="onConfirm1"
  @cancel="showPicker1 = false"
 
/>

</van-popup>

<div style="margin: 16px;">
  <van-button round block type="info" native-type="submit"   color="#ff8917">{{langs.t6}}</van-button>
</div>

</van-form>
<p style="white-space: pre-wrap;text-align: left;color:#999;padding:1px 10px;line-height: 120%;">
  {{langs.t7}}<span style="color:red">{{ !user.trc_address?'Is not set':user.trc_address }}</span>,
  {{langs.t8}}<span style="color:red">{{ !user.bank_card?'Is not set':user.bank_card }}</span><br/>
{{langs.t9}}
</p>
 <p style="white-space: pre-wrap;text-align: left;color:#999;padding: 10px;">{{ tx_msg }}</p>
</div>

</div>
<div style="height:20px"></div>

<div class="list">
<div class="title">{{langs.t10}}</div>
<van-pull-refresh v-model="refreshing" :loading-text="langs.tp13"
    lodding="lodding" @refresh="onRefresh" v-show="is_has" 
    :loosing-text="langs.tp14"
    :pulling-text="langs.tp16">

<van-list v-model="loading" :finished="finished" :finished-text="langs.tp15" @load="onLoad" 
:loading-text="langs.tp13" lodding="lodding" >
<!-- <van-cell  /> -->

<div
  data-v-40838d58
  class="cz_list"
  v-for="item in list"
  :key="item.id"
  :title="item.id"
>
  <!-- <div class="icon tag0">I</div> -->
  <div  class="order_no public_flex">
    <div class="order">{{item.order_no}}
    </div>
     <div class="status">
       <div class="kuang one_hide">{{langs.t19}}:
          <span v-if="active==3&&item.zhuangtai==0">{{langs.t11}}</span>
         <span v-else-if="active==3&&item.zhuangtai==1">{{langs.t12}}</span>
         <span v-else-if="active==3&&item.zhuangtai==2">{{langs.t13}}</span>
           <span v-else-if="active==3&&item.zhuangtai==3">{{langs.t14}}</span>
             <span v-else-if="active==3&&item.zhuangtai==4">{{langs.t15}}</span>
               <span v-else-if="active==3&&item.zhuangtai==5">{{langs.t16}}</span>
                 <span v-else-if="active==3&&item.zhuangtai==6">{{langs.t17}}</span>
                 <span v-else-if="active==3&&item.zhuangtai==7">{{langs.t12}}</span>
             <span v-else>{{langs.t18}}</span>
        </div>
     </div>
     </div>
     <div class="date">  {{langs.t21}}: {{item.create_time}}</div>
    <div class="money public_flex">
      <div class="money1">{{langs.t22}}:{{item.sj_price}}</div>
       <div class="money3">{{langs.t23}}:{{item.price}}</div>
    </div>
  </div>

</van-list>

</van-pull-refresh>
<van-empty :description="langs.tp15" v-if="list.length<=0"/>
</div>







<div style="height:80px"></div>

<Footer :lang_list="lang_list" :lang="langs" />

</div>

</template>

<script>
import Vue from "vue";
import { Tab, Tabs } from "vant";
import { List } from "vant";
import { Toast } from "vant";
import * as api from '@/api';
import * as local from "@/libs/local";
import { Form } from 'vant';
import { Field } from 'vant';
import { Dialog } from 'vant';
import { NavBar } from 'vant';
import { Empty } from 'vant';
import Footer from './lyout/footer.vue';
import Decimal from "decimal.js";
Vue.use(Empty);
Vue.use(NavBar);
Vue.use(Form);
Vue.use(Field);
Vue.use(List);
Vue.use(Toast);
Vue.use(Tab);
Vue.use(Tabs);
export default {
  name: "Home123213",
  components: {
    //HelloWorld
    Footer,
  },
data() {
  return {
     is_has:1,
    active:3,
    col:["USDT wallet","Mobile wallet"],
    showPicker1: false,
    value1:"",
    index1:1,
    page:1,
    list: [],
    feilv:[],
    huilv:0,
    shouxu:0,
    fabi:0,
    price2:12000,
    tx_msg:"",
    loading: false,
    finished: false,
    refreshing: false,
    price:0,
    password:'',
    show1_1: false,
    is_jia:false,
    show12: false,
    user_info:{
      yue:0.00
      },
   sub: false,
   user:{},
   lang:0,
   text1:["back","رد : .","Retour"],
   text2:["Withdrawal","سحب","Retrait"],
   text3:["Withdrawal Amount","سحب المبلغ","Montant de retrait"],
   text4:["Please enter the withdrawal amount","الرجاء إدخال مبلغ السحب","Veuillez entrer le montant du retrait"],
   text5:["payment password","دفع كلمة السر","mot de passe de paiement"],
   text6:["Enter the password for paymnet","أدخل كلمة السر","Entrez le mot de passe pour le paiement"],
   text7:["Choose withdrawal way","اختيار طريقة السحب","Choisissez le mode de retrait"],
   text8:["cancel","ألغى","Annuler"],
   text9:["Choose withdrawal way","اختيار طريقة السحب","Choisissez le mode de retrait"],
   text10:["confirm","أكّد","confirmer"],
   text11:["Withdrawal record","سحب السجلات","Historique des retraits"],
   text12:["status","مركز","statut"],
   text13:["date","تواريخ","date"],
   text14:["amount","الكمية","la quantité"],
   text15:["To the account","نقل إلى حساب","Vers le compte"],
   text16:["No more","لا أكثر","Pas plus"],
   text17:["Apply for withdrawal","طلب سحب usdt"," Confirmer le retrait"],
   text18:["currency","العملة","monnaie"],
   status1:["Waiting for audit","في انتظار مراجعة","En attente de vérification"],
   status2:["In the transfer","تحويل مصرفي","virement"],
   status3:["Pay to complete","دفع كامل","Payer pour terminer"],
   status4:["Audit not through","بازرسی","Audit pas à travers"],
   status5:["Pay for failure","دفع ثمن الفشل","Payer pour l'échec"],
   status6:["cancel","ألغى","Annuler"],
   status7:["Pay to complete","دفع كامل","Payer pour terminer"],
   status8:["Pay for failure","دفع ثمن الفشل","Payer pour l'échec"],
   lang_list: [],
    select_lang: {},
    langs: {},
    cate: [],
    redid:1111,
  };
},
// <span v-if="active==3&&item.zhuangtai==0">Waiting for audit</span>
//          <span v-else-if="active==3&&item.zhuangtai==1">Encrypted wallet transfer</span>
//          <span v-else-if="active==3&&item.zhuangtai==2">Pay to complete</span>
//            <span v-else-if="active==3&&item.zhuangtai==3">Audit not through</span>
//              <span v-else-if="active==3&&item.zhuangtai==4">Pay for failure</span>
//                <span v-else-if="active==3&&item.zhuangtai==5">cancel</span>
//                  <span v-else-if="active==3&&item.zhuangtai==6">Pay to complete</span>
//                  <span v-else-if="active==3&&item.zhuangtai==7">Encrypted wallet transfer</span>
created() {
 // this.get_price()
 this.get_price()
   this.lang=local.readInfo('setting_lang')==undefined||local.readInfo('setting_lang')==null?0:local.readInfo('setting_lang')

},
// computed: {
//     result() {
//       if (this.price2) {
//         let num1 = new Decimal(this.huilv);
//         let num2 = new Decimal(this.price2);
//       //  this.price1=num2.div(num1).toFixed(2);
//         return num2.div(num1).toFixed(2);
//         // if(this.type==1){
//         //   return num1.times(num2).toFixed(4);
//         // }
//         // let shou = new Decimal(this.shou);
//         // let bai = new Decimal(100);
//         // return num1.times(num2).minus(shou.times(num1.times(num2)).dividedBy(bai)).toFixed(4);
//       }
//       return 0
//     },
 
//   },
methods: {
  get_price(){
    
   this.redid=this.$route.query.redId

console.log( this.$route.query.redId)
    console.log(this.huilv)
       
        api.get_huilv1({
        token: local.readInfo('jwt'),
        lang:local.readInfo('car_lang'),
        price:this.price2
      }, (err, data) => {
            this.logining = false
        if (!err && data.code === 1) {
             this.huilv=data.data.huilv
        
              this.tx_msg=data.data.tx_msg
             this.price=data.data.price
             this.lang_list = data.lang_list;
              this.select_lang = data.select_lang;
               this.langs = data.lang;
               this.col[0]=data.lang.t25
               this.col[1]=data.lang.t24
               if(this.index1==1){
               this.value1=data.lang.t24
               }
        }
      
      }
      )
            },

    formatter:function(x){
    console.log(x)
    // if(x>=1){
    //   this.get_price()
    //   return x
    // }else{
    //   return x
    // }
   if(x&&x>0&&(x/this.huilv)>=0.01){
    if(x<999){
          return x
        }else{
          let num1 = new Decimal(this.huilv);
        let num2 = new Decimal(this.price2);
      //  this.price1=num2.div(num1).toFixed(2);
        this.price=num2.div(num1).toFixed(2);
        }


   }


    return x
  },
  init_price:function(event){
    console.log(event)
    if(this.price2<45000){
      this.price2=45000
    }
  },
  init_price1:function(event){
    console.log(event)
    if(this.price2<12000){
      this.price2=12000
    }
  },
  onClickLeft() {
    this.$router.go(-1); //返回上一层
  },   
  onClickRight: function () {
    let _this = this;
    _this.$router.push({ name: "team", query: { redId: 1111 } });
  },

  onConfirm1(value, index) {
    this.value1 = this.col[index];
    this.index1=index
    if(index==0){
      this.price2=45000
    }else{
      this.price2=12000
    }
    this.showPicker1 = false;
    console.log(index)
    console.log(value)
     this.get_price()
      },
  onLoad(name=false) {
    if(this.is_jia){return false}
    this.is_jia=true
    // setTimeout(() => {
    //   if (this.refreshing) {
    //     this.list = [];
    //     this.refreshing = false;
    //   }

    //   for (let i = 0; i < 10; i++) {
    //     this.list.push(this.list.length + 1);
    //   }
    //   this.loading = false;

    //   if (this.list.length >= 40) {
    //     this.finished = true;
    //   }
    // }, 1000);
    
    if(name){
      this.list=[];
      this.page = 1;
       this.finished = false;
       this.is_has=1
    }
          api.get_my_mingxi({
        token: local.readInfo('jwt'),
        type:this.active,
        page:this.page
      }, (err, data) => {
             this.loading= false
             this.refreshing = false;
             this.is_jia=false
        if (!err && data.code === 1) {
             
                 let oldArr = this.list
                 let newArr = data.data.data
                 this.list = oldArr.concat(newArr)
                 this.user_info=data.data.user
                 this.user=data.data.user
                this.page++
                 if((data.data.last_page-data.data.current_page)<=0){
                      this.finished = true;
                 }
               
                 if(data.data.total<=0){
                    this.is_has=0
                 }
        
        } else {
            console.log(data)
         if(data&&'msg' in data){
           Toast(data.msg)
         }
        
       
        }
      
      })
  },
     back() {
    this.$router.go(-1); //返回上一层
  },
  onRefresh() {
    // 清空列表数据
    this.finished = false;
     if(this.logining){return}
    // 重新加载数据
    // 将 loading 设置为 true，表示处于加载状态
    this.loading = true;
    this.onLoad(true);
  },
  onClick(name) {
   // Toast(name + "已被禁用" + title);
      if(this.logining){return}
   this.active=name
   this.list=[];
   this.page=1
   console.log(name);
    this.onLoad(true)
  },
  show1:function(){
      this.show1_1=true
    },
    cz: function () {
    let _this = this;
    _this.$router.push({ name: "usdt", query: { redId: 1111 } });
  },
  show_c:function(){
      this.show12=true
    },
     show_z:function(){
      let _this = this;
      _this.$router.push({ name: "chongzhi", query: { redId: 1111 } });
    },
  onSubmit(values) {
    //console.log('submit', values);
   if(this.sub){return}
   this.sub=true
     api.tixian({
        token: local.readInfo('jwt'),
        money:values.price,
        tx_type:this.index1,
        pwd:values.password
      }, (err, data) => {
        this.sub=false
        if (!err && data.code === 1) {
             
             Dialog.alert({
              message: data.msg,
              confirmButtonText:this.langs.tp2
           }).then(() => {
              this.onLoad(true)
          });
        
        } else {
            console.log(data)
         if(data&&'msg' in data){
          Toast(data.msg)
         }
        
       
        }
      
      })
  },
}
};
</script>

<style scoped>
.home222 {
  background:  #f7fbe1;
max-width: 750px;
margin: 0 auto;
}
.content {
width: 100%;
height: auto;
min-height: 1624px;
background:  #f7fbe1;
background-size: 100%;
}
.people{
width:92%;
margin: 0 auto;
background: white;
border-radius: 20px;
overflow: hidden;
height: 280px;
}
.shuye {
width: 100%;
height: 122px;
position: fixed;
bottom: 0px;
left: 0;
}
.shuye img {
width: 100%;
}
.dajiange{
height: 65px;
}
.zhifu{
width: 100%;
height: 203px;
background: url("../assets/img1/cz_btn.png") no-repeat;
background-size: 100%;
}
.zhifu .zf1{
width:50%;
height: 203px;
}
.zhifu .zf2{
width:50%;
height: 203px;
}

.list{
width:95%;
margin: 0 auto;
min-height: 400px;
background: #fff;
border-radius: 25px;

}
.list .title{
text-align: center;
color:#666;
font-size: 28px;
line-height: 40px;
}

.list .cz_list{
width:95%;
height: 200px;
background: white;
border-radius: 20px;
margin: 0 auto;
margin-top: 20px;
padding: 5px;
box-sizing: border-box;
box-shadow: 3px 5px 10px 1px #dbdbdb;

}
.list .cz_list .order_no{
  width: 100%;
  height: 35px;
  padding-top: 20px;
}
.list .cz_list .order_no .order{
  width:50%;
  background: url("../assets/img1/dd.png") no-repeat;
 background-size: 24px 30px;
 padding-left: 30px;
 box-sizing: border-box;
 height: 35px;
 text-align: left;
 font-size: 24px;
 font-weight: 700;
 color:#333;
 line-height: 35px;
}
.list .cz_list .order_no .status{
  width: 50%;
  height: 35px;
}
.list .cz_list .order_no .status .kuang{
  width: 100%;
  height: 33px;
  border: 1px solid #e4b025;
  padding:0;
  line-height: 33px;
  font-size: 12px;
  color:#e4b025;
  border-radius: 7px;
}
.list .cz_list .date{
  line-height: 35px;
  text-align: left;
  font-size: 24px;
 font-weight: 100;
 color:#999999;
 margin-top: 20px;
}
.list .cz_list .money{
  width: 100%;
  height: 35px;
  padding-top: 20px;
}
.list .cz_list .money .money1{
  width:50%;
  text-align: left;
  font-size: 24px;
 font-weight: 100;
 color:#999999;

}
.list .cz_list .money .money3{
  width:50%;
  text-align: right;
  font-size: 24px;
 font-weight: 100;
 color:#f13b17;
}
.tixian >>>.van-field__label{
width: 7.2em;
}
.tixian >>> .van-field__body {
border: 1px solid rgb(222, 221, 221);
}
.tixian{
width:95%;
margin: 0 auto;
min-height: 400px;
background: white;
border-radius: 25px;
padding: 25px 0;
box-shadow: 5px 5px 15px 5px #e3e3e3;

}
</style>