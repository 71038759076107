<template>
  <div class="content">
  <van-nav-bar
 :title="langs.t1"
:left-text="langs.t5"
  left-arrow
  @click-left="onClickLeft"
/>


    <div style="height:10px"></div>
<div class="list">

 
<!-- <van-cell  /> -->
<van-pull-refresh v-model="refreshing" @refresh="onRefresh" :loading-text="langs.tp13" lodding="lodding" 
:loosing-text="langs.tp14"
:pulling-text="langs.tp16">
  <van-list
    v-model="loading"
    :finished="finished"
    :finished-text="langs.tp15" @load="onLoad" :loading-text="langs.tp13"  lodding="lodding"
  >
  <div class="cz_list"  v-for="(item,index) in list" :key="index">
  <!-- <div class="icon tag0">I</div> -->
  <div  class="order_no public_flex">
    <div class="order one_hide">{{langs.t2}}
    </div>
     <div class="status">
      <div class="kuang">{{ item.log }} </div>
     </div>
     </div>
     
    <div class="money public_flex">
      <div class="money1">{{langs.t3}}n</div>
       <div class="money3">{{lang==0?item.yw_msg:lang==1?item.alb_msg:item.xby_msg}}</div>
    </div>
    <div class="date public_flex">  
      <div class="d1">{{langs.t4}}</div>
      <div class="d2"> {{item.create_time}}</div>
    </div>
  </div>

  </van-list>
</van-pull-refresh>


  <div style="height:20px"></div>
  <van-empty :description="langs.tp13" v-if="list.length<=0"/>

</div>









<div style="height:80px"></div>

<Footer :lang_list="lang_list" :lang="langs" />

</div>

</template>

<script>
import Vue from "vue";
import { Tab, Tabs } from "vant";
import { PullRefresh } from 'vant';
import { List } from "vant";
import { Toast } from "vant";
import * as api from '@/api';
import * as local from "@/libs/local";
import { Form } from 'vant';
import { Field } from 'vant';
import { Dialog } from 'vant';
import { NavBar } from 'vant';
import { Empty } from 'vant';
import { Loading } from 'vant';
import Footer from './lyout/footer.vue';
Vue.use(NavBar);
Vue.use(Form);
Vue.use(Field);
Vue.use(List);
Vue.use(PullRefresh);
Vue.use(Toast);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Empty);
Vue.use(Dialog);
Vue.use(Loading);
export default {
  name: "Home123213",
  components: {
    //HelloWorld
    Footer,
  },
data() {
  return {
 
    user:{},
    lists:[],
    sub: false,
    list:[],
    loadin:false,
    loading: false,
    finished:false,
    refreshing:false,
    page:1,
    is_jia:false,
    is_has:0,
    lang:0,
    text1:["back","رد : .","dos"],
    text2:["Usdt change record","usdt سجل التغيير","Usdt modifier l'enregistrement"],
    text3:["Changes that","تغير","source"],
    text4:["change date","عموما تاريخ التغيير","date de modification"],
    lang_list: [],
    select_lang: {},
    langs: {},
    cate: [],
  };
},
created() {
    this.lang=local.readInfo('setting_lang')==undefined||local.readInfo('setting_lang')==null?0:local.readInfo('setting_lang')
},
methods: {
  onLoad(name=false) {
          if(this.is_jia){return false}
      this.is_jia=true
      if(name){
        this.list=[];
        this.page = 1
         this.is_has=1
        this.finished = false;
      }
            api.myjbjl({
          token: local.readInfo('jwt'),
          page:this.page,
          lang:local.readInfo('car_lang'),
        }, (err, data) => {
             this.loading= false
               this.refreshing = false;
               this.is_jia=false
          if (!err && data.code === 1) {
                   let oldArr = this.list
                   let newArr = data.data.data.data
                   this.list = oldArr.concat(newArr)
                   this.lang_list = data.lang_list;
              this.select_lang = data.select_lang;
               this.langs = data.lang;
                   this.page++
                   if((data.data.data.last_page-data.data.data.current_page)<=0){
                       this.finished = true;//this.is_has=0
                   }
                  if(data.data.data.total<=0){
                      this.is_has=0
                   }
          } else {
              console.log(data)
           if(data&&'msg' in data){
             Toast(data.msg)
           }
           // this.logining = false
         
          }
        
        })
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
       if(this.logining){return}
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad(true);
    },
  onClickLeft() {
    this.$router.go(-1); //返回上一层
  },   
  onClickRight: function () {
    let _this = this;
    _this.$router.push({ name: "my_props", query: { redId: 1111 } });
  },
 
}
};
</script>

<style scoped>
.home222 {
  background-color: #f7fbe1;
max-width: 750px;
margin: 0 auto;
}
.content {
width: 100%;
height: auto;
min-height: 1624px;
background-color: #f7fbe1;
background-size: 100%;
}
.people{
width:700px;
margin: 0 auto;
background: white;
border-radius: 20px;
overflow: hidden;
height: 280px;
}
.shuye {
width: 100%;
height: 122px;
position: fixed;
bottom: 0px;
left: 0;
}
.shuye img {
width: 100%;
}
.dajiange{
height: 185px;
}
.zhifu{
width: 100%;
height: 203px;
background: url("../assets/img1/cz_btn.png") no-repeat;
background-size: 100%;
}
.zhifu .zf1{
width:50%;
height: 203px;
}
.zhifu .zf2{
width:50%;
height: 203px;
}

.list{
width:700px;
margin: 0 auto;
min-height: 400px;
background: #fff;
border-radius: 25px;

}
.list .title{
text-align: center;
color:#333;
font-size: 28px;
line-height: 40px;
}

.list .cz_list{
width:670px;
height: 200px;
background: white;
border-radius: 20px;
margin: 0 auto;
margin-top: 20px;
padding: 5px;
box-sizing: border-box;
box-shadow: 3px 5px 10px 1px #dbdbdb;


}
.list .cz_list .order_no{
  width: 100%;
  height: 35px;
  padding-top: 20px;
}
.list .cz_list .order_no .order{
  width:50%;
  background: url("../assets/img1/dd.png") no-repeat;
 background-size: 24px 30px;
 padding-left: 30px;
 box-sizing: border-box;
 height: 35px;
 text-align: left;
 font-size: 24px;
 font-weight: 700;
 color:#333;
 line-height: 35px;
}
.list .cz_list .order_no .status{
  width: 50%;
  height: 35px;
}
.list .cz_list .order_no .status .kuang{
  width: 100%;
  height: 33px;
  border: 1px solid #e4b025;
  line-height: 33px;
  font-size: 12px;
  color:#e4b025;
  border-radius: 7px;
}
.list .cz_list .date{
  line-height: 35px;
  text-align: left;
  font-size: 24px;
 font-weight: 100;
 color:#999999;
 margin-top: 20px;
}
.list .cz_list .money{
  width: 100%;
  height: 35px;
  padding-top: 20px;
}
.list .cz_list .money .money1{
  width:50%;
  text-align: left;
  font-size: 24px;
 font-weight: 100;
 color:#999999;

}
.list .cz_list .money .money3{
  width:50%;
  text-align: right;
  font-size: 24px;
 font-weight: 100;
 color:#f13b17;
}
.tixian >>>.van-field__label{
width: 7.2em;
}
.tixian >>> .van-field__body {
border: 1px solid rgb(222, 221, 221);
}
.tixian{
width:700px;
margin: 0 auto;
min-height: 400px;
background: white;
border-radius: 25px;
padding: 25px 0;

}
</style>