<template>
  <div class="home222">

    <div class="content245">
      <div class="shuye">
        <img src="../assets/img1/nc_bg.png"/>
      </div>

      <!--顶部内容-->
      <div class="header public_flex">
          <div class="header1 public_flex">
            <div class="touxiang" @click="tomy">
                <img :src="user.head" style />
            </div>
            <div class="name one_hide">{{user.user_name}}</div>
          </div>
          <div  class="header2 public_flex">
            <div class="usdt one_hide"  @click="tochongzhi">
                {{user.jinbi}}
            </div>
            <div class="points one_hide"  @click="jiaoyi">
              {{user.niunai}}
            </div>
            
          </div>
        </div>
        <img src="../assets/img1/qq1.png" class="qq1 up_moveqq1" />
      <img src="../assets/img1/qq2.png" class="qq2 up_moveqq2">
      <img src="../assets/img1/qq3.png" class="qq3 up_moveqq3" />
      <img src="../assets/img1/fs.png" class="fs up_movefs" />
        <div style="height:20px"></div>
        <!-- <div class="daojishi" style="color: #852121;" v-if="cow&&cow.cow.is_xn==1">Adult date:{{cow.cow.zc_time1}}</div>
        <div class="daojishi" style="color: #852121;" v-else>The remaining {{cow.cow.cndjs}} seconds</div> -->
 <!-- <img :src="require('../assets/img1/xn11.png')" class="xiaoniao up_move"/> -->
 <img :src="require('../assets/img1/mf11.png')" class="mifeng up_move1"/>
 
 <div class="detail_li" >
          
          <!-- <div class="mupai">
            <van-image :src="require('../assets/img/images/mupai.png')" /> 
          </div>-->
          <div v-if="item.zhong&&item.zhong.id!=undefined">
            <div v-if="item.zhong.zt == 'max'">
            <div class="max_shu">
                      <img src="../assets/img1/cs.png"  v-if="!item.zhong.logo"/>
                      <img :src="item.zhong.logo" v-else/>
             </div>    <div class="max_shu">
                      <img src="../assets/img1/cs.png"  v-if="!item.zhong.logo"/>
                      <img :src="item.zhong.logo" v-else/>
             </div>
        
          </div>
             <div v-else-if="item.zhong.zt=='zhong'">
                  <div class="zhong_shu">
                   <img src="../assets/img1/zh.png"/>
                  </div>
                 
             </div>
             <div v-else-if="item.zhong.zt=='xiao'">
                  <div class="xiao_shu">
                   <img src="../assets/img1/xh.png"/>
                  </div>
                
             </div>
         </div>
         <div v-else>
        
           <div class="jia">
        
             <img src="../assets/img1/zz.png"/>
            
           </div>
        
         </div>
      
           <p class="code one_hide">{{item.code}}</p>
      </div>
      <div class="ctr_btn public_flex">
<div class="ctr_btn1" style="width:100%">
<van-button round type="success" color="#ffb53c" @click="jiaoshui">{{text1[lang]}}</van-button>
</div>
<!-- <van-button round type="success" color="#ffb53c">Fast charging</van-button> -->
</div>
  <img src="../assets/img1/js.gif" class="jiaoshui" v-if="js_show"/>
 
<!-- <img src="../assets/img/images/nd.png" class="naidi"/> -->

<!---ce-->
    </div>



<!--自制的tabbar-->
<div style="height:80px"></div>

<van-tabbar v-model="active_bar" @change="click_tab">
  <van-tabbar-item icon="wap-home">{{ text31[lang] }}</van-tabbar-item>
  <van-tabbar-item icon="fire">{{ text32[lang] }}</van-tabbar-item>
  <van-tabbar-item icon="manager">{{ text33[lang] }}</van-tabbar-item>
</van-tabbar>
  <!-- <div class="tabbar">
     <van-row>
  <van-col span="8">        <van-image :src="require('../assets/img/images/home.png')" @click="index"/>  </van-col>

    <van-col span="8">    <van-image :src="require('../assets/img/images/muchan.png')" @click="muchang"/> </van-col>
  <van-col span="8"><van-image :src="require('../assets/img/images/my.png')" @click="tomy"/> </van-col>
    </van-row>
  </div> -->






    <!-- <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
  </div>
</template>

<script>
// @ is an alias to /src

import * as local from "@/libs/local";
//import HelloWorld from '@/components/HelloWorld.vue';
import { Toast } from "vant";
import Vue from "vue";
import { Swipe, SwipeItem } from "vant";
import { Tabbar, TabbarItem } from "vant";
import { Grid, GridItem } from 'vant';
import * as api from "@/api";
import { ref } from "vue";
import { Tab, Tabs } from 'vant';
import { Col, Row } from 'vant';
import { Empty } from 'vant';
import { Popup } from 'vant';
import { Cell, CellGroup } from 'vant';
import { Dialog } from 'vant';
//import * as loginUtil from '@/utils/loginUtil';

Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(local);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Col);
Vue.use(Row);
Vue.use(Empty);
Vue.use(Popup);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Dialog);
export default {
  name: "Home",
  components: {
    //HelloWorld
  },
  data() {
    return {
      title: "商品列表",
      active: 0,
      active_bar:-1,
      productList: [],
      actions: [
      { text: 'Planting seeds'},
    ],
    off:[30,30],
      howExchangeItem: null,
      popHowExchange: false,
      isshow:1,
      user:{
        "niunai":0.00,
        "jinbi":0.00
      },
      js_show:false,
      showPopover:false,
      show: false,
       show1: false,
        show2: false,
         show4: false,
           show3: false,
            show6: false,
               show5: false,
        kpzlb:[],
        ksjlb:[],
        fmjl:[],
        cnjl:[],
         pzjl:[],
      setting:{},
      manghe_setting:{
        count:0
      },
      category:[],
      cow:{
        cow:{"cndjs":0}
      },
     item:{
      },
      lock:false,
      lang:0,
      text1:["watering","سقي","Arrosage"],
      text31:["Home","منزل .","Maison"],
      text32:["The farm","مزرعه","La ferme"],
      text33:["My"," فرد . ","Le mien"],
    };
  },
  setup() {
   const active = ref(0);
    return { active };
    
  },
  created: function() {
      this.lang=local.readInfo('setting_lang')==undefined||local.readInfo('setting_lang')==null?0:local.readInfo('setting_lang')
        this.get_imdex_data()
        setTimeout(()=>{
     this.showPopover=true;
      },2000)
  },
  methods: {
    jiaoshui:function(){
      if(this.lock){return}
        this.lock=true
      api.jiao({
            token: local.readInfo('jwt'),
            td_id:this.item.id,
            zz_id:this.item.zhong.id,
           }, (err, data) => {
            this.lock=false
          if (!err && data.code === 1) {
             
               this.js_show=true
                setTimeout(()=>{
                 this.js_show=false
                 Toast(data.yy[this.lang])
                   },2000)
          } else {
              console.log(data)
              if(data){
                Toast(data.yy[this.lang])
              }
            //this.block = false
          }
           this.lock=false
        })
   
    },
    onSelect :function(action){
      Toast(action.text)
    },
    click_tab:function(index){
      console.log(index)
      if(index==1){
        this.muchang();
      }else if(index==2){
        this.tomy()
      }else if(index==0){
        this.index()
      }
    },
    shengji:function(s_id){
        if(this.lock){return}
        this.lock=true
                   Dialog.confirm({
          title: 'Cows to upgrade',
          message: 'Cows upgrade can improve the cow milk production, confirm upgrade',
          confirmButtonText:"confirm",
          cancelButtonText:"cancel"
       })
    .then(() => {
         api.zhihui11({
            token: local.readInfo('jwt'),
            id:this.cow.cow.id,
            id1:s_id,
            type:"shengji"
           }, (err, data) => {
          if (!err && data.code === 1) {
               Toast(data.msg)
               this.get_imdex_data();
          } else {
              console.log(data)
              Toast(data.msg)
            //this.block = false
          }
           this.lock=false
        })
     })
  .catch(() => {
    console.log('quxiao')
       this.lock=false
  });
       
    },

    zhihui() {
      this.show1 = true;
    },
    howExchange: function(item) {
      // 如何兑换
      this.howExchangeItem = item;
      this.popHowExchange = true;
    },
    get_imdex_data:function(){
    
        api.zzdetail({
        token: local.readInfo('jwt'),
        id:this.$route.query.id
        }, (err, data) => {
          if (!err && data.code === 1) {
                this.user=data.data.user
                 this.item=data.data.cow
           
          } else {
              console.log(data)
          Toast(data.msg)
            //this.block = false
         
          }
        
        })
    },
    buy:function(cow){
            Dialog.confirm({
          title: 'Buy a cow',
          message: 'Buy the cows need '+cow.price+' grams of milk, confirm to buy',
          confirmButtonText:"confirm",
          cancelButtonText:"cancel"
       })
    .then(() => {
    //确认前往盲盒
        api.buy({
        token: local.readInfo('jwt'),
        id:cow.id
        }, (err, data) => {
          if (!err && data.code === 1) {
                 Toast(data.msg)
          } else {
              console.log(data)
                 Toast(data.msg)
            //this.block = false
         
          }
        
        })
     })
  .catch(() => {
    console.log('quxiao')
  });
    },
    to_not_open:function(){
      Toast("A task is not open");
    },
    close:function(){
      this.isshow=1
    },
   index: function() {
      let _this = this;
      _this.$router.push({ name: "Home", query: { redId: 1111 } });
    },
    tolang: function() {
      let _this = this;
      _this.$router.push({ name: "language", query: { redId: 1111 } });
    },
     
     gengduo: function() {
      let _this = this;
      _this.$router.push({ name: "buy", query: { redId: 1111 } });
    },
     muchang: function() {
      let _this = this;
      _this.$router.push({ name: "muchang", query: { redId: 1111 } });
    },
       tomy: function() {
      let _this = this;
      _this.$router.push({ name: "my", query: { redId: 1111 } });
    },
     jiaoyi: function() {
      let _this = this;
      _this.$router.push({ name: "jiaoyi", query: { redId: 1111 } });
    }
    ,
    tochongzhi: function() {
      let _this = this;
      _this.$router.push({ name: "cz_history", query: { redId: 1111 } });
    }
  
  }
};
</script>
<style scoped>
.baise>>> .van-cell__title{color: white!important;}

.home222 {
  background-color: #56a62e;
  padding-bottom: 200px;
  height: 100vh;
}
.content245{
  width:100%;
  height: 100vh;

  background: url('../assets/img1/ncbg1.jpg') no-repeat #56a62e;
   background-size: 100%;
   background-color: #56a62e;
   padding-top: 70px;
   box-sizing: border-box;
   
}
.shuye{
  width: 100%;
  height:122px;
  position: fixed;
  bottom: 50px;
  left: 0;;
}
.shuye img{width:100%}
.up_move3{
	animation: up5 1s linear infinite;
       }
  @keyframes up5 {
	
	0%{
        top:15px;
	}
	50%{
        top:30px;
	}
    100%{
        top:10px;
	}
  }
.detail_li{

  width:400px;
  height: 302px;
  position: relative;
  margin: 0 auto;
  background: url('../assets/img1/tudi.png') no-repeat;
   background-size: 100%;
   margin-top:500px;
}
.detail_li .code{
  display: block;
  width:145px;height: 30px;
  font-size: 19px;
  line-height: 30px;
  color:white;
  position: absolute;
  bottom:75px;
  left:130px
}
.detail_li .jia{
  position: absolute;
  width:64px;
  height: 38px;
  top:110px;
  left:165px
}
.btn{
  width:100px;
  height: 130px;
  position: absolute;

}
.btn img{width: 100%;}
.btn p{
  color:white;
  font-size: 20px;
  font-weight: 800;
  text-align: center;
  line-height: 30px;
  margin-top: -35px;
}
.btn1{
  left:20px;
  top:600px
}
.btn2{
  left:20px;
  top:750px
}
.btn3{
  left:20px;
  top:900px
}
.btn4{
  right:20px;
  top:600px
}
.btn5{
  right:20px;
  top:750px
}
.ctr_btn{
  width:600px;margin: 0 auto;
}

.ctr_btn .ctr_btn1{
  width:50%
}
.ctr_btn .ctr_btn2{
  width:50%
}
.detail_li .jia img{
  width:100%;
}
.detail_li .max_shu {
  position: absolute;
  width:256px;
  height: auto;
  top:-140px;
  left:65px
}
.detail_li .max_shu img{
  width:100%;
}
.detail_li .zhong_shu{
  position: absolute;
  width:88px;
  height: 116px;
  top:10px;
  left:135px
}
.detail_li .zhong_shu img{
  width:100%;
}
.detail_li .xiao_shu{
  position: absolute;
  width:76px;
  height: 64px;
  top:10px;
  left:135px
}
.detail_li .xiao img{
  width:100%;
}
.detail_li .jindou{
  position: absolute;
  width:66px;
  height: 66px;
  top:10px;
  right:30px ;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 100%;
}
.detail_li .jindou img{
  width:46px;
  display: block;
  margin: 5px;
}
.detail_li .shijian{
  position: absolute;
  width:260px;
  height: 45px;
  top:-50px;
  right:60px ;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 13px;
  line-height: 45px;
  text-align: center;
  color:white;
  font-size: 12px;

}
.detail_li .guoshi{
  position: absolute;
  width:260px;
  height: 45px;
  top:-50px;
  right:60px ;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 13px;
  line-height: 45px;
  text-align: center;
  color:white;
  font-size: 12px;

}

.tab_nairong{
    width:720px;
    margin: 0 auto;
    min-height: 100vh;
    background: #ffffff;
    border: 5px solid #ffdd96;
    border-radius: 0 0 10px 10px;
    box-sizing: border-box;
    padding: 20px;
}
.tab_nairong .li{
  width:100%;
  height: auto;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 10px;
  background: #fff4d4;
  margin-top: 15px;
}
.tab_nairong .miumin{
  font-size: 16px;
  font-weight: bold;
  word-wrap: break-word;
	word-break: break-all;
  color: #ba8a0a;
}
.tab_nairong .dengji{
  font-size: 16px;
  font-weight: bold;
  word-wrap: break-word;
	word-break: break-all;
  color: #ba8a0a;
}
.tab_nairong .jiage{
  font-size: 16px;
  font-weight: bold;
  word-wrap: break-word;
	word-break: break-all;
  color: #ba8a0a;
}
.tab_nairong .miumin1{
  padding-left: 10px;
  text-align: left;
}
.gengduo{
  width: 100%;
  height: 40px;
  position: relative;
}
.gengduo img{
  display: block;
  position: absolute;
  width: 97px;
  height: 52px;
  top:-20px;
  left:323px;
}

.jiaoshui_list{
  width:700px;
  margin: 0 auto;
  border-radius: 15px;
  background: #ecf1cf;
  min-height: 300px;
}
.ncc{
  width: 385px;
  height: 270px;
  position: absolute;
  left:183px;
  top:760px
}
.xiaoniao{
  width: 100px;
  height: 113px;
  position: absolute;
  left:80px;
  top:256px
}
.mifeng{
  width: 108px;
  height: 128px;
  position: absolute;
  right:0px;
  top:911px
}
.jiaoshui{
  width: 450px;
  height: 450px;
  position: absolute;
  left:200px;
  top:457px
}
.shouzhi{
  width: 90px;
  height: 100px;
  position: absolute;
  left:500px;
  top:1197px
}
.naidi{
  width: 166px;
  height: 140px;
  position: absolute;
  left:390px;
  top:436px
}
.daojishi{
  width: 550px;
  height: 50px;
  position: absolute;
  text-align: center;
  line-height: 50px;
  font-size: 34px;
  color:#fff;
  left:100px;
  top:480px
}
     .up_move{
	animation: up 2s linear infinite;
       }
  @keyframes up {
	
	0%{
        top:256px;
	}
	50%{
        top:270px;
	}
    100%{
        top:250px;
	}
  }
      .up_move1{
	animation: up1 2s linear infinite;
       }
  @keyframes up1 {
	
	0%{
        right:15px;
	}
	50%{
        right:30px;
	}
    75%{
      right:0px;
	}
    100%{
      right:15px;
	}
  }

      .up_move2{
	animation: up2 1s linear infinite;
       }
  @keyframes up2 {
	
	0%{
        top:800px;
        left:500px
	}

50%{
        top:850px;
        left:540px
	}

100%{
        top:800px;
        left:500px
	}
 
  }

  .xq_btn{
    width:100%;height: auto;
  
  }
  .xq_btn .li_btn{
    width:auto;
    height: 65px;
    padding: 0 15px;
    line-height: 65px;
    background: #fd9a08;
    color: white;
    font-size: 32px;
    margin-left: 20px;
    float: left;
    margin-top: 25px;
    border: 2px solid white;
    border-radius: 25px;
  }
    .cz_btn{
    width:350px;
    height: 65px;
    padding: 0 15px;
    line-height: 65px;
    background: #fd9a08;
    color: white;
    font-size: 32px;
 
    float: left;
    margin-top: 25px;
    border: 2px solid white;
    border-radius: 25px;
    position:fixed;
    left:200px;
    bottom: 155px;
  }
  .liebiao{
    min-height: 100vh;
  }
    .zidin1>>>.van-col{
    font-size: 18px;
    color:#fff;
    line-height: 25px;
  }
 .zidin .van-col{
    font-size: 14px;
    color:#fff;
    line-height: 25px;
    /* text-align: left; */
  }
  
.qq1{
  width:31px;height:79px;position:absolute;top:20%;right:35%;z-index: 9;
}
.qq2{
  width:53px;height:124px;position:absolute;top:17%;left:5%;z-index: 9;
}
.qq3{
  width:36px;height:93px;position:absolute;top:29%;left:35%;z-index: 9;
}
.fs{
  width:180px;height:180px;position:absolute;top:395px;left:5px;z-index: 9;
}
.up_movefs{
	animation: upfs 4s linear infinite;
       }
  @keyframes upfs {
	
	0%{
    transform:rotate(0deg)
	}
	
    100%{
      transform:rotate(360deg)
	}
  }

  .up_moveqq1{
	animation: upqq1 5s linear infinite;
       }
  @keyframes upqq1 {
	
	0%{
        top:20%;
	}
    50%{
        top:25%;
	}
  100%{
    top:20%;
	}
  }
  .up_moveqq2{
	animation: upqq2 5s linear infinite;
       }
  @keyframes upqq2 {
	
	0%{
        top:17%;
	}
    50%{
        top:14%;
	}
  100%{
    top:17%;
	}
  }
  .up_moveqq3{
	animation: upqq3 5s linear infinite;
       }
  @keyframes upqq3 {
	
	0%{
        top:29%;
	}
  50%{
        top:35%;
	}
  100%{
    top:29%;
	}
  }
  .home222>>>.van-tabbar{
  background: url('../assets/img1/tab_bg.png') no-repeat;
  background-size: cover;
  padding-top: 1.1rem;
  border: 0!important;
}
.home222>>>.van-tabbar::after{
  border: 0!important;
}
.home222>>>.van-tabbar .van-tabbar-item{
  color:#fff
}
.home222>>>.van-tabbar .van-tabbar-item--active{
  background: rgba(0, 0, 0, 0);
  color:#1989fa
}
</style>
