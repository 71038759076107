<template>
  <div class="about222">
    <div data-v-2ac32f0d data-v-4d9388c2 class="Site" v-if="block">
      <div data-v-2ac32f0d class="van-nav-bar van-nav-bar--fixed">
        <div class="van-nav-bar__left" v-on:click="back">
          <i class="van-icon van-icon-arrow-left van-nav-bar__arrow">
            <!---->
          </i>
        </div>
        <div class="van-nav-bar__title van-ellipsis">{{langs.i1}}
</div>
        <div class="van-nav-bar__right"></div>
      </div>
      <div data-v-50ebf356 data-v-65207978 class="PageBox" style="padding: 0px;">
        <div data-v-50ebf356 class="ScrollBox">
          <div data-v-50ebf356 class="PromoteInfo" style>
            <div style=" padding: 16px 12px; width: 100%;" >
              <h4 data-v-50ebf356>
                <font style="vertical-align: inherit;">
                  <font style="vertical-align: inherit;">{{langs.i2}}:{{data.user.user_name}}</font>
                </font>
              </h4>
              <h4 data-v-50ebf356>
                <font style="vertical-align: inherit;">
                  <font style="vertical-align: inherit;">{{langs.i3}}</font>
                </font>
              </h4>
              <div class="code">
                <img alt="" :src="img+jwt" style="display: block;" />
              </div>
              <h4 data-v-50ebf356 class="m0">
                <font style="vertical-align: inherit;">
                  <font style="vertical-align: inherit;">{{langs.i4}}：</font>
                </font>
                <span data-v-50ebf356 id="IosCode">
                  <font style="vertical-align: inherit;">
                    <font style="vertical-align: inherit;">{{data.user.code}}</font>
                  </font>
                </span>
              </h4>
              <input
                data-v-50ebf356
                id="AppCode"
                type="text"
                readonly="readonly"
                style="position: absolute; opacity: 0;"
              />
              <a data-v-50ebf356 href="javascript:;" class="xia1"   v-clipboard:copy="data.user.code"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError">
                <i data-v-50ebf356 class="van-icon van-icon-description">
                  <!---->
                </i> {{langs.i5}}
              </a>
              <div data-v-50ebf356 class="xia2"  >
                <span data-v-50ebf356 id="IosLink">
             
                  https://www.aisinii.com/#/registeremail?lang={{lang}}&code={{data.user.code}}
                </span>
                <input
                  data-v-50ebf356
                  id="AppLink"
                  type="text"
                  readonly="readonly"
                  style="position: absolute; opacity: 0;"
                />
              </div>
              <a data-v-50ebf356 class="xia3"   href="javascript:;" v-clipboard:copy="url+data.user.code"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError">
                <i data-v-50ebf356 class="van-icon van-icon-description">
                  <!---->
                </i> {{langs.i6}}
              </a>
            </div>
            <div
              data-v-50ebf356
              style="margin-top: 20px; display: flex; justify-content: space-between;"
            >
              <button v-on:click="to_save"
                data-v-50ebf356
                class="van-button van-button--default van-button--normal van-button--block save"
                style=""
              >
                <div data-v-50ebf356 class="van-button__content"  >
                  <span
                    data-v-50ebf356
                    class="van-button__text"
                  >{{langs.i7}}</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>


    <Footer :lang_list="lang_list" :lang="langs" />
  </div>
</template>
 <script>
import Vue from "vue";
import { Step, Steps } from "vant";
import * as local from "@/libs/local";
import { Toast } from "vant";
import * as api from "@/api";
import { Dialog } from "vant";
import Footer from './lyout/footer.vue';
Vue.use(Step);
Vue.use(Steps);
export default {
  components: {
    //HelloWorld
    Footer,
  },
  data() {
    return {
      radio: "1",
      active: 2,
      data: {},
      block: false,
      jwt: "",
      img:"https://api.aisinii.com/qintai_public/api.user1/get_code/token/",
      url:"https://www.aisinii.com/#/registeremail?code=",
      lang:0,
      text1:["back","رد : .","retour"],
     text2:["Invited to share ","أنت مدعو للانضمام إلى الفريق الأول","Invité à partager"],
     text3:["Your best friend","حسابي","Mon compte"],
     text4:["Invite you to join the team","أدعوكم إلى الانضمام إلى فريق أعلى","Vous inviter à rejoindre le top tarm"],
     text5:["Referral code","رمز التوصية","Code de Parrainage"],
     text6:[" Copy recommendation code ","نسخ رمز التوصية","Copier le code de recommandation"],
     text7:[" Copy the invitation link ","نسخ دعوة الرابط","Copiez le lien d'invitation"],
     text8:["Save the QR code","حفظ رمز ثنائي الأبعاد","Enregistrez le code QR"],
     lang_list: [],
    select_lang: {},
    langs: {},
    cate: [],
    };
  },
  created() {
    this.jwt = local.readInfo("jwt");
      this.lang=local.readInfo('setting_lang')==undefined||local.readInfo('setting_lang')==null?0:local.readInfo('setting_lang')
    this.url="https://www.aisinii.com/#/registeremail?lang="+this.lang+"&code="
    api.my(
      {
        token: local.readInfo("jwt"),
        lang:local.readInfo('car_lang'),
      },
      (err, data) => {
        if (!err && data.code === 1) {
          this.data = data.data;
          this.block = true;
          this.lang_list = data.lang_list;
               this.select_lang = data.select_lang;
               this.langs = data.lang;
        } else {
          console.log(data);
          Toast(data.msg);
          this.block = false;
        }
      }
    );
  },
  methods: {
  // 复制成功时的回调函数
    onCopy(e) {
      console.log(e)
      Toast("ok");
    },
    // 复制失败时的回调函数
    onError(e) {
            console.log(e)
      Toast("Sorry, copy failed!");
    },

    back() {
      this.$router.go(-1); //返回上一层
    },
    to_save: function() {
      //The use of mobile phones screenshots for posters
      Dialog.alert({
        message: this.langs.i8,
        confirmButtonText: this.langs.tp2,
      }).then(() => {
        // on close
      });
    }
  }
};
</script>
<style  scoped>

.about222{ min-height:100vh;  width: 100%;
height: auto;
background:  #fff;
background-size: 100%;}
.Content p {
  color: white;
  text-align: left;
}
.Menu[data-v-bef8e3fc] {
  border-top: 10px solid #0e1526;
  margin-bottom: 15px;
}
.m0 {
  margin: 0 !important;
}
.van-grid-item__text {
  font-size: 16px;
  margin-top: 10px;
  color: #000;
}
.van-grid-item__text {
  color: #646566;
  font-size: 12px;
  line-height: 1.5;
  word-wrap: break-word;
}
.van-icon,
.van-icon:before {
  display: inline-block;
}
.van-icon {
  position: relative;
  font: normal normal normal 14px/1 vant-icon;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}
.code{width:270px;height: 270px;margin: 0 auto;  margin-top: 50px;}
.code img{width:100%}
.van-icon__image {
  width: 1em;
  height: 1em;
  object-fit: contain;
}
.Menu[data-v-bef8e3fc] .van-grid-item__text {
  font-size: 15px;
  color: #fff;
  text-align: center;
}
.PageBox{
  min-height: 120vh;
}
.van-grid-item__icon + .van-grid-item__text {
  margin-top: 8px;
}
.MyEarnings .van-grid-item__content {
  color: #4087f1;
  font-size: 22px;
  text-align: center;
  background-color: #151d31;
}
.Main .van-grid-item__content {
  background-color: #151d31;
}
.MyEarnings .van-grid-item__icon-wrapper {
  color: #bbb;
  margin-bottom: 6px;
  font-size: 16px;
}
.van-grid-item__icon-wrapper {
  position: relative;
}
body {

}
.Main {
  position: relative;
}
.Main {
  width: 100%;
  
  overflow: hidden;
}
.IndexBox {
  overflow: hidden;
  /* padding-bottom: 64px; */
}
.IndexBox,
.ScrollBox {
  position: relative;
  width: 100%;

}
.IndexBox,
.ScrollBox {
  position: relative;
  width: 100%;

}
.ScrollBox {
  overflow-x: hidden;
  overflow-y: auto;
}
.van-nav-bar {
  background-color: transparent !important;
  color: white !important;
}
.van-nav-bar {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: 46px;
  line-height: 1.5;
  text-align: center;
  background-color: #fff;
  -webkit-user-select: none;
  user-select: none;
}
.van-nav-bar__left {
  left: 0;
  padding: 0 12px;
}
.van-nav-bar__left {
  left: 0;
}
.van-nav-bar__left,
.van-nav-bar__right {
  position: absolute;
  top: 0;
  bottom: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 0 16px;
  font-size: 14px;
  cursor: pointer;
}
.van-nav-bar__title {
  max-width: 60%;
  margin: 0 auto;
  color:#ff8917!important;
  font-weight: 500;
  font-size: 16px;
}

.PromoteInfo {
  width: 100%;
height: 120vh;
background: url("../assets/img1/yq_bg.jpg") no-repeat #fff;
background-size: 100%;
}
.van-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.van-nav-bar__right {
  right: 0;
  padding: 0 12px;
}
.van-nav-bar__right {
  right: 0;
}
.van-nav-bar__left,
.van-nav-bar__right {
  position: absolute;
  top: 0;
  bottom: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 0 16px;
  font-size: 14px;
  cursor: pointer;
}
.PageBox {
  color: #fff;
}
.PageBox {
  width: 100%;
  position: relative;
}
*,
:after,
:before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.IndexBox,
.ScrollBox {
  position: relative;
  width: 100%;
}
.ScrollBox {
  overflow-x: hidden;
  overflow-y: auto;
}
.PromoteInfo[data-v-50ebf356] {

  color: #333;
  text-align: center;
  display: block;
  min-height: 100%;
}
.PromoteInfo h4 {
  margin-top: 20px;
}
#QRCode[data-v-50ebf356] {
  margin-bottom: 16px;
  display: inline-block;
  padding: 5px;
  background-color: #fff;
  font-size: 0;
}
.PromoteInfo h4[data-v-50ebf356] {
  margin-bottom: 20px;
}
.m0 {
  margin: 0 !important;
}

.PromoteInfo #IosLink[data-v-50ebf356] {
  word-break: break-all;
  margin-top: 15px;
  display: block;
  text-align: center;
  margin-top: 20px;
}
.PromoteInfo a {
  color: #1a1a1a;
  display: block;
  font-size: 34px;
  display: block;
  z-index: 99999999;
}
.save{
  width:94%;margin: 0 auto;
}
.xia1{margin-top:370px;height: 70px;color: #fff!important; 
   background: linear-gradient(180deg, #1563b3 0%, #7869ff 60%, #1a009a 100%);
   text-align: center;
   line-height: 70px;
   display: block;
   border-radius: 16px;
}
.xia2{margin-top:70px;height: 70px;
   border: 1px solid #999;
   text-align: center;
   line-height: 30px;
   display: block;

}
.xia3{margin-top:20px;height: 70px;color: #fff!important; 
   background: linear-gradient(180deg, #1563b3 0%, #7869ff 60%, #1a009a 100%);
   text-align: center;
   line-height: 70px;
   display: block;
   border-radius: 16px;
}
</style>
