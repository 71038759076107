<template>
  <div class="content">
  <van-nav-bar
  :title="langs.t4"
  :left-text="langs.t5"
  left-arrow
  @click-left="onClickLeft"
/>

<div class="dajiange"></div>
<!-- <div class="zhifu public_flex">
   <div class="zf1"  @click="show_z"></div>
   <div class="zf2"  @click="cz"></div>
</div> -->


<div class="list">


<div  >
  <div style="height:20px"></div>

<!-- <van-cell  /> -->

<div class="cz_list"   v-for="(item1,index) in lists" :key="index">
  <!-- <div class="icon tag0">I</div> -->
  <div  class="order_no public_flex">
    <div class="order">{{item1.title}}
    </div>
     <div class="status">
      <!-- <div class="kuang"></div> -->
     </div>
  </div>
     
  <div class="new_list public_flex">
    <div class="logo"><img :src="item1.jp.logo" v-if="item1.jp"/></div>
    <div class="new_content">
      <div class="money public_flex">
      <div class="money1">{{langs.t2}}:</div>
       <div class="money2" >{{item1.jp.name}}</div>
    </div>
    <div class="money public_flex">
      <div class="money1">{{langs.t3}}：</div>
       <div class="money3">{{ item1.status }}</div>
    </div>
       <div class="money public_flex">
         <div class="money1">{{langs.t4}}:</div>
          <div class="money2">{{ item1.create_time }}</div>
        </div>
      
    </div>
  </div>
 
      <van-empty :description="langs.tp15" v-if="lists.length<=0"/>
  </div>
 
</div>

  



  <div style="height:20px"></div>
</div>


<div style="height:80px"></div>

<Footer :lang_list="lang_list" :lang="langs" />

</div>

</template>

<script>
import Vue from "vue";
import { Tab, Tabs } from "vant";
import { List } from "vant";
import { Toast } from "vant";
import * as api from '@/api';
import * as local from "@/libs/local";
import { Form } from 'vant';
import { Field } from 'vant';
import { Dialog } from 'vant';
import { NavBar } from 'vant';
import { Empty } from 'vant';
import { Loading } from 'vant';
import Footer from './lyout/footer.vue';
Vue.use(NavBar);
Vue.use(Form);
Vue.use(Field);
Vue.use(List);
Vue.use(Toast);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Empty);
Vue.use(Dialog);
Vue.use(Loading);

export default {
  name: "Home123213",
  components: {
    //HelloWorld
    Footer,
  },
data() {
  return {
  
    user:{},
    lists:[],
   sub: false,
   lang:0,
   text1:["back","رد : .","retour"],
   text2:["To buy land","شراء أرض","Pour acheter un terrain"],
   text3:["price ","الأسعار","prix"],
   text4:["Give the seed","إرسال البذور","Donnez la graine"],
   text5:["Award points","مكافأة نقاط","Attribuer des points"],
   text6:["The highest level of seed","أعلى مستوى من البذور","Le plus haut niveau de semences"],
   text7:["buy ","شراء","acheter"],
   text8:["level ","مستوى","niveau"],
   text16:["class ","فصل","classe"],
   text9:["star "," نجم ","pièces"],
   text10:["integral","أساسي","points"],
   lang_list: [],
    select_lang: {},
    langs: {},
    cate: [],
  };
},
created() {
 this.get_imdex_data()
   this.lang=local.readInfo('setting_lang')==undefined||local.readInfo('setting_lang')==null?0:local.readInfo('setting_lang')
},
methods: {
  get_imdex_data:function(){
        api.all("/qintai_public/api.user1/order_list",{
        token: local.readInfo('jwt'),
        lang:local.readInfo('car_lang'),
        }, (err, data) => {
          if (!err && data.code === 1) {
              this.user=data.data.user
              this.lists=data.data.data
              this.lang_list = data.lang_list;
              this.select_lang = data.select_lang;
               this.langs = data.lang;
          } else {
              console.log(data)
              Toast("Network error")
              this.sub = false
         
          }
        
        })
    },
  onClickLeft() {
    this.$router.go(-1); //返回上一层
  },   
  onClickRight() {
    let _this = this;
      _this.$router.push({ name: "farm", query: { redId: 1111 } });
  },   
  buy(id){
    if(this.sub){
      return;
    }
    this.sub==true
    Toast.loading({
        message: "loading...",
        forbidClick: true
      });
    api.buytudi({
        token: local.readInfo('jwt'),
        td_id:id
        }, (err, data) => {
          this.sub=false;
          if (!err && data.code === 1) {
            //Toast(data.msg)
              Dialog.alert({
              message:    data.yy[this.lang],
              confirmButtonText: this.lang==0?"confirm":this.lang==1?"أكّد":"confirmer",
             theme: 'round-button',
              }).then(() => {
              // on close
              });
         
          }else{
            if(data){
            Toast.fail(data.yy[this.lang])
            }
          }
        })

  }
 
}
};
</script>

<style scoped>
.home222 {
  background-color: #f7fbe1;
max-width: 750px;
margin: 0 auto;
}
.content {
width: 100%;
height: auto;
min-height: 1624px;
background-color: #f7fbe1;
background-size: 100%;
}


.shuye img {
width: 100%;
}
.dajiange{
height: 55px;
}

.list{
width:96%;
margin: 0 auto;
min-height: 400px;
background: rgba(255, 255, 255, .7);
border-radius: 25px;

}
.list .title{
text-align: left;
color:#333;
font-weight: 700;
font-size: 30px;
line-height: 40px;
padding: 15px 20px;
box-sizing: border-box;
background: #eee;
}
.list .cz_list{
width:95%;
height: 280px;
background: white;
border-radius: 20px;
margin: 0 auto;
margin-top: 20px;
padding: 5px;
box-sizing: border-box;
box-shadow: 3px 5px 10px 1px #edebeb;
}
.list .cz_list .new_list{
  width: 100%;
}
.list .cz_list .new_list .logo{
  width:200px;
  height: 150px;
  overflow: hidden;
}
.list .cz_list .new_list .logo img{
  width:100px
}
.list .cz_list .new_list .new_content{
  width:440px;
  height: 210px;
  overflow: hidden;
}

.list .cz_list .order_no{
  width: 100%;
  height: 35px;
  padding-top: 20px;
  padding-bottom: 10px;
}
.list .cz_list .order_no .order{
  width:50%;
  background: url("../assets/img/wd (3).png") no-repeat;
 background-size: 30px 30px;
 padding-left: 30px;
 box-sizing: border-box;
 height: 35px;
 text-align: left;
 font-size: 24px;
 font-weight: 700;
 color:#333;
 line-height: 35px;
}
.list .cz_list .order_no .status{
  width: 50%;
  height: 35px;
}
.list .cz_list .order_no .status .kuang{
  width: 230px;
  height: 33px;
  border: 1px solid #e4b025;
  padding:0 15px;
  line-height: 33px;
  font-size: 12px;
  color:#e4b025;
  margin-left: 60px;
  border-radius: 7px;
}
.list .cz_list .date{
  line-height: 35px;
  text-align: left;
  font-size: 24px;
 font-weight: 100;
 color:#999999;
 margin-top: 15px;
}
.list .cz_list .money{
  width: 100%;
  height: 30px;
  padding-top: 15px;
}
.list .cz_list .money .money1{
  width:50%;
  text-align: left;
  font-size: 24px;
 font-weight: 100;
 color:#999999;

}

.list .cz_list .money .money3{
  width:50%;
  text-align: right;
  font-size: 24px;
  font-weight: 100;
  color:#f13b17;
}

</style>