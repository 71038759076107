<template>
  <div class="about2">
    <div data-v-2ac32f0d class="van-nav-bar van-nav-bar--fixed">
      <div class="van-nav-bar__left" v-on:click="back">
        <i class="van-icon van-icon-arrow-left van-nav-bar__arrow">
          <!---->
        </i>
      </div>
      <div class="van-nav-bar__title van-ellipsis">{{langs.t1}}</div>
      <div class="van-nav-bar__right"></div>
    </div>
    <div class="detail">
      <div class="title three_hide">
        {{ac.name}}
      </div>

      <div class="content" v-html="ac.describe">
        
      </div>
      <div style="font-size: 14px;color: #666;text-align: right;margin-top: 14px;">    {{ac.jia_date}}</div>
    </div>

  <div style="height:50px"> </div>
  <Footer :lang_list="lang_list" :lang="langs" />
  </div>
</template>
 <script>
 import Vue from 'vue';
 import { Popup } from 'vant';
 import * as local from '@/libs/local'
import { Toast } from 'vant';
import * as api from '@/api';
import { Tab, Tabs } from 'vant';
import Footer from './lyout/footer.vue';
Vue.use(Popup);
Vue.use(Tab);
Vue.use(Tabs);
export default {
  name:"ac_list",
  components: {
    //HelloWorld
    Footer,
  },
  data() {
    return {
       show: false,
       active:0,
        radio: "1",
      ac:{},
      content:'',
      title:'',
      lang:0,
      lang_list: [],
      select_lang: {},
      langs: {},
      cate: [],
    };
  },
 created: function() {
    this.lang=local.readInfo('setting_lang')==undefined||local.readInfo('setting_lang')==null?0:local.readInfo('setting_lang')
     api.ac_detail({
        token: local.readInfo('jwt'),
        lang:local.readInfo('car_lang'),
        id:this.$route.query.id,
        type:this.$route.query.type
        }, (err, data) => {
          if (!err && data.code === 1) {
            this.ac=data.data
            this.lang_list = data.lang_list;
              this.select_lang = data.select_lang;
               this.langs = data.lang;
          } else {
              console.log(data)
          Toast(data.msg)
            //this.block = false
         
          }
        
        })
    let _this = this;
    console.log(_this.$store);
    //let _this = this
    //_this.$router.push({'name': 'About', query: {redId: 1111}})
  },
  methods: {

     showPopup(x,d) {
       this.content=d
       this.title=x;
       console.log(this.content)
      this.show = true;
    },
    back() {
      this.$router.go(-1); //返回上一层
    }
  }
};
</script>
<style scoped>

.content11011 img{
  width: 100%;
height: auto;
min-height: 1624px;
/* background: url("../assets/img1/my_bg.jpg") no-repeat #c0e78f; */
background-size: 100%;
}
.content11011{
  box-sizing: border-box;
  /* background: rgba(0, 0, 0,0.5); */
}


.about2 {
min-height:100vh;  width: 100%;
height: auto;
background:  #ffffff;
background-size: 100%;
max-width: 750px;
margin: 0 auto;
}
.content11011{
  width:100%;
  height: auto;
  min-height: 1624px;

}
.about1 {
   min-height: 1.1vh;
    /* padding-bottom: 64px; */
}

.van-nav-bar {
    color:white!important
}
.van-nav-bar {
    position: relative;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    height: 46px;
    line-height: 1.5;
    text-align: center;
    background-color: #f3f3f3;
    -webkit-user-select: none;
    user-select: none;
}.van-nav-bar__left {
    left: 0;
    padding: 12px;
}
.van-nav-bar__left {
    left: 0;
}
.van-nav-bar__left, .van-nav-bar__right {
    position: absolute;
    top: 0;
    bottom: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    padding: 0 16px;
    font-size: 14px;
    cursor: pointer;
}
.van-nav-bar__title {
    max-width: 60%;
    margin: 0 auto;
    color:#333;
    font-weight: 500;
    font-size: 16px;
}
.detail{
  width: 700px;
  min-height: 100vh;
  background:#f5f5f5e6;
  margin: 0 auto;
  margin-top: 20px;
  border-radius: 20px;
  padding: 10px;
  box-sizing: border-box;
}
.detail .title{
  font-size: 32px;
  font-weight: 500;
  text-align: center;
  color:#333
}
.detail .content{
  font-size: 24px;
  font-weight: 100;
  text-align: left;
  color:#999;
  word-break:break-all
}
.content>>> img{
  display: block!important;
  width: 100%!important;
  margin: 20px auto;
}
</style>