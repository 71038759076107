<template>
  <div class="home222">

    <div class="content">

     <!--顶部内容-->
        <div class="header">
          <div class="touxiang" @click="tomy">
                <img :src="user.head" style />
            </div>
            <div class="name">{{user.user_name}}</div>
            <div class="niunai"  @click="jiaoyi">
              {{user.niunai}}
            </div>
             <div class="jinbi"  @click="tochongzhi">
                {{user.jinbi}}
            </div>
        </div>

   
   <div class="tab_nairong">
     <div class="li" v-for="(item,index) in manghe" :key="index" :class="[{weijiesuo:item.js==0}]">
      <van-row>
  <van-col span="4">      <div class="dian"></div>   <van-image :src="require('../assets/img/mh.png')" />  </van-col>
  <van-col span="13"><p class="miumin1">name:{{item.name}}</p>
  <p class="miumin1">The milk:{{item.niunai}}g</p>
    <p class="miumin1">The gold COINS:{{item.jinbi}}</p>
  </van-col>
  <van-col span="7"> 
    <div style="width:100%;height:16px"></div>
     <van-button round  size="small" type="info" color="#ff6034"  @click="choumanghe(item)" >Open magic box</van-button>
  </van-col>
    </van-row>
      </div>
  <van-empty description="No blind box" v-show="manghe.length<=0"/>
    

    
   </div>



<!---ce-->
    </div>

<!--自制的tabbar-->

  <div class="tabbar">
     <van-row>
  <van-col span="8">        <van-image :src="require('../assets/img/images/home.png')"  @click="index"/>  </van-col>

    <van-col span="8">    <van-image :src="require('../assets/img/images/muchan.png')" @click="muchang" /> </van-col>
  <van-col span="8"><van-image :src="require('../assets/img/images/my.png')"  @click="tomy"/> </van-col>
    </van-row>
  </div>


<div class="bangzhu" @click="help230" ><van-image :src="require('../assets/img/mhbz.png')" /> </div>
  
<div class="jilu" @click="cjjl"><van-image :src="require('../assets/img/cjjl.png')" /> </div>
  

  

<!--牛的详情弹出层--->
<!-- <van-cell is-link @click="showPopup" >展示弹出层</van-cell> -->
<van-popup v-model="show" position="bottom" round :style="{ height: '60%' }" >
<div style="padding:20px;background:#ff6034;" class="help">
   <p>{{setting.help}}</p>
    <van-empty description="No help information" v-show="setting.help.length<=0"/>
</div>

</van-popup>
<!--抽奖记录--->
<van-popup v-model="show1" position="bottom" round  :style="{ height: '70%' }" >
<div style="padding:20px;background:#fff4d4;height:100%">
    <div class="tab_nairong" style="width:100%">
     <div class="li" v-for="(item,index) in mh_list" :key="index">
      <van-row>
  <van-col span="6">        <van-image :src="require('../assets/img/mh.png')" style="width:100%"/>  </van-col>
  <van-col span="1"><div style="width:100%;height:20px"></div></van-col>
  <van-col span="16">
    <p class="miumin1" v-if="item.manghe">Blind box:{{item.manghe.name}}</p>
    <p class="miumin1">date:{{item.create_time}}</p>
    <p class="miumin1">The milk:{{item.price}}g</p>
    <p class="miumin1">The gold COINS:{{item.jinbi}}</p>
    <p class="miumin1" v-if="item.nainiu">The prize:{{item.nainiu.name}}</p>
      <p class="miumin1" v-else>The prize:Not winning</p>
  </van-col>

    </van-row>
      </div>
  <van-empty description="No blind box drawing record" v-show="mh_list.length<=0"/>
   </div>
</div>

</van-popup>



    <!-- <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
  </div>
</template>

<script>
// @ is an alias to /src

import * as local from "@/libs/local";
//import HelloWorld from '@/components/HelloWorld.vue';
import { Toast } from "vant";
import Vue from "vue";
import { Swipe, SwipeItem } from "vant";
import { Tabbar, TabbarItem } from "vant";
import { Grid, GridItem } from 'vant';
import * as api from "@/api";
import { ref } from "vue";
import { Tab, Tabs } from 'vant';
import { Col, Row } from 'vant';
import { Empty } from 'vant';
import { Popup } from 'vant';
import { Cell, CellGroup } from 'vant';
import { Dialog } from 'vant';
//import * as loginUtil from '@/utils/loginUtil';

Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(local);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Col);
Vue.use(Row);
Vue.use(Empty);
Vue.use(Popup);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Dialog);
export default {
  name: "manghe",
  components: {
    //HelloWorld
  },
  data() {
    return {
      title: "商品列表",
      active: 0,
      productList: [],
      howExchangeItem: null,
      popHowExchange: false,
      isshow:1,
      user:{
        "niunai":0.00,
        "jinbi":0.00
      },
      mh_list:[],
      show: false,
       show1: false,
      setting:{
        help:''
      },
      manghe_setting:{
        count:0
      },
      manghe:[],
      category:[],
      cow:{}
    };
  },
  setup() {
   const active = ref(0);
    return { active };
    
  },
  created: function() {
    let EXPIRESTIME = 5000
     let setting1 = local.readInfo('manghe_data')
    if(setting1 === null || setting1 === undefined || setting1 === ''){
        this.get_imdex_data()
    }else{
         let date = new Date().getTime();
      // 如果大于就是过期了，如果小于或等于就还没过期
      if ((date - (setting1.startTime?setting1.startTime:0)) > EXPIRESTIME) {
        this.get_imdex_data()
      }else{
        this.setting=setting1.setting
      
              this.user=setting1.user
                this.manghe=setting1.manghe
           
      }
    }
    let _this = this;
    console.log(_this.$store);
    //let _this = this
    //_this.$router.push({'name': 'About', query: {redId: 1111}})
  },
  methods: {
    help230: function() {
      this.show = true;
    },
    cjjl: function() {
        api.mh_list({
        token: local.readInfo('jwt'),
        }, (err, data) => {
          if (!err && data.code === 1) {
              this.mh_list=data.data.list
          } else {
              console.log(data)
          Toast(data.msg)
            //this.block = false
         
          }
        
        })
      this.show1 = true;
    },
    howExchange: function(item) {
      // 如何兑换
      this.howExchangeItem = item;
      this.popHowExchange = true;
    },
    get_imdex_data:function(){
        api.manghe_data({
        token: local.readInfo('jwt'),
        }, (err, data) => {
          if (!err && data.code === 1) {
            var datas = Object.assign(data.data, { startTime: new Date().getTime() });
             local.saveInfo('manghe_data',datas)
          
              this.user=data.data.user
                this.setting=data.data.setting
                 this.manghe=data.data.manghe
          } else {
              console.log(data)
          Toast(data.msg)
            //this.block = false
         
          }
        
        })
    },
    buy:function(cow){
            Dialog.confirm({
          title: 'Buy a cow',
          message: 'Buy the cows need '+cow.price+' grams of milk, confirm to buy',
          confirmButtonText:"confirm",
          cancelButtonText:"cancel"
       })
    .then(() => {
    //确认前往盲盒
        api.buy({
        token: local.readInfo('jwt'),
        id:cow.id
        }, (err, data) => {
          if (!err && data.code === 1) {
                 Toast(data.msg)
          } else {
              console.log(data)
                 Toast(data.msg)
            //this.block = false
         
          }
        
        })
     })
  .catch(() => {
    console.log('quxiao')
  });
    },
    to_not_open:function(){
      Toast("A task is not open");
    },
    close:function(){
      this.isshow=1
    },
    toline: function() {
      let _this = this;
      _this.$router.push({ name: "line", query: { redId: 1111 } });
    },
      to_update: function() {
      let _this = this;
      _this.$router.push({ name: "update", query: { redId: 1111 } });
    },
      to_share: function() {
      let _this = this;
      _this.$router.push({ name: "promote", query: { redId: 1111 } });
    },
    totask: function() {
      let _this = this;
      _this.$router.push({ name: "task", query: { redId: 1111 } });
    },
   index: function() {
      let _this = this;
      _this.$router.push({ name: "Home", query: { redId: 1111 } });
    },
       tomy: function() {
      let _this = this;
      _this.$router.push({ name: "my", query: { redId: 1111 } });
    },
     jiaoyi: function() {
      let _this = this;
      _this.$router.push({ name: "jiaoyi", query: { redId: 1111 } });
    }
    ,
    tochongzhi: function() {
      let _this = this;
      _this.$router.push({ name: "cz_history", query: { redId: 1111 } });
    },
     gengduo: function() {
      let _this = this;
      _this.$router.push({ name: "buy", query: { redId: 1111 } });
    },
     muchang: function() {
      let _this = this;
      _this.$router.push({ name: "muchang", query: { redId: 1111 } });
    },
  
     choumanghe: function(item) {
          let _this = this;
       if(item.js==0){
         Toast('magic box not unlock, please upgrade your pasture can unlock more magic box')
         return;
       }
      _this.$router.push({ name: "choumanghe", query: { id: item.id,price:item.jinbi,niunai:item.niunai } });
    }
  }
};

</script>
<style  scoped>
.dian{width:100%;height: 10px;}
.my-swipe .van-swipe-item img {
  width: 100%;
  height: auto;
}
.van-tabs__nav--card{margin:0!important}
.home222 {
  background-color: #62a412;
  /* padding-bottom: 200px; */
    max-width: 750px;
  margin: 0 auto;
}
.help p{
  color:white;
  font-size: 14px;
}
.content{
  width:100%;
  height: auto;
  min-height: 1624px;
  background: url('../assets/img/index.jpg') no-repeat #62a412;
   background-size: contain;
   background-color: #62a412;
}

.header{
  position: relative;
  width:100%;
  height:150px
}
.header .touxiang{
  position: absolute;
  width:95px;
  height: 95px;
  border-radius: 100%;
  border: 2px solid white;
  overflow: hidden;
  top:30px;
  left:20px;
  box-sizing: border-box;
}
.header .touxiang img{width: 100%;}
.header .name{
    position: absolute;
  width:190px;
  height: 55px;
  top:49px;
  left:77px;
  line-height: 55px;
  font-size: 28px;
  color:#7b3c1f;
  overflow: hidden;
}
.header .niunai{
  background: url('../assets/img/images/niunai.png') no-repeat;
   background-size: contain;
   width: 220px;
   height: 75px;
   position: absolute;
   padding-left: 64px;
   box-sizing: border-box;
   line-height: 80px;
   color: #ffffff;
   left:270px;
   font-size: 13px!important;
   text-align: left;
    top:39px;

}
.header .jinbi{
  background: url('../assets/img/images/jinbi.png') no-repeat;
   background-size: contain;
   width: 220px;
   height: 75px;
   position: absolute;
   padding-left: 64px;
   box-sizing: border-box;
   line-height: 80px;
   color: #ffffff;
   left:500px;
   font-size: 13px;
   text-align: left;
    top:39px;

}
.lunbo{
  width:700px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 20px;
}
.gongge{
    width:700px;
  margin: 0 auto;
}


.tab_nairong{
    width:720px;
    margin: 0 auto;
    min-height: 300px;
    background: #ff8917;
    border: 5px solid #ffdd96;
    border-radius: 0 0 10px 10px;
    box-sizing: border-box;
    padding: 20px;
}
.tab_nairong .li{
  width:100%;
  padding: 10px;
  height: auto;
  box-sizing: border-box;
  border-radius: 10px;
  background: #fff4d4;
  margin-top: 15px;
}
.tab_nairong .miumin1{
  font-size: 14px;
  /* font-weight: bold; */
  word-wrap: break-word;
	word-break: break-all;
  color: #444340;
  text-align: left;
  margin:5px 0px;
  padding-left: 10px;
  /* white-space:nowrap;

       overflow:hidden;

       text-overflow:ellipsis; */

}
.tab_nairong .dengji{
  font-size: 16px;
  font-weight: bold;
  word-wrap: break-word;
	word-break: break-all;
  color: #ba8a0a;
}
.tab_nairong .jiage{
  font-size: 16px;
  font-weight: bold;
  word-wrap: break-word;
	word-break: break-all;
  color: #ba8a0a;
}
.gengduo{
  width: 100%;
  height: 40px;
  position: relative;
}
.gengduo img{
  display: block;
  position: absolute;
  width: 97px;
  height: 52px;
  top:-20px;
  left:323px;
}

.tabbar{
  position:fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 139px;
}

.bangzhu{
  width:94px;
  height: 94px;
  position: fixed;
  right: 40px;
  bottom: 190px;
}
.jilu{
  width:94px;
  height: 94px;
  position: fixed;
  right: 40px;
  bottom: 300px;
}
.weijiesuo{
   -webkit-filter: grayscale(100%); /* Chrome, Safari, Opera */
    filter: grayscale(100%);
}
</style>
