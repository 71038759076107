<template>
  <div class="home222">

    <div class="content">

      <!--顶部内容-->
        <div class="header">
            <div class="touxiang" @click="tomy">
                <img :src="user.head" style />
            </div>
            <div class="name">{{user.user_name}}</div>
            <div class="niunai"  @click="jiaoyi">
              {{user.niunai}}
            </div>
             <div class="jinbi"  @click="tochongzhi">
                {{user.jinbi}}
            </div>
        </div>

  


<!---->
<div class="tabs" >

   
   <div class="tab_nairong" >
     <div class="li" @click="showPopup(item1)" v-for="(item1,index1) in category" :key="index1">
      <van-row>
  <van-col span="4">      
      <van-image :src="require('../assets/images/r_jqr.png')" v-if="item1.type==1"/> 
     <van-image :src="require('../assets/images/y_jqr.png')" v-else/> 
  </van-col>
  <van-col span="13">
    <p class="miumin">days:{{item1.day}}</p>
     <p class="miumin">type:{{item1.type==1?"Automatic charging milk":"Automatic production/collection of milk"}}</p>
     <div class="goumai"> <van-image :src="require('../assets/img/images/buy.png')" /> </div>
        
  </van-col>
  <van-col span="7"><p class="jiage">{{item1.price}}<van-image :src="require('../assets/img/images/nai.png')" /></p></van-col>
    </van-row>
      </div>

  <van-empty description="No this breeds of cows" v-show="category.length<=0"/>
    
   </div>
  


</div>



<!---ce-->
    </div>

<!--自制的tabbar-->

  <div class="tabbar">
     <van-row>
  <van-col span="8">        <van-image :src="require('../assets/img/images/home.png')" @click="index" />  </van-col>

    <van-col span="8">    <van-image :src="require('../assets/img/images/muchan.png')" @click="muchang" /> </van-col>
  <van-col span="8"><van-image :src="require('../assets/img/images/my.png')"  @click="tomy"/> </van-col>
    </van-row>
  </div>



  

  

<!--牛的详情弹出层--->
<!-- <van-cell is-link @click="showPopup" >展示弹出层</van-cell> -->
<van-popup v-model="show" position="bottom" round :style="{ height: '80%' }" >
<div style="padding:20px;background:#fff4d4;height:100%" class="baise">


   <van-row>
  <!-- <van-col span="4">       
     <van-image :src="require('../assets/img/images/niu.png')" />
     <div style="width:100%;height:30px"></div> 

   </van-col> -->
  <van-col span="24">
       <van-cell-group inset>
  <van-cell title="name" :value="cow.name" />
  <van-cell title="days" :value="cow.day+'day'" />
  <van-cell title="price" :value="cow.price+'grams of milk'" />
  
</van-cell-group>

  </van-col>

    </van-row>
        <div style="width:100%;height:20px"></div> 
<div style="width:100%;margin:0 auto">
       <van-radio-group v-model="checked">
  <van-cell-group inset>
    <van-cell title="Effective immediately" clickable @click="checked = '1'">
      <template #right-icon>
        <van-radio name="1" />
      </template>
    </van-cell>
    <van-cell title="The next day to take effect" clickable @click="checked = '2'">
      <template #right-icon>
        <van-radio name="2" />
      </template>
    </van-cell>
  </van-cell-group>
</van-radio-group>
</div>
       <div style="width:100%;height:10px"></div> 
     <van-row>
  <van-col span="8">       
     <div style="width:100%;height:30px"></div> 
   </van-col>
  <van-col span="8">
     <van-image :src="require('../assets/img/images/buy.png')" @click="buy(cow)"/> 
  </van-col>
<van-col span="8">       
     <div style="width:100%;height:30px"></div> 
   </van-col>
    </van-row>
</div>

</van-popup>




    <!-- <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
  </div>
</template>

<script>
// @ is an alias to /src

import * as local from "@/libs/local";
//import HelloWorld from '@/components/HelloWorld.vue';
import { Toast } from "vant";
import Vue from "vue";
import { Swipe, SwipeItem } from "vant";
import { Tabbar, TabbarItem } from "vant";
import { Grid, GridItem } from 'vant';
import * as api from "@/api";
import { ref } from "vue";
import { Tab, Tabs } from 'vant';
import { Col, Row } from 'vant';
import { Empty } from 'vant';
import { Popup } from 'vant';
import { Cell, CellGroup } from 'vant';
import { RadioGroup, Radio } from 'vant';
import { Dialog } from 'vant';
//import * as loginUtil from '@/utils/loginUtil';
import * as update from '@/utils/update'


Vue.prototype.upd=update
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Tabbar);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(TabbarItem);
Vue.use(local);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Col);
Vue.use(Row);
Vue.use(Empty);
Vue.use(Popup);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Dialog);
export default {
  name: "buy",
  components: {
    //HelloWorld
  },
  data() {
    return {
      title: "商品列表",
      active: 0,
      productList: [],
      howExchangeItem: null,
      popHowExchange: false,
      isshow:1,
      user:{
        "niunai":0.00,
        "jinbi":0.00
      },
      checked:"1",
      red_count:0,
      yellow_count:0,
      show: false,
      setting:{},
      manghe_setting:{
        count:0
      },
      category:[],
      cow:{},
      lock:false
    };
  },
  setup() {
   const active = ref(0);
    return { active };
    
  },
  created: function() {
    let EXPIRESTIME = 5000
     let setting1 = local.readInfo('buy_data')
    if(setting1 === null || setting1 === undefined || setting1 === ''){
        this.get_imdex_data()
    }else{
         let date = new Date().getTime();
      // 如果大于就是过期了，如果小于或等于就还没过期
      if ((date - (setting1.startTime?setting1.startTime:0)) > EXPIRESTIME) {
        this.get_imdex_data()
      }else{
  
                 this.user=setting1.user
                 this.category=setting1.category
                 this.red_count=setting1.red_count
                 this.yellow_count=setting1.yellow_count
      }
    }
    // let _this = this;
    // console.log(_this.$store);

    //let _this = this
    //_this.$router.push({'name': 'About', query: {redId: 1111}})
  },
  methods: {
    showPopup(cow) {
      this.show = true;
      console.log(cow)
       this.cow= cow;
    },
    howExchange: function(item) {
      // 如何兑换
      this.howExchangeItem = item;
      this.popHowExchange = true;
    },
    get_imdex_data:function(){
      if(this.lock){return}
      this.lock=true
        api.jqr({
        token: local.readInfo('jwt'),
        }, (err, data) => {
          if (!err && data.code === 1) {
            this.setting=data.data.setting
            var datas = Object.assign(data.data, { startTime: new Date().getTime() });
             local.saveInfo('buy_data',datas)
              this.user=data.data.user
                 this.category=data.data.category
                     this.red_count=data.data.red_count
                 this.yellow_count=data.data.yellow_count
          } else {
              console.log(data)
              console.log(err)
         // Toast(data.msg)
            //this.block = false
         
          }
          this.lock=false
        
        })
    },
    buy:function(cow){
      if(cow.type==1&&this.yellow_count>0||cow.type==2&&this.red_count>0){
                     Dialog.confirm({
          title: 'Helpful hints',
          message: 'Buy the robot will be covered, confirm to buy?',
          confirmButtonText:"confirm",
          cancelButtonText:"cancel"
       })
       .then(() => {
       this.buy1(cow)
      })
      .catch(() => {
    console.log('quxiao')
       });
      }else{
        this.buy1(cow)
      }
  
    },
        buy1:function(cow){
            Dialog.confirm({
          title: 'Buy a robot',
          message: 'Buy the robot takes '+cow.price+' grams of milk, sure to buy',
          confirmButtonText:"confirm",
          cancelButtonText:"cancel"
       })
    .then(() => {
    //确认前往盲盒
        api.buy_jqr({
        token: local.readInfo('jwt'),
        id:cow.id,
        sx:this.checked
        }, (err, data) => {
          if (!err && data.code === 1) {
                 Toast(data.msg)
                 //this.get_imdex_data()
                   this.$router.push({ name: "muchang", query: { redId: 1111 } });
          } else {
              console.log(data)
                 Toast(data.msg)
            //this.block = false
         
          }
        
        })
     })
  .catch(() => {
    console.log('quxiao')
  });
    },
    to_not_open:function(){
      Toast("A task is not open");
    },
    close:function(){
      this.isshow=1
    },
    toline: function() {
      let _this = this;
      _this.$router.push({ name: "line", query: { redId: 1111 } });
    },
      to_update: function() {
      let _this = this;
      _this.$router.push({ name: "update", query: { redId: 1111 } });
    },
      to_share: function() {
      let _this = this;
      _this.$router.push({ name: "promote", query: { redId: 1111 } });
    },
    totask: function() {
      let _this = this;
      _this.$router.push({ name: "task", query: { redId: 1111 } });
    },
    index: function() {
      let _this = this;
      _this.$router.push({ name: "Home", query: { redId: 1111 } });
    },
     gengduo: function() {
      let _this = this;
      _this.$router.push({ name: "buy", query: { redId: 1111 } });
    },
     muchang: function() {
      let _this = this;
      _this.$router.push({ name: "muchang", query: { redId: 1111 } });
    },
    manghe: function() {
      let _this = this;
      _this.$router.push({ name: "manghe", query: { redId: 1111 } });
    },
       tomy: function() {
      let _this = this;
      _this.$router.push({ name: "my", query: { redId: 1111 } });
    },
     jiaoyi: function() {
      let _this = this;
      _this.$router.push({ name: "jiaoyi", query: { redId: 1111 } });
    }
    ,
    tochongzhi: function() {
      let _this = this;
      _this.$router.push({ name: "cz_history", query: { redId: 1111 } });
    }
  }
};
</script>
<style scoped>
.baise>>> .van-cell__title{color: white!important;text-align: left;}
.my-swipe .van-swipe-item img {
  width: 100%;
  height: auto;
}
.van-tabs__nav--card{margin:0!important}
.home222 {
  background-color: #62a412;
  /* padding-bottom: 200px; */
    max-width: 750px;
  margin: 0 auto;
}
.content{
  width:100%;
  height: auto;
  min-height: 1624px;
  background: url('../assets/img/index.jpg') no-repeat #62a412;
   background-size: contain;
   background-color: #62a412;
}

.header{
  position: relative;
  width:100%;
  height:150px
}
.header .touxiang{
  position: absolute;
  width:95px;
  height: 95px;
  border-radius: 100%;
  border: 2px solid white;
  overflow: hidden;
  top:30px;
  left:20px;
  box-sizing: border-box;
}
.header .touxiang img{width: 100%;}
.header .name{
    position: absolute;
  width:190px;
  height: 55px;
  top:49px;
  left:77px;
  line-height: 55px;
  font-size: 28px;
  color:#7b3c1f;
  overflow: hidden;
}
.header .niunai{
  background: url('../assets/img/images/niunai.png') no-repeat;
   background-size: contain;
   width: 220px;
   height: 75px;
   position: absolute;
   padding-left: 64px;
   box-sizing: border-box;
   line-height: 80px;
   color: #ffffff;
   left:270px;
   font-size: 13px!important;
   text-align: left;
    top:39px;

}
.header .jinbi{
  background: url('../assets/img/images/jinbi.png') no-repeat;
   background-size: contain;
   width: 220px;
   height: 75px;
   position: absolute;
   padding-left: 64px;
   box-sizing: border-box;
   line-height: 80px;
   color: #ffffff;
   left:500px;
   font-size: 13px;
   text-align: left;
    top:39px;

}

.lunbo{
  width:700px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 20px;
}
.gongge{
    width:700px;
  margin: 0 auto;
}
.van-tabs__nav{
    background: url('../assets/img/images/tab.png') no-repeat;
   background-size: contain;
      background-color: rgba(0, 0, 0, 0) !important;
}
.van-tab__text{color: white!important;}
.tab_nairong{
    width:720px;
    margin: 0 auto;
    min-height: 300px;
    background: #ffffff;
    border: 5px solid #ffdd96;
    border-radius: 0 0 10px 10px;
    box-sizing: border-box;
    padding: 20px;
}
.tab_nairong .li{
  width:100%;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 10px;
  background: #fff4d4;
  margin-top: 15px;
}
.tab_nairong .miumin{
  font-size: 26px;
  font-weight: bold;
  word-wrap: break-word;
	word-break: break-all;
  color: #ba8a0a;
  text-align: left;
  margin: 0!important;
}
.tab_nairong .dengji{
  font-size: 26px;
  font-weight: bold;
  word-wrap: break-word;
	word-break: break-all;
  color: #ba8a0a;
}
.tab_nairong .jiage{
  font-size: 28px;
  font-weight: bold;
  word-wrap: break-word;
	word-break: break-all;
  color: #ba8a0a;
}
.goumai >>>.van-image{
  width:70px;
  margin: 0 auto;
}
.tab_nairong .jiage >>>.van-image{
  width:40px;
  display: block;
  margin: 0 auto;
}
.gengduo{
  width: 100%;
  height: 40px;
  position: relative;
}
.gengduo img{
  display: block;
  position: absolute;
  width: 97px;
  height: 52px;
  top:-20px;
  left:323px;
}

.tabbar{
  position:fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 139px;
}


.van-grid-item__content {
  background-color: rgba(0, 0, 0, 0) !important;

}
.Title {
  background-color: #151d31 !important;
  color: #fff !important;
  margin: 4px 0 !important;
  padding: 7px 25px !important;
}
.topItem {
  line-height: 1;
  color: #fff;
}
.topItem .van-cell__left-icon {
  height: 46px;
  width: 46px;

  border-radius: 100%;
  overflow: hidden;
  padding: 2px;
}
.van-cell{
    background-color: rgba(0, 0, 0, 0) !important;
}
/* .van-cell__title,
.van-cell__value {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
} */
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;

}
.van-tabs__content .van-swipe__indicators {
  display: none !important;
}
.van-cell::after {
  border: 0px !important;
}
.topItem .van-cell__title span {
  color: white;
  text-align: left!important;
  font-size: 14px;
  display: block;
  width: 100%;
}
.topItem .van-cell__title div {
  color: white;
  text-align: left!important;
  font-size: 12px;
}
.NoticePopup {
  background: url(../assets/images/bg_mine.0c92df27.png) no-repeat #052058;
  background-size: contain;
  height: 515px;
  padding: 0 15px 15px 15px;
  border-radius: 7px;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
}
.NoticePopup dt {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  line-height: 52px;
}
.NoticePopup dd {
  margin: 0 !important;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 7px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 7px;
  color: #ccc;
  flex: auto;
  text-align: justify;
}
.van-nav-bar__text {
  margin: 0;
  padding: 0;
  color: #bbb !important;
}
.van-nav-bar__text {
  margin: 0;
  padding: 0;
  color: #bbb !important;
}
</style>
