<template>
  <div class="home222">
    <div class="content">
      <div style="width: 100%; height: 30px"></div>
      <div data-v-65207978 data-v-4d9388c2 class="Main111" v-if="block">
        <!-- <div data-v-65207978 class="van-nav-bar van-nav-bar--fixed">
      <div class="van-nav-bar__left" v-on:click="back">
        <i class="van-icon van-icon-arrow-left van-nav-bar__arrow">
      
        </i>
      </div>
      <div class="van-nav-bar__title van-ellipsis">Personal information</div>
      <div class="van-nav-bar__right"></div>
    </div> -->
        <div data-v-53d4c15c data-v-65207978 class="PageBox">
          <div data-v-53d4c15c class="ScrollBox11">
            <div
              data-v-53d4c15c
              role="button"
              tabindex="0"
              class="
                van-cell van-cell--clickable van-cell--center van-cell--large
              "
              v-on:click="show"
            >
              <i data-v-53d4c15c class="van-icon van-cell__left-icon">
                <img
                  data-v-53d4c15c
                  src="../assets/images/info_0011.png"
                  class="van-icon__image"
                />
                <!---->
              </i>
              <div data-v-53d4c15c class="van-cell__title">
                <span data-v-53d4c15c>{{langs.a1}}</span>
              </div>
              <div data-v-53d4c15c class="van-cell__value">
                <img data-v-53d4c15c :src="data.user.head" height="45" />
              </div>
              <i
                data-v-53d4c15c
                class="van-icon van-icon-arrow van-cell__right-icon"
              >
                <!---->
              </i>
            </div>
            <div
              data-v-53d4c15c
              class="van-cell van-cell--center van-cell--large"
            >
              <i data-v-53d4c15c class="van-icon van-cell__left-icon">
                <img
                  data-v-53d4c15c
                  src="../assets/images/info_0021.png"
                  class="van-icon__image"
                />
                <!---->
              </i>
              <div data-v-53d4c15c class="van-cell__title">
                <span data-v-53d4c15c>{{langs.a3}}</span>
              </div>
              <div data-v-53d4c15c class="van-cell__value">
                <span data-v-53d4c15c>{{ data.user.phone }}</span>
              </div>
            </div>
            <div
              data-v-53d4c15c
              role="button"
              tabindex="0"
              class="
                van-cell van-cell--clickable van-cell--center van-cell--large
              "
              v-on:click="show4"
            >
              <i data-v-53d4c15c class="van-icon van-cell__left-icon">
                <img
                  data-v-53d4c15c
                  src="../assets/images/info_0031.png"
                  class="van-icon__image"
                />
                <!---->
              </i>
              <div data-v-53d4c15c class="van-cell__title">
                <span data-v-53d4c15c>{{langs.a4}}</span>
              </div>
              <!-- <div data-v-53d4c15c class="van-cell__value">
            <span data-v-53d4c15c>Click Settings</span>
          </div> -->
              <i
                data-v-53d4c15c
                class="van-icon van-icon-arrow van-cell__right-icon"
              >
                <!---->
              </i>
            </div>
            <div
              data-v-53d4c15c
              role="button"
              tabindex="0"
              class="
                van-cell van-cell--clickable van-cell--center van-cell--large
              "
              v-on:click="show41"
            >
              <i data-v-53d4c15c class="van-icon van-cell__left-icon">
                <img
                  data-v-53d4c15c
                  src="../assets/images/info_0051.png"
                  class="van-icon__image"
                />
              </i>
              <div data-v-53d4c15c class="van-cell__title">
                <span data-v-53d4c15c>{{langs.a5}}</span>
              </div>
              <!-- <div data-v-53d4c15c class="van-cell__value">
            <span data-v-53d4c15c>Click Settings</span>
          </div> -->
              <i
                data-v-53d4c15c
                class="van-icon van-icon-arrow van-cell__right-icon"
              >
              </i>
            </div>
            <div
              data-v-53d4c15c
              role="button"
              tabindex="0"
              class="
                van-cell van-cell--clickable van-cell--center van-cell--large
              "
              v-on:click="show2('l')"
            >
              <i data-v-53d4c15c class="van-icon van-cell__left-icon">
                <img
                  data-v-53d4c15c
                  src="../assets/images/info_0061.png"
                  class="van-icon__image"
                />
                <!---->
              </i>
              <div data-v-53d4c15c class="van-cell__title">
                <span data-v-53d4c15c>{{langs.a6}}</span>
              </div>
              <!-- <div data-v-53d4c15c class="van-cell__value">
            <span data-v-53d4c15c>Click Settings</span>
          </div> -->
              <i
                data-v-53d4c15c
                class="van-icon van-icon-arrow van-cell__right-icon"
              >
                <!---->
              </i>
            </div>
            <div
              data-v-53d4c15c
              role="button"
              tabindex="0"
              class="
                van-cell van-cell--clickable van-cell--center van-cell--large
              "
              v-on:click="show2('z')"
            >
              <i data-v-53d4c15c class="van-icon van-cell__left-icon">
                <img
                  data-v-53d4c15c
                  src="../assets/images/info_0071.png"
                  class="van-icon__image"
                />
                <!---->
              </i>
              <div data-v-53d4c15c class="van-cell__title">
                <span data-v-53d4c15c>{{langs.a7}}</span>
              </div>
              <!-- <div data-v-53d4c15c class="van-cell__value">
            <span data-v-53d4c15c>Click Settings</span>
          </div> -->
              <i
                data-v-53d4c15c
                class="van-icon van-icon-arrow van-cell__right-icon"
              >
                <!---->
              </i>
            </div>
            <div
              data-v-53d4c15c
              role="button"
              tabindex="0"
              class="
                Cache
                van-cell van-cell--clickable van-cell--center van-cell--large
              "
              v-on:click="close"
            >
              <i
                data-v-53d4c15c
                class="van-icon van-icon-delete van-cell__left-icon"
              >
                <!---->
              </i>
              <div data-v-53d4c15c class="van-cell__title">
                <span data-v-53d4c15c>{{langs.t19}}</span>
              </div>
              <i
                data-v-53d4c15c
                class="van-icon van-icon-arrow van-cell__right-icon"
              >
                <!---->
              </i>
            </div>
          </div>
          <div class="van-overlay" style="z-index: 2017; display: none"></div>
        </div>

        <div class="czmb">
          <!---zf密码面板1-->

          <!-- <van-action-sheet
            v-model="show4_1"
            :title="text9[lang]"
            style=""
          >
            <div class="baise">
              <van-form @submit="onSubmit_paypwd">
    
                <van-field
                  v-model="pay_password"
                  type="password"
                  name="pay_password"
                  :label="text10[lang]"
                  :placeholder="text11[lang]"
                  :rules="[{ required: true, message: '' }]"
                />
                <div style="margin: 16px">
                  <van-button round block type="info" native-type="submit"
                    >{{langs.t19}}</van-button
                  >
                </div>
              </van-form>
            </div>
          </van-action-sheet> -->
          <!---zf密码面板1ed-->
       <!---登录密码面板1-->
          <!-- <van-action-sheet
            v-model="show41_1"
            :title="text9[lang]"
      
          >
            <div class="baise">
              <van-form @submit="onSubmit_paypwd41">

     
                <van-field
                  v-model="pay_password"
                  type="password"
                  name="pay_password"
                  :label="text10[lang]"
                  :placeholder="text11[lang]"
                  :rules="[{ required: true, message: '' }]"
                />
                <div style="margin: 16px">
                  <van-button round block type="info" native-type="submit"
                    >{{langs.t19}}</van-button
                  >
                </div>
              </van-form>
            </div>
          </van-action-sheet> -->
       <!---登录密码面板1ed-->

          <!---银行卡信息1-->
          <van-action-sheet
            v-model="show5_1"
            :title="langs.a8"
          >
            <div class="baise">
              <van-form @submit="onSubmit">
                <van-field
                  v-model="card"
                  name="card"
                  :label="langs.a9"
                  :placeholder="langs.a9"
                  :rules="[{ required: true, message: '' }]"
                  
                />
                <van-field
                  readonly
                  clickable
                  name="bank"
                  :value="value"
                  :label="langs.a10"
                  :placeholder="langs.a10"
                  @click="showPicker = true"
                  
                />
                <van-popup
                  v-model="showPicker"
                  position="bottom"
                  height="600px"
                  style=""
                >
                  <van-picker
                    show-toolbar
                    :confirm-button-text="langs.tp2"
                    :cancel-button-text="langs.tp1"
                    :title="langs.a13"
                    :columns="columns"
                    :default-index="index"
                    @confirm="onConfirm"
                    @cancel="showPicker = false"
                    style="background-color: rgba(0, 0, 0, 0.7)"
                  />
                </van-popup>
             
                <van-field
                  v-model="name"
                  name="name"
                  :label="langs.a11"
                  :placeholder="langs.a11"
                  :rules="[{ required: true, message: '' }]"
                  
                />
                <van-field
                  v-model="cardNo"
                  name="cardNo"
                  :label="langs.i9"
                  :placeholder="langs.i9"
                  :rules="[{ required: true, message: '' }]"
                  
                />
                <van-field
                  v-model="mobile"
                  name="mobile"
                  :label="langs.a12"
                  :placeholder="langs.a12"
                  :rules="[{ required: true, message: '' }]"
                  
                />

                <div style="margin: 16px">
                  <van-button round block type="info" native-type="submit"
                    >{{langs.tp2}}</van-button
                  >
                </div>
              </van-form>
            </div>
          </van-action-sheet>
       <!---银行卡信息1ed-->
          <!---BEP面板1-->

          <van-action-sheet
            v-model="show51_1"
            :title="langs.a14"
      
          >
            <div class="baise">
              <van-form @submit="onSubmit51">
                <van-field
                  v-model="qian"
                  readonly
                  name="usdt_qb"
                  :label="langs.a15"
                  :placeholder="langs.a15"
                  :rules="[{ required: true, message: '' }]"
         
                />

                <van-field
                  v-model="address"
                  name="usdt_qb_address"
                  :label="langs.a16"
                  :placeholder="langs.a16"
                  :rules="[{ required: true, message: '' }]"
           
                />

                <!---面板1ed-->
                <div style="margin: 16px">
                  <van-button round block type="info" native-type="submit"
                    >{{langs.tp2}}</van-button
                  >
                </div>
              </van-form>
            </div>
          </van-action-sheet>

          <!---密码面板2-->

          <!-- <van-action-sheet
            v-model="show2_1"
            :title="langs.a7"
          >
            <div class="baise">
              <van-form @submit="onSubmit_yz">
 
                <van-field
                  v-model="password"
                  type="password"
                  name="pass"
                  :label="ttext2[lang]"
                  :placeholder="ttext3[lang]"
                  :rules="[{ required: true, message: '' }]"

                />
                <div style="margin: 16px">
                  <van-button round block type="info" native-type="submit"
                    >{{text21[lang]}}</van-button
                  >
                </div>
              </van-form>
            </div>
          </van-action-sheet> -->
          <!---密码面板2ed-->
          <!---修改密码面板1-->
          <van-action-sheet
            v-model="show3_1"
            :title="langs.a6"
          >
            <div class="baise">
             
                <!-- <van-field
    v-model="phone"
    readonly
    name="user_name"
    label=""
      left-icon="manager"
      right-icon="question-o"
      @click-right-icon="notup"
    :placeholder="text91[lang]"
    :rules="[{ required: true, message: '' }]"
  />
  <van-field
    v-model="sms"
    center
    clearable
    left-icon="comment-o"
    :placeholder="text41[lang]"
  >
    <template #button >
      <van-button size="small" type="primary"  @click="send">{{msg}}</van-button>
    </template>
  </van-field> -->
  <!--短信2-->
  <van-form @submit="onSubmit_xg_l">
    <!-- :label="ttext5[lang]" -->
    <van-field
    v-model="old_pass"
                name="old_pass"
               label=""
               left-icon="lock"
               right-icon="question-o"
               @click-right-icon="notup"
              :placeholder="langs.a17"
             :rules="[{ required: true, message: '' }]"
              />
                <van-field
                  v-model="l_pwd"
                  type="password"
                  name="l_pwd"
                  label=""
                  left-icon="lock"
                   :placeholder="langs.a18"
                  :rules="[{ required: true, message: '' }]"
                />
   
                <div style="margin: 16px">
                  <van-button round block type="info" native-type="submit"
                    >{{langs.tp2}}</van-button
                  >
                </div>
              </van-form>
            </div>
          </van-action-sheet>
          <!---正式修改支付密码面板1ed-->
          <van-action-sheet
            v-model="show6_1"
            :title="langs.a7"
          >
            <div class="baise">
           
                <!-- <van-field
    v-model="phone"
    name="user_name"
     readonly
    label=""
      left-icon="manager"
      right-icon="question-o"
      @click-right-icon="notup"
    :placeholder="text91[lang]"
    :rules="[{ required: true, message: '' }]"
  />
  <van-field
    v-model="sms"
    center
    clearable
    left-icon="comment-o"
    :placeholder="text41[lang]"
  >
    <template #button >
      <van-button size="small" type="primary"  @click="send">{{msg}}</van-button>
    </template>
  </van-field> -->
  <!--短信2-->
  <van-form @submit="onSubmit_xg_z">
  <!--  :label="ttext8[lang]"-->
          <van-field
          v-model="old_pass1"
                name="old_pass"
               label=""
               left-icon="lock"
               right-icon="question-o"
               @click-right-icon="notup"
               :placeholder="langs.a17"
             :rules="[{ required: true, message: '' }]"
              />
                <van-field
                  v-model="z_pwd"
                  type="password"
                  name="z_pwd"
                
                  label=""
    left-icon="lock"
                 :placeholder="langs.a18"
                  :rules="[{ required: true, message: '' }]"
                />
                <div style="margin: 16px">
                  <van-button round block type="info" native-type="submit"
                  >{{langs.tp2}}</van-button
                  >
                </div>
              </van-form>
            </div>
          </van-action-sheet>

          <van-action-sheet
            v-model="show0_1"
            :title=" langs.a2"
            style="background-color: #151d31"
          >
          <div data-v-53d4c15c class="van-action-sheet__content baise">
          <div data-v-53d4c15c role="radiogroup" class="van-radio-group">
            <div data-v-53d4c15c class="van-grid van-hairline--top">
              <div data-v-53d4c15c class="van-grid-item" style="flex-basis: 20%;" v-on:click="head(1)">
                <div
                  role="button"
                  tabindex="0"
                  class="van-grid-item__content van-grid-item__content--center van-grid-item__content--clickable van-hairline"
                >
                  <i class="van-icon van-grid-item__icon" style="font-size: 45px;">
                    <img src="../assets/images/src/head (1).png" class="van-icon__image" />
                    <!---->
                  </i>
                </div>
              </div>
              <div data-v-53d4c15c class="van-grid-item" style="flex-basis: 20%;" v-on:click="head(2)">
                <div
                  role="button"
                  tabindex="0"
                  class="van-grid-item__content van-grid-item__content--center van-grid-item__content--clickable van-hairline"
                >
                  <i class="van-icon van-grid-item__icon" style="font-size: 45px;">
                    <img src="../assets/images/src/head (2).png" class="van-icon__image" />
                    <!---->
                  </i>
                </div>
              </div>
              <div data-v-53d4c15c class="van-grid-item" style="flex-basis: 20%;" v-on:click="head(3)">
                <div
                  role="button"
                  tabindex="0"
                  class="van-grid-item__content van-grid-item__content--center van-grid-item__content--clickable van-hairline"
                >
                  <i class="van-icon van-grid-item__icon" style="font-size: 45px;">
                    <img src="../assets/images/src/head (3).png" class="van-icon__image" />
                    <!---->
                  </i>
                </div>
              </div>
              <div data-v-53d4c15c class="van-grid-item" style="flex-basis: 20%;" v-on:click="head(4)">
                <div
                  role="button"
                  tabindex="0"
                  class="van-grid-item__content van-grid-item__content--center van-grid-item__content--clickable van-hairline"
                >
                  <i class="van-icon van-grid-item__icon" style="font-size: 45px;">
                    <img src="../assets/images/src/head (4).png" class="van-icon__image" />
                    <!---->
                  </i>
                </div>
              </div>
              <div data-v-53d4c15c class="van-grid-item" style="flex-basis: 20%;" v-on:click="head(5)">
                <div
                  role="button"
                  tabindex="0"
                  class="van-grid-item__content van-grid-item__content--center van-grid-item__content--clickable van-hairline"
                >
                  <i class="van-icon van-grid-item__icon" style="font-size: 45px;">
                    <img src="../assets/images/src/head (5).png" class="van-icon__image" />
                    <!---->
                  </i>
                </div>
              </div>
              <div data-v-53d4c15c class="van-grid-item" style="flex-basis: 20%;" v-on:click="head(6)">
                <div
                  role="button"
                  tabindex="0"
                  class="van-grid-item__content van-grid-item__content--center van-grid-item__content--clickable van-hairline"
                >
                  <i class="van-icon van-grid-item__icon" style="font-size: 45px;">
                    <img src="../assets/images/src/head (6).png" class="van-icon__image" />
                    <!---->
                  </i>
                </div>
              </div>
              <div data-v-53d4c15c class="van-grid-item" style="flex-basis: 20%;" v-on:click="head(7)">
                <div
                  role="button"
                  tabindex="0"
                  class="van-grid-item__content van-grid-item__content--center van-grid-item__content--clickable van-hairline"
                >
                  <i class="van-icon van-grid-item__icon" style="font-size: 45px;">
                    <img src="../assets/images/src/head (7).png" class="van-icon__image" />
                    <!---->
                  </i>
                </div>
              </div>
              <div data-v-53d4c15c class="van-grid-item" style="flex-basis: 20%;" v-on:click="head(8)">
                <div
                  role="button"
                  tabindex="0"
                  class="van-grid-item__content van-grid-item__content--center van-grid-item__content--clickable van-hairline"
                >
                  <i class="van-icon van-grid-item__icon" style="font-size: 45px;">
                    <img src="../assets/images/src/head (8).png" class="van-icon__image" />
                    <!---->
                  </i>
                </div>
              </div>
              <div data-v-53d4c15c class="van-grid-item" style="flex-basis: 20%;" v-on:click="head(9)">
                <div
                  role="button"
                  tabindex="0"
                  class="van-grid-item__content van-grid-item__content--center van-grid-item__content--clickable van-hairline"
                >
                  <i class="van-icon van-grid-item__icon" style="font-size: 45px;">
                    <img src="../assets/images/src/head (9).png" class="van-icon__image" />
                    <!---->
                  </i>
                </div>
              </div>
              <div data-v-53d4c15c class="van-grid-item" style="flex-basis: 20%;" v-on:click="head(10)">
                <div
                  role="button"
                  tabindex="0"
                  class="van-grid-item__content van-grid-item__content--center van-grid-item__content--clickable van-hairline"
                >
                  <i class="van-icon van-grid-item__icon" style="font-size: 45px;">
                    <img src="../assets/images/src/head (10).png" class="van-icon__image" />
                    <!---->
                  </i>
                </div>
              </div>
      <div data-v-53d4c15c class="van-grid-item" style="flex-basis: 20%;" v-on:click="head(11)">
                <div
                  role="button"
                  tabindex="0"
                  class="van-grid-item__content van-grid-item__content--center van-grid-item__content--clickable van-hairline"
                >
                  <i class="van-icon van-grid-item__icon" style="font-size: 45px;">
                    <img src="../assets/images/src/head (11).png" class="van-icon__image" />
                    <!---->
                  </i>
                </div>
              </div>
                    <div data-v-53d4c15c class="van-grid-item" style="flex-basis: 20%;" v-on:click="head(12)">
                <div
                  role="button"
                  tabindex="0"
                  class="van-grid-item__content van-grid-item__content--center van-grid-item__content--clickable van-hairline"
                >
                  <i class="van-icon van-grid-item__icon" style="font-size: 45px;">
                    <img src="../assets/images/src/head (12).png" class="van-icon__image" />
                    <!---->
                  </i>
                </div>
              </div>
                    <div data-v-53d4c15c class="van-grid-item" style="flex-basis: 20%;" v-on:click="head(13)">
                <div
                  role="button"
                  tabindex="0"
                  class="van-grid-item__content van-grid-item__content--center van-grid-item__content--clickable van-hairline"
                >
                  <i class="van-icon van-grid-item__icon" style="font-size: 45px;">
                    <img src="../assets/images/src/head (13).png" class="van-icon__image" />
                    <!---->
                  </i>
                </div>
              </div>

      <div data-v-53d4c15c class="van-grid-item" style="flex-basis: 20%;" v-on:click="head(14)">
                <div
                  role="button"
                  tabindex="0"
                  class="van-grid-item__content van-grid-item__content--center van-grid-item__content--clickable van-hairline"
                >
                  <i class="van-icon van-grid-item__icon" style="font-size: 45px;">
                    <img src="../assets/images/src/head (14).png" class="van-icon__image" />
                    <!---->
                  </i>
                </div>
              </div>
      <div data-v-53d4c15c class="van-grid-item" style="flex-basis: 20%;" v-on:click="head(15)">
                <div
                  role="button"
                  tabindex="0"
                  class="van-grid-item__content van-grid-item__content--center van-grid-item__content--clickable van-hairline"
                >
                  <i class="van-icon van-grid-item__icon" style="font-size: 45px;">
                    <img src="../assets/images/src/head (15).png" class="van-icon__image" />
                    <!---->
                  </i>
                </div>
              </div>
      <div data-v-53d4c15c class="van-grid-item" style="flex-basis: 20%;" v-on:click="head(16)">
                <div
                  role="button"
                  tabindex="0"
                  class="van-grid-item__content van-grid-item__content--center van-grid-item__content--clickable van-hairline"
                >
                  <i class="van-icon van-grid-item__icon" style="font-size: 45px;">
                    <img src="../assets/images/src/head (16).png" class="van-icon__image" />
                    <!---->
                  </i>
                </div>
              </div>
      <div data-v-53d4c15c class="van-grid-item" style="flex-basis: 20%;" v-on:click="head(17)">
                <div
                  role="button"
                  tabindex="0"
                  class="van-grid-item__content van-grid-item__content--center van-grid-item__content--clickable van-hairline"
                >
                  <i class="van-icon van-grid-item__icon" style="font-size: 45px;">
                    <img src="../assets/images/src/head (17).png" class="van-icon__image" />
                    <!---->
                  </i>
                </div>
              </div>
      <div data-v-53d4c15c class="van-grid-item" style="flex-basis: 20%;" v-on:click="head(18)">
                <div
                  role="button"
                  tabindex="0"
                  class="van-grid-item__content van-grid-item__content--center van-grid-item__content--clickable van-hairline"
                >
                  <i class="van-icon van-grid-item__icon" style="font-size: 45px;">
                    <img src="../assets/images/src/head (18).png" class="van-icon__image" />
                    <!---->
                  </i>
                </div>
              </div>
      <div data-v-53d4c15c class="van-grid-item" style="flex-basis: 20%;" v-on:click="head(19)">
                <div
                  role="button"
                  tabindex="0"
                  class="van-grid-item__content van-grid-item__content--center van-grid-item__content--clickable van-hairline"
                >
                  <i class="van-icon van-grid-item__icon" style="font-size: 45px;">
                    <img src="../assets/images/src/head (19).png" class="van-icon__image" />
                    <!---->
                  </i>
                </div>
              </div>
      <div data-v-53d4c15c class="van-grid-item" style="flex-basis: 20%;" v-on:click="head(20)">
                <div
                  role="button"
                  tabindex="0"
                  class="van-grid-item__content van-grid-item__content--center van-grid-item__content--clickable van-hairline"
                >
                  <i class="van-icon van-grid-item__icon" style="font-size: 45px;">
                    <img src="../assets/images/src/head (20).png" class="van-icon__image" />
                    <!---->
                  </i>
                </div>
              </div>


            </div>
          </div>
      </div>
          </van-action-sheet>
        </div>
      </div>
    
      <!--自制的tabbar-->

      <!-- <div class="tabbar">
     <van-row>
  <van-col span="8">        <van-image :src="require('../assets/img/images/home.png')" @click="index111"/>  </van-col>

    <van-col span="8">    <van-image :src="require('../assets/img/images/muchan.png')" @click="muchang" /> </van-col>
  <van-col span="8"><van-image :src="require('../assets/img/images/my.png')"  @click="tomy"/> </van-col>
    </van-row>
  </div> -->

      <div style="height: 80px"></div>

    
  <!-- <van-tabbar v-model="active_bar" @change="click_tab">
  <van-tabbar-item icon="wap-home">Home</van-tabbar-item>
  <van-tabbar-item icon="todo-list">News</van-tabbar-item>
  <van-tabbar-item icon="logistics">Product</van-tabbar-item>
  <van-tabbar-item icon="chart-trending-o">Fund</van-tabbar-item>
  <van-tabbar-item icon="manager">My</van-tabbar-item>
</van-tabbar> -->

    </div>
    <Footer :lang_list="lang_list" :lang="langs" />
  </div>
</template>
<script>
import Vue from "vue";
import { ActionSheet } from "vant";
import { Form } from "vant";
import { Field } from "vant";
import * as local from "@/libs/local";
import { Toast } from "vant";
import * as api from "@/api";
import { Tabbar, TabbarItem } from "vant";
Vue.use(Form);
Vue.use(Field);
Vue.use(ActionSheet);
Vue.use(Tabbar);
Vue.use(TabbarItem);

import Footer from './lyout/footer.vue';


export default {
  components: {
    //HelloWorld
    Footer,
  },
  data() {
    return {
      value: "",
      columns: [
/** "BANCO DE BOGOTA",
"BANCO POPULAR",
"BANCO ITAU",
"BANCOLOMBIA",
"CITIBANK",
"BANCO GNB SUDAMERIS",
"BANCO BBVA COLOMBIA S.A.",
"SCOTIABANK COLPATRIA",
"BANCO DE OCCIDENTE",
"BANCO CAJA SOCIAL",
"BANCO AGRARIO",
"BANCO DAVIVIENDA",
"BANCO AV VILLAS",
"BANCAMIA S.A.",
"BANCO PICHINCHA S.A.",
"BANCOOMEVA S.A.",
"BANCO FALABELLA",
"BANCO SANTANDER COLOMBIA",
"BANCO COOPERATIVO COOPCENTRAL",
"BANCO SERFINANZA",
"DALE",
"RAPPIPAY",
"CFA COOPERATIVA FINANCIERA",
"COTRAFA",
"CONFIAR COOPERATIVA FINANCIERA",
"GIROS Y FINANZAS COMPAÑIA DE FINANCIAMIENTO S.A.",
"COLTEFINANCIERA",*/
"NEQUI",
/*"DAVIPLATA",
"BANCO CREDIFINANCIERA",
"IRIS",
"MOVII S.A.",*/
      ],
      //cih和attjariwafa
      columns1: [
/*"COPDS1001",
"COPDS1002",
"COPDS1006",
"COPDS1007",
"COPDS1009",
"COPDS1012",
"COPDS1013",
"COPDS1019",
"COPDS1023",
"COPDS1032",
"COPDS1040",
"COPDS1051",
"COPDS1052",
"COPDS1059",
"COPDS1060",
"COPDS1061",
"COPDS1062",
"COPDS1065",
"COPDS1066",
"COPDS1069",
"COPDS1097",
"COPDS1151",
"COPDS1283",
"COPDS1289",
"COPDS1292",
"COPDS1303",
"COPDS1370",*/
"COPDS1507",
/*"COPDS1551",
"COPDS1558",
"COPDS1637",
"COPDS1801",*/
      ],
      miao:120,
      msg:"send",
      code: '',
      biao:'',
      showPicker: false,
      show0_1: false,
      show1_1: false,
      show2_1: false,
      qian: "TRC 20",
      active_bar: -1,
      address: "",
      show3_1: false,
      show4_1: false,
      show41_1: false,
      show51_1: false,
      show5_1: false,
      show6_1: false,
      username: "",
      phone:"",
      cardNo:"",
      guo:"",
      sms:'',
      password: "",
      active: 2,
      data: {},
      block: false,
      pay_password: "",
      bank: "",
      card: "",
      name: "",
      IFSC: "",
      l_pwd: "",
      old_pass:"",
      old_pass1:"",
      mobile: "",
      login_pwd: "",
      z_pwd: "",
      index: 0,
      select_index: 0,
      into: "l",
      lang:0,
   
      lang_list: [],
      select_lang: {},
      langs: {},
      cate: [],
    };
  },
  created() {
      this.lang=local.readInfo('setting_lang')==undefined||local.readInfo('setting_lang')==null?0:local.readInfo('setting_lang')
    api.my(
      {
        token: local.readInfo("jwt"),
        lang:local.readInfo('car_lang'),
      },
      (err, data) => {
        if (!err && data.code === 1) {
          this.data = data.data;
          this.name = data.data.user.name;
          this.mobile = data.data.user.phone;
          this.card = data.data.user.bank_card;
          this.address = data.data.user.usdt_qb_address;
          this.value = data.data.user.bank_name;
          this.IFSC = data.data.user.IFSC;
          this.phone=data.data.user.user_name
          this.guo=data.data.user.guo
          this.cardNo=data.data.user.cardNo
          for (let i = 0; i < this.columns1.length; i++) {
            if (this.columns1[i] == data.data.user.bank_code) {
              this.index = i;
              this.select_index = i;
            }
          }
          this.block = true;
          this.lang_list = data.lang_list;
              this.select_lang = data.select_lang;
               this.langs = data.lang;
        } else {
          console.log(data);
          Toast(data.msg);
          this.block = false;
        }
      }
    );
    this.set_biao()
  },
  methods: {
    click_tab: function (index) {
      console.log(index);
      if (index == 1) {
        this.muchang();
      } else if (index == 2) {
        this.tomy();
      } else if (index == 0) {
        this.index111();
      }
    },
    back() {
      this.$router.go(-1); //返回上一层
    },
    head: function (d) {
      api.head(
        {
          token: local.readInfo("jwt"),
          pic: 'https://api.aisinii.com/static/img/head ('+d+').png'
        },
        (err, data) => {
          if (!err && data.code === 1) {
            Toast(data.msg)
            this.$router.go(0);
          } else {
            console.log(data);
            Toast(data.msg)
            //this.block = false
          }
        }
      );
    },
    onConfirm(value, index) {
      this.value = value;
      this.select_index = index;
      this.showPicker = false;
    },
    show: function () {
      this.show0_1 = true;
    },
    show1: function () {
      this.show1_1 = true;
    },
    show2: function (e) {
      this.into = e;
     // this.show2_1 = true;
     if (this.into == "l") {
              this.show3_1 = true;
            } else {
              this.show6_1 = true;
       }
    },
    show3: function () {
      this.show3_1 = true;
    },
    show4: function () {
     // this.show4_1 = true;
     this.show5_1 = true;
    },
    show41: function () {
     // this.show41_1 = true;
     this.show51_1 = true;
    },
    show5: function () {
      this.show5_1 = true;
    },
    onSubmit(values) {
      //  "bank_card"=>$req['bnum'],
      //   "bank_code"=>$req['bank_code'],
      //   "bank_name"=>$req['bname'],
      //   "name"=>$req['name'],
      //   "phone"=>$req['phone'],
      // if(this.select_index==0){
      //   Toast("Please select your bank");
      //   return
      // }
      Toast.loading({
        message: "loading...",
        forbidClick: true,
      });
      console.log("submit", values);
      api.zhifu(
        {
          token: local.readInfo("jwt"),
          bnum: values.card,
          bank_code: this.columns1[this.select_index],
          name: values.name,
          IFSC: 0,
          phone: values.mobile,
          bname: values.bank,
          pwd: this.pay_password,
          cardNo:this.cardNo
        },
        (err, data) => {
          if (!err && data.code === 1) {
            this.show4_1 = false;
            this.show5_1 = false;
            Toast(data.msg)
          } else {
            console.log(data);
            Toast(data.msg)
            //this.block = false
          }
        }
      );
    },

    onSubmit51(values) {
      Toast.loading({
        message: "loading...",
        forbidClick: true,
      });
      console.log("submit", values);
      api.zhifu1(
        {
          token: local.readInfo("jwt"),
          usdt_qb: values.usdt_qb,
          usdt_qb_address: values.usdt_qb_address,
          pwd: this.pay_password,
        },
        (err, data) => {
          if (!err && data.code === 1) {
            this.show4_1 = false;
            this.show5_1 = false;
            Toast(data.msg)
          } else {
            console.log(data);
            Toast(data.msg)
            //this.block = false
          }
        }
      );
    },
    onSubmit_yz(values) {
      Toast.loading({
        message: "loading...",
        forbidClick: true,
      });
      console.log("submit", values);
      api.l_pwd(
        {
          token: local.readInfo("jwt"),
          password: values.pass,
        },
        (err, data) => {
          if (!err && data.code === 1) {
            Toast(data.msg)

            this.show2_1 = false;
            if (this.into == "l") {
              this.show3_1 = true;
            } else {
              this.show6_1 = true;
            }
          } else {
            console.log(data);
            Toast(data.msg)
            //this.block = false
          }
        }
      );
    },
    onSubmit_xg_l(values) {
      Toast.loading({
        message: "loading...",
        forbidClick: true,
      });
      console.log("submit", values);
      api.xl_pwd(
        {
          token: local.readInfo("jwt"),
          password: values.l_pwd,
          biaoshi:this.biao,
          code:this.sms,
          guo:this.guo,
          phone:this.phone,
          old_pass:this.old_pass
        },
        (err, data) => {
          if (!err && data.code === 1) {
            Toast(data.msg)

            this.show3_1 = false;
          } else {
            console.log(data);
            Toast(data.msg)
            //this.block = false
          }
        }
      );
    },
    onSubmit_xg_z(values) {
      Toast.loading({
        message: "loading...",
        forbidClick: true,
      });
      console.log("submit", values);
      api.xz_pwd(
        {
          token: local.readInfo("jwt"),
          zf_password: values.z_pwd,
          biaoshi:this.biao,
          code:this.sms,
          guo:this.guo,
          phone:this.phone,
          old_pass:this.old_pass1
        },
        (err, data) => {
          if (!err && data.code === 1) {
            Toast(data.msg)

            this.show6_1 = false;
          } else {
            console.log(data);
            Toast(data.msg)
            //this.block = false
          }
        }
      );
    },
    onSubmit_paypwd(values) {
      Toast.loading({
        message: "loading...",
        forbidClick: true,
      });
      console.log("submit", values);
      api.zhifu(
        {
          token: local.readInfo("jwt"),
          pwd: values.pay_password,
        },
        (err, data) => {
          if (!err && data.code === "3") {
            this.show4_1 = false;
            this.show5_1 = true;
          } else {
            console.log(data);
            Toast(data.msg)
            //this.block = false
          }
        }
      );
    },
    onSubmit_paypwd41(values) {
      Toast.loading({
        message: "loading...",
        forbidClick: true,
      });
      console.log("submit", values);
      api.zhifu(
        {
          token: local.readInfo("jwt"),
          pwd: values.pay_password,
        },
        (err, data) => {
          if (!err && data.code === "3") {
            this.show41_1 = false;
            this.show51_1 = true;
          } else {
            console.log(data);
            Toast(data.msg)
            //this.block = false
          }
        }
      );
    },
    close: function () {
      Toast(this.langs.t33);
    },
    notup: function () {
   // Toast(this.lang==0?"The phone number cannot be modified. Please contact customer service":
      // this.lang==1?"رقم الهاتف لا يمكن تعديلها ، يرجى الاتصال بخدمة العملاء":"O número de telefone não pode ser modificado. Por favor, contacte o serviço de cliente");
      Toast(this.langs.a19);
    },
    bank1: function () {
      let _this = this;
      _this.$router.push({ name: "bankinfo", query: { redId: 1111 } });
    },
    tomy: function () {
      let _this = this;
      _this.$router.push({ name: "my", query: { redId: 1111 } });
    },

    muchang: function () {
      let _this = this;
      _this.$router.push({ name: "farm", query: { redId: 1111 } });
    },
    index111: function () {
      let _this = this;
      _this.$router.push({ name: "Home", query: { redId: 1111 } });
    },
    send:function(){
      if(this.phone==''){
      //  Toast('Please select country area code first')
        Toast(this.lang==0?'Please select country area code first':
        this.lang==1?"الرجاء اختيار رمز البلد / المنطقة الأولى":"Veuillez d'abord sélectionner l'indicatif régional du pays")
        return
      }
      if(this.guo==''){
        //Toast('Please enter your phone number')
        Toast(this.lang==0?'Please enter your phone number':this.lang==1?"يرجى إدخال رقم الهاتف الخاص بك":"Veuillez entrer votre numéro de téléphone")
       
        return
      }
    
        if (this.logining1) return
                Toast.loading({
        message: "loading...",
        forbidClick: true
      });
      if(this.text110.indexOf(this.msg)<0){
      
        Toast(this.lang==0?'Please send wait for '+this.miao+' seconds':this.lang==1?"من فضلك انتظر بعض الوقت لإرسال":'Veuillez envoyer attendre '+this.miao+' seconde')
        return
      }
       this.logining1=true

        api.sms({
          user_name: this.phone,
          biaoshi:this.biao,
          code:this.sms,
          guo:this.guo,
          yanzheng:0,
          lang:this.lang,
        
        }, (err, data) => {
         
          if (!err && data.code === 1) {
            this.logining1 = false
                 Toast(data.msg)
                 this.jishi()
          } else {
              this.set_biao()
              console.log(data)
              Toast(data.msg)
            this.logining1 = false
         
          }
        
        })
    },
    set_biao:function(){
           
           api.biao({
         biaoshi:this.biao,
         code:this.sms,
         guo:this.value
       }, (err, data) => {
         if (!err && data.code === 1) {
               let biaoshi=data.data.ssxx
               local.saveInfo('biaoshi',biaoshi);
               this.biao=biaoshi
         
         } else {
         Toast("Unable to service")
         }
       
       })

 
   },
   jishi:function(){
      let time= setInterval(() => {
                    if(this.miao>0){
                    this.miao=this.miao-1;
                    this.msg=this.miao
                    }else{
                      this.miao=120;
                      this.msg=this.text110[this.lang]
                      clearInterval(time)
                    }
                  }, 1000);
   },
  },
};
</script>

<style scoped>
/* .baise>>>.van-field__label{color:white}
.baise>>>.van-field__control{color:white} */
.van-action-sheet__header {
  color: white !important;
}

.van-tab__text {
  color: white;
}

.home222 {
  background-color: #f7fbe1;
  max-width: 750px;
  margin: 0 auto;
  height: 100vh;
  overflow: hidden;
}
.tabbar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 139px;
}

.content {
  width: 100%;
  height: auto;
  min-height: 1624px;
  background-color: #f7fbe1;
  background-size: 100%;
}
.Main111 [class*="van-hairline"]::after {
  border: 0px !important;
}
.Main111 {
  width: 700px;
  margin: 0px auto;
  border-radius: 20px;
  padding: 20px;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.8);
}

.PageBox .van-cell {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 16px;
  overflow: hidden;
  color: #323233;
  font-size: 14px;
  line-height: 24px;
  background: rgba(255, 255, 255, 0);
}
.van-cell__left-icon[data-v-53d4c15c] {
  width: 26px;
  height: 26px;
  margin-right: 10px;
  background: #1989fa;
  border-radius: 100%;
}
.van-icon__image[data-v-53d4c15c] {
  width: 100%;
  height: 100%;
}
.van-icon__image {
  width: 1em;
  height: 1em;
  object-fit: contain;
}
img {
  display: inline-block;
  vertical-align: top;
}
.van-cell__title {
  color: #333;
  text-align: left;
}
.van-cell__value {
  color: #666;
}
.shuye {
  width: 100%;
  height: 122px;
  position: fixed;
  bottom: 90px;
  left: 0;
}
.shuye img {
  width: 100%;
}

.czmb >>> .van-action-sheet__header {
  background: #ff8917;
}
.czmb >>> .van-field__body {
  border: 1px solid rgb(222, 221, 221);
}
.czmb >>>.van-field__label{
  width: 7.2em;
}

</style>
