<template>
  <div class="about5">
    <div data-v-bef8e3fc class="van-nav-bar">
      <div class="van-nav-bar__left" v-on:click="back">
        <i class="van-icon van-icon-arrow-left van-nav-bar__arrow">
          <!---->
        </i>
      </div>
      <div class="van-nav-bar__title van-ellipsis">Records of consumption</div>
      <div class="van-nav-bar__right"></div>
    </div>

   <van-tabs
      v-model="active"
      style="border-color: rgb(64, 135, 241); background: rgb(21, 29, 49);"
      @click="onClick"
    >
     <van-tab title="Gold consumption" name=4>
   
      </van-tab>
       <van-tab  title="Milk consumption" name=5>
   
    </van-tab>
       <!--  <van-tab title="Recharge record" name=2>
   
      </van-tab> -->
   
 
    </van-tabs>

     <van-pull-refresh v-model="refreshing" @refresh="onRefresh" v-show="is_has" loading-text="lodding" lodding="lodding" loosing-text="Release can refresh" pulling-text="The drop-down to refresh"> 
          <van-list v-model="loading" :finished="finished" finished-text="No more" @load="onLoad" loading-text="lodding">
            <!-- <van-cell  /> -->

            <div
              data-v-40838d58
              class="FundItem van-cell van-cell--borderless"
              v-for="item in list"
              :key="item.id"
              :title="item.id"
            >
              <div data-v-40838d58 class="icon tag0">I</div>
              <div data-v-40838d58 class="van-cell__title">
                <div data-v-40838d58>
              
                   <span data-v-40838d58 >{{item.msg}}</span>
                  <span data-v-40838d58>{{item.create_time}}</span>
                </div>
                <div data-v-40838d58>
                  <span data-v-40838d58 class="money" v-if="active==4">{{item.price}}</span>
                  <span data-v-40838d58 class="money" v-if="active==5">{{item.nailiang}}</span>
                    <span data-v-40838d58 >{{item.msg}}</span>
           

                </div>
              </div>
            </div>
          </van-list>
        </van-pull-refresh>



     <div data-v-40838d58 role="tabpanel" class="van-tab__pane" style="padding: 0px 12px;" v-show="!is_has">
          <div data-v-40838d58 class="van-pull-refresh">
            <div class="van-pull-refresh__track" style="transition-duration: 0ms;">
              <div class="van-pull-refresh__head"></div>
              <div
                data-v-40838d58
                role="feed"
                class="van-list Empty"
                pulling-text="pulling"
                loosing-text="loosing"
              >
                <div data-v-40838d58></div>
                <div class="van-list__finished-text">No data</div>
                <div class="van-list__placeholder"></div>
              </div>
            </div>
          </div>
        </div>

  </div>
</template>
<script>
import Vue from "vue";
import { Tab, Tabs } from "vant";
import { List } from "vant";
import { Toast } from "vant";
import * as api from '@/api';
import * as local from "@/libs/local";
import { Form } from 'vant';
import { Field } from 'vant';
import { Dialog } from 'vant';

Vue.use(Form);
Vue.use(Field);
Vue.use(List);
Vue.use(Toast);
Vue.use(Tab);
Vue.use(Tabs);
export default {
  data() {
    return {
       is_has:1,
      active: 4,
      page:1,
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      price:'',
      password:'',
      show1_1: false,
      is_jia:false,
      user_info:{
        yue:1
        },
     sub: false,
    };
  },
  methods: {
    onLoad(name=false) {
      if(this.is_jia){return false}
      this.is_jia=true
      // setTimeout(() => {
      //   if (this.refreshing) {
      //     this.list = [];
      //     this.refreshing = false;
      //   }

      //   for (let i = 0; i < 10; i++) {
      //     this.list.push(this.list.length + 1);
      //   }
      //   this.loading = false;

      //   if (this.list.length >= 40) {
      //     this.finished = true;
      //   }
      // }, 1000);
      if(name){
        this.list=[];
        this.page = 1;
         this.finished = false;
         this.is_has=1
      }
            api.get_my_mingxi({
          token: local.readInfo('jwt'),
          type:this.active,
          page:this.page
        }, (err, data) => {
              this.loading= false
               this.refreshing = false;
                this.is_jia=false
          if (!err && data.code === 1) {
               
                   let oldArr = this.list
                   let newArr = data.data.data
                   this.list = oldArr.concat(newArr)
                   this.user_info=data.data.user
                   this.page++
                   if((data.data.last_page-data.data.current_page)<=0){
                        this.finished = true;
                   }
                 
                   if(data.data.total<=0){
                      this.is_has=0
                   }
          
          } else {
              console.log(data)
           if(data&&'msg' in data){
             Toast(data.msg)
           }
          
         
          }
        
        })
    },
       back() {
      this.$router.go(-1); //返回上一层
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
       if(this.logining){return}
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
   this.onLoad(true);
    },
    onClick(name) {
     // Toast(name + "已被禁用" + title);
     if(this.logining){return}
     this.list=[];
     this.active=name
      this.onLoad(true)
    },
    show1:function(){
        this.show1_1=true
      },
    onSubmit(values) {
      //console.log('submit', values);
     if(this.sub){return}
     this.sub=true
       api.tixian({
          token: local.readInfo('jwt'),
          money:values.price,
          pwd:values.password
        }, (err, data) => {
              this.logining = false
          if (!err && data.code === 1) {
               
               Dialog.alert({
                message: data.msg,
                confirmButtonText:'I know the'
             }).then(() => {
                this.onLoad(true)
            });
          
          } else {
              console.log(data)
           if(data&&'msg' in data){
             Toast(data.msg)
           }
          
         
          }
        
        })
    },
  }
};
</script>

<style  scoped>
.about5{min-height:100vh;  background-color: #151d31;}
.van-field__control{color:white!important}
.van-action-sheet__header{color:white!important}

 .van-tab__text{color:white}

.Main {
    position: relative;
}
.Main {
    width: 100%;
    height: 100%;

}

.IndexBox, .ScrollBox {
    position: relative;
    width: 100%;
    height: 100%;
}

.ScrollBox {
    overflow-x: hidden;
    overflow-y: auto;
}.van-nav-bar {
    background-color: transparent!important;
    color:white!important
}
.van-nav-bar {
    position: relative;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    height: 46px;
    line-height: 1.5;
    text-align: center;
    background-color: #fff;
    -webkit-user-select: none;
    user-select: none;
}.van-nav-bar__left {
    left: 0;
    padding: 0 13px;
}
.van-nav-bar__left {
    left: 0;
}
.van-nav-bar__left, .van-nav-bar__right {
    position: absolute;
    top: 0;
    bottom: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    padding: 0 16px;
    font-size: 14px;
    cursor: pointer;
}
.van-nav-bar__title {
    max-width: 60%;
    margin: 0 auto;
    color: #323233;
    font-weight: 500;
    font-size: 16px;
}
.van-nav-bar__title {
    font-size: 22px;
    color: #fff;
}
.van-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}.van-nav-bar__right {
    right: 0;
    padding: 0 12px;
}
.van-nav-bar__right {
    right: 0;
}
.van-nav-bar__left, .van-nav-bar__right {
    position: absolute;
    top: 0;
    bottom: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    padding: 0 16px;
    font-size: 14px;
    cursor: pointer;
}

.van-tabs__content .van-cell {
  background-color: #151d31;
  color: #bbb;
}
.ScrollBox .van-cell {
  line-height: 1;
}
.PageBox .van-cell {
  background-color: #151d31;
  color: #bbb;
}
.FundItem {
  margin: 15px 0;
  padding: 12px;
  line-height: 1;
   background-color: #151d31;
}
.FundItem .icon {
  background-color: #dd6161;
  color: #fff;
  width: 50px;
  height: 50px;
  line-height: 30px;
  text-align: center;
  border-radius: 100%;
  font-size: 15px;
  margin-right: 12px;
  flex: none;
}
.FundItem .van-cell__title {
  overflow: hidden;
}
.FundItem .van-cell__title > div:first-child {
  color: #c0c4cc;
}
.FundItem .van-cell__title > div {
  color: #c0c4cc;
  font-size: 17px;
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.FundItem .van-cell__title > div span:last-child {
  text-align: right;
}
.FundItem .van-cell__title > div span {
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
  text-align: left;
}
.FundItem .van-cell__title > div:last-child {
  color: #999;
  margin-top: 10px;
}
.FundItem .van-cell__title > div {
  color: #c0c4cc;
  font-size: 16px;
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.FundItem .van-cell__title .money {
  font-size: 22px;
  font-weight: 600;
  color: #4087f1!important;
}
.FundItem .van-cell__title > div span {
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
}.van-tab__pane, .van-tab__pane-wrapper {
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    width: 100%;
}.van-list__error-text, .van-list__finished-text, .van-list__loading {
    color: #969799;
    font-size: 14px;
    line-height: 50px;
    text-align: center;
}.Empty .van-list__finished-text:before {
    content: "";
    height: 160px;
    display: block;
    background: url(../assets/images/no_data.7d5de337.png) no-repeat center 0;
    background-size: contain;
    margin-top: 40%;
}
.Wallet .balance[data-v-c11ee39e] {
    background-color: #151d31;
    color: #fff;
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    padding: 16px 10px;
    line-height: 1;
    position: relative;
    z-index: 3;
}
.Wallet .tool[data-v-c11ee39e] {
    padding: 0 16px;
    position: relative;
    z-index: 2;
    width: 100%;
        box-sizing: border-box;
    margin: 0;
}.Wallet .tool>div[data-v-c11ee39e] {
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 2px 5px #888;
    overflow: hidden;
    width: 100%;
    position: relative;
    z-index: 2;
    padding: 0 5px;
    display: flex;
    align-items: center;
    padding: 20px;
        box-sizing: border-box;
    margin: 0;
}.Wallet .tool[data-v-c11ee39e]:before {
    content: "";
    display: block;
    background-color: #151d31;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: -50%;
}.Wallet .tool a[data-v-c11ee39e] {
    flex: 1;
    text-align: center;
    font-size: 16px;
    color: #4087f1;
    line-height: 25px;
    font-weight: 600;
}

</style>