<template>
  <div class="about2">
    <div data-v-2ac32f0d class="van-nav-bar van-nav-bar--fixed">
      <div class="van-nav-bar__left" v-on:click="back">
        <i class="van-icon van-icon-arrow-left van-nav-bar__arrow">
          <!---->
        </i>
      </div>
      <div class="van-nav-bar__title van-ellipsis">{{langs.t1}}</div>
      <div class="van-nav-bar__right"></div>
    </div>







<div class="tabs" >
<van-tabs>
  <div >
 <van-tab  :title="langs.t2" >
   
   <div class="tab_nairong" >
    <!-- <div class="li" @click="showPopup(item1)" v-for="(item1,index1) in item.cow_list" :key="index1"> -->
     <div class="li public_flex"  @click="showac(item.id,1)"  v-for="(item,index) in news" :key="index">
        <div class="title">
          <h3 class="two_hide">{{item.name}}</h3>
    <p>{{item.jia_date?item.jia_date:'Three years ago' }}</p>
        </div>
        <div class="img"><van-image :src="require('../assets/img1/news.png')"  /></div>
      </div>



  
  <van-empty :description="langs.tp15" v-show="news.length<=0"/>
    
   </div>

 </van-tab>
 <van-tab  :title="langs.t3" >
   
   <div class="tab_nairong" >
    <!-- <div class="li" @click="showPopup(item1)" v-for="(item1,index1) in item.cow_list" :key="index1"> -->
      <div class="li public_flex"  @click="showac(item.id,2)"  v-for="(item,index) in help" :key="index">
        <div class="title">
          <h3 class="two_hide">{{item.name}}</h3>
          <p>{{item.jia_date?item.jia_date:'Three years ago' }}</p>
        </div>
        <div class="img"><van-image :src="require('../assets/img1/help.png')"  /></div>
      </div>

  <van-empty :description="langs.tp15" v-show="help.length<=0"/>
    
   </div>

 </van-tab>
 </div>
  </van-tabs>

</div>


    <div data-v-3edcb9bc class="ScrollBox">

  
      

     
      <!---->
    </div>

    <Footer :lang_list="lang_list" :lang="langs" />
<div class="xiufu">
<van-popup v-model="show" closeable
  position="bottom"
  :style="{ height: '90%' }"> 
  <p style="">{{title}}</p>
  <div v-html="content" style="padding:20px;" class="content11011"></div>
  </van-popup>
  </div>
  </div>

</template>
 <script>
 import Vue from 'vue';
 import { Popup } from 'vant';
 import * as local from '@/libs/local'
import { Toast } from 'vant';
import * as api from '@/api';
import { Tab, Tabs } from 'vant';

import Footer from './lyout/footer.vue';
Vue.use(Popup);
Vue.use(Tab);
Vue.use(Tabs);
export default {
  name:"ac_list",
  components: {
    //HelloWorld
    Footer,
  },
  data() {
    return {
       show: false,
       active:0,
      radio: "1",
      list:[],
      content:'',
      title:'',
      news:[],
      help:[],
      lang:0,
      lang_list: [],
      select_lang: {},
      langs: {},
      cate: [],
    };
  },
 created: function() {
    this.lang=local.readInfo('setting_lang')==undefined||local.readInfo('setting_lang')==null?0:local.readInfo('setting_lang')
     api.help({
        token: local.readInfo('jwt'),
        lang:local.readInfo('car_lang'),
        }, (err, data) => {
          if (!err && data.code === 1) {
            this.list=data.data.list
            this.news=data.data.list
            this.help=data.data.help
            this.lang_list = data.lang_list;
              this.select_lang = data.select_lang;
               this.langs = data.lang;
          } else {
              console.log(data)
          Toast(data.msg)
            //this.block = false
         
          }
        
        })
    let _this = this;
    console.log(_this.$store);
    //let _this = this
    //_this.$router.push({'name': 'About', query: {redId: 1111}})
  },
  methods: {

     showPopup(x,d) {
       this.content=d
       this.title=x;
       console.log(this.content)
      this.show = true;
    },
    back() {
      this.$router.go(-1); //返回上一层
    },
    showac(id,type) {
      let _this = this;
      _this.$router.push({ name: "ac_detail", query: { id: id,type:type } });
    }
  }
};
</script>
<style scoped>

.content11011 img{
  width: 100%;
height: auto;
min-height: 1624px;
/* background: url("../assets/img1/my_bg.jpg") no-repeat #79c752; */
background-size: 100%;
}
.content11011{
  box-sizing: border-box;
  /* background: rgba(0, 0, 0,0.5); */
}
.my-swipe .van-swipe-item img {
  width: 100%;
  height: auto;
}


.about2 {
min-height:100vh;  width: 100%;
height: auto;
background:  #ffffff;
background-size: 100%;
max-width: 750px;
margin: 0 auto;
}
.content11011{
  width:100%;
  height: auto;
  min-height: 1624px;

}
.van-cell__title{
  text-align: left;
}
.header{
  position: relative;
  width:100%;
  height:150px
}
.xiufu>>>.van-popup__close-icon{
  position: absolute!important;;
}
.header .touxiang{
  position: absolute;
  width:75px;
  height: 75px;
  border-radius: 100%;
  border: 2px solid white;
  overflow: hidden;
  top:30px;
  left:20px;
  box-sizing: border-box;
}
.header .touxiang img{width: 100%;}
.header .name{
    position: absolute;
  width:190px;
  height: 55px;
  top:39px;
  left:77px;
  line-height: 55px;
  font-size: 16px;
  color:#7b3c1f;
  overflow: hidden;
}
.header .niunai{
  background: url('../assets/img/images/niunai.png') no-repeat;
   background-size: contain;
   width: 220px;
   height: 75px;
   position: absolute;
   padding-left: 70px;
   box-sizing: border-box;
   line-height: 80px;
   color: #ffffff;
   left:270px;
   font-size: 12px;
   text-align: left;
    top:39px;

}
.header .jinbi{
  background: url('../assets/img/images/jinbi.png') no-repeat;
   background-size: contain;
   width: 220px;
   height: 75px;
   position: absolute;
   padding-left: 70px;
   box-sizing: border-box;
   line-height: 80px;
   color: #ffffff;
   left:500px;
   font-size: 12px;
   text-align: left;
    top:39px;

}
/* .Menu[data-v-bef8e3fc] {
    border-top: 10px solid #0e1526;
    margin-bottom: 15px;
} */
.m0 {
    margin: 0!important;
}
.van-grid-item__text {
    font-size: 16px;
    margin-top: 10px;
    color: #000;
}
.van-grid-item__text {
    color: #646566;
    font-size: 12px;
    line-height: 1.5;
    word-wrap: break-word;
}

.Menu[data-v-bef8e3fc] .van-grid-item__text {
    font-size: 15px;
    color: #fff;
    text-align: center;
}
.van-grid-item__icon+.van-grid-item__text {
    margin-top: 8px;
}
.MyEarnings .van-grid-item__content {
    color: #4087f1;
    font-size: 22px;
    text-align: center;
    background-color: #151d31;
}
.Main .van-grid-item__content {
    background-color: #151d31;
}.MyEarnings .van-grid-item__icon-wrapper {
    color: #bbb;
    margin-bottom: 7px;
    font-size: 16px;
    min-height: 32px;
}
.van-grid-item__icon-wrapper {
    position: relative;
}

/* .Main {
    position: relative;
}*/
.Main {
    width: 100%;
    min-height: 1.1vh;

}
.MyEarnings{
    width:700px;
  margin: 0px auto;
  border-radius: 20px;
  padding: 20px;
  box-sizing: border-box;
    margin-top: 30px;
} 
.Menu{
     width:700px;
  margin: 0px auto;
  border-radius: 20px;
  padding: 20px;
  box-sizing: border-box;
    margin-top: 30px;
}
.item1{
  width:700px;
  margin: 0px auto;
  border-radius: 20px;
  padding: 20px;
  box-sizing: border-box;
}
.about1 {
   min-height: 1.1vh;
    /* padding-bottom: 64px; */
}

.van-nav-bar {

    color:#17ffd8!important
}
.van-nav-bar {
    position: relative;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    height: 46px;
    line-height: 1.5;
    text-align: center;
    background-color: #f3f3f3;
    -webkit-user-select: none;
    user-select: none;
}.van-nav-bar__left {
    left: 0;
    padding: 12px;
}
.van-nav-bar__left {
    left: 0;
}
.van-nav-bar__left, .van-nav-bar__right {
    position: absolute;
    top: 0;
    bottom: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    padding: 0 16px;
    font-size: 14px;
    cursor: pointer;
}
.van-nav-bar__title {
    max-width: 60%;
    margin: 0 auto;
    color:#333;
    font-weight: 500;
    font-size: 16px;
}
.UserInfo .info[data-v-bef8e3fc] {
    background-color: #151d31;
    color: #fff;
    position: relative;
    z-index: 3;
}.ScrollBox .van-cell {
    line-height: 1;
}.UserInfo .info .head[data-v-bef8e3fc] {
    margin-right: 10px;
}
img {
    display: inline-block;
    vertical-align: top;
}.UserInfo .info .van-cell__title[data-v-bef8e3fc] {
    font-size: 16px;
    text-align: left;
}
.UserInfo .info .van-cell__label[data-v-bef8e3fc] {
    font-size: 14px;
    color: #fff;
}
.van-cell__label {
    margin-top: 4px;
    color: #969799;
    font-size: 12px;
    line-height: 18px;
}


.zdybtn{
  width:25%!important;
}

.tabs{
  background: #fff;
  width:96%;
  margin: 0 auto;
  min-height: 880px;
  border-radius: 16px;
  overflow: hidden;
}
.tab_nairong{
    width:100%;
    margin: 0 auto;
    padding: 20px;
    min-height: 100vh;
    box-sizing: border-box;
}
.tabs>>>.van-tabs__content{
  min-height: 5.50rem;
}
.tab_nairong .li{
  width:100%;
  height: 180px;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 15px;
  background: white;
  margin-top: 25px;
  box-shadow: 3px 5px 10px 1px #dbdbdb;

}
.tab_nairong .li .title{
  width:450px;
  height: 160px;
  box-sizing: border-box;
}
.tab_nairong .li .img{
  width:140px;
  height: 160px;
}
.tab_nairong .li .title h3{
  font-size: 28px;
  color:#181816;
  line-height: 42px;
  text-align: left;
  padding: 0;
  margin:0
}
.tab_nairong .li .title p{
  color:#a6cabc;
  font-size: 24px;
  line-height: 38px;
  text-align: left;
}

</style>