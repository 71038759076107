<template>
  <div class="about3 content_bg">
    <div data-v-2ac32f0d class="van-nav-bar van-nav-bar--fixed">
      <div class="van-nav-bar__left" v-on:click="back">
        <i class="van-icon van-icon-arrow-left van-nav-bar__arrow">
          <!---->
        </i>
      </div>
      <div class="van-nav-bar__title van-ellipsis">{{langs.t1}} </div>
      <div class="van-nav-bar__right" v-on:click="to_list">{{text2[lang]}}</div>
    </div>
    <div data-v-52129574 data-v-65207978 class="PageBox">
      <div data-v-52129574 class="ScrollBox">
        <div data-v-52129574>
          <div data-v-52129574>
            <div
              data-v-52129574
              role="separator"
              class="van-divider van-divider--hairline van-divider--content-left"
            >
              <span data-v-52129574>
              {{langs.t2}}
              </span>
            </div>
            <!---->
          </div>
        </div>
        <!---->
      </div>
    </div>
    <div class="qianbao">
      
    <van-cell-group>
  <van-cell :title="langs.t6" :value="langs.t8" is-link  label="" v-clipboard:copy="setting.url1"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError" />
          <van-cell :title="setting.url1" style="text-align: left;"></van-cell>
  <van-cell :title="langs.t7" :value="langs.t8" is-link   label=""  v-clipboard:copy="setting.url2"
          v-clipboard:success="onCopy1"
          v-clipboard:error="onError1" />
          <van-cell :title="setting.url2" style="text-align: left;"></van-cell>
</van-cell-group>
    </div>
    <div style="height:20px"></div>
    <van-divider>{{langs.t10}}</van-divider>
    <div style="width:92%;margin:0 auto" class="baise">
      <van-field
    v-model="price2"
 
    name="price2"
    :label="langs.t3"
     type="digit"
    :placeholder="langs.t4"
 @blur="init_price"
    :rules="[{ required: true, message: '' }]" style=""
  />
        <van-field
    :value="result"
    name="price"
    label="USDT"
    type="number"  
    readonly

    :placeholder="langs.t11"
    :rules="[{ required: true, message: '' }]" style=""
  />
 
     <div style="height:20px"></div>
     <!-- <van-row>
  <van-col span="8">{{text11[lang]}}</van-col>
  <van-col span="16"> <van-uploader accept="image/*" v-model="fileList" :max-size="5000 * 1024" @oversize="onOversize" multiple :after-read="afterRead" style="display:block"></van-uploader></van-col>
 
</van-row>
  -->


      <!-- <van-button
        color="#7232dd"
        type="primary"
        size="large"
        style="margin-top:20px"
        @click="wepay_d"
      >Pay 1 electronic wallet</van-button> -->



    <!--Bank card payment wepay -->
         <van-button
        color="#ff8917"
        type="primary"
        size="large"
        style="margin-top:20px"
        @click="usdt_pay"
      >{{langs.t12}}</van-button>


  




    </div>
    <div class="txsm">
      <p style="white-space: pre-wrap;text-align: left;color:#999;padding: 10px;">{{ cz_msg }}</p>
    </div>

   <div ></div>
     <!-- <div style="width:92%;margin:0 auto" >
      <van-button
        color="#1989fa"
        type="primary"
        size="large"
        style="margin-top:20px"
        @click="wepay_bank1"
      >Online top-up</van-button>
     </div> -->

     <van-popup v-model="show1" position="bottom"  closeable round :style="{ height: '50%' }" >
<div style="padding:20px;height:100%" class="baise">
  <div style="margin:26px 10px;">
    <img :src="setting.img1" style="width:60%;display:block;margin:0 auto"/>
  </div>
</div>
</van-popup>
<van-popup v-model="show2" position="bottom"  closeable round :style="{ height: '50%' }" >
<div style="padding:20px;height:100%" class="baise">
  <div style="margin:26px 10px;">
    <img :src="setting.img2" style="width:60%;display:block;margin:0 auto"/>
  </div>
</div>
</van-popup>
<div style="height:100px"></div>
<Footer :lang_list="lang_list" :lang="langs" />
  </div>
</template>
 <script>
import Vue from "vue";
import { Button } from "vant";
import { Toast } from "vant";
import * as api from "@/api";
import * as local from "@/libs/local";
import { Divider } from 'vant';
import { Uploader } from 'vant';
import { Col, Row } from 'vant';
import { Popup } from 'vant';
import Footer from './lyout/footer.vue';
import Decimal from "decimal.js";

Vue.use(Uploader);
Vue.use(Button);
Vue.use(Divider);
Vue.use(Col);
Vue.use(Row);
Vue.use(Popup)
Vue.use(Toast);
export default {
  components: {
    //HelloWorld
    Footer,
  },
  computed: {
    result() {
      if (this.price2) {
        let num1 = new Decimal(this.huilv);
        let num2 = new Decimal(this.price2);
      //  this.price1=num2.div(num1).toFixed(2);
        return num2.div(num1).toFixed(2);
        // if(this.type==1){
        //   return num1.times(num2).toFixed(4);
        // }
        // let shou = new Decimal(this.shou);
        // let bai = new Decimal(100);
        // return num1.times(num2).minus(shou.times(num1.times(num2)).dividedBy(bai)).toFixed(4);
      }
      return 0
    },
 
  },

  setup() {
    const afterRead = (file) => {
      // 此时可以自行将文件上传至服务器
      console.log(file);
    };

    return {
      afterRead,
    };
  },
  name:"chz",
  data() {
    return {
      radio: "1",
      fileList:[],
      price:"",
      price1:"",
      price2:45000,
      lock:false,
      msg:"",
      huilv:0,
      tx_type:1,
      cz_msg:"",
      url:"",
      show:false,
      lock11:true,
      show1:false,
      show2:false,
      setting:{},
      lang:0,
      text1:["Deposit","تعبئة رصيد","Recharger"],
      text2:["Deposit record","سجل الشحن","Enregistrer"],
      text3:[" Please select a payment method to pay ","الرجاء اختيار طريقة الدفع","Veuillez sélectionner un mode de paiement pour payer"],
      text4:["TRC20 receiving address 1","BEP20(BSC) تلقي العنوان 1","BEP20(BSC) adresse de réception 1"],
      text5:["TRC20 receiving address 2","TRC20  تلقي العنوان 2","TRC20 adresse de réception 2"],
      text6:["copy","نسخ","copie"],
      text7:["Submit payment application","تقديم طلب الدفع","Soumettre une demande de paiement"],
      text8:["Please copy the wallet address to complete the payment, keep your proof of payment; Back to the app upload your proof of payment to complete the payment ",
      "يرجى نسخ عنوان المحفظة لإكمال الدفع ، والحفاظ على قسيمة الدفع . العودة إلى التطبيق الخاص بك ، تحميل شهادة الدفع لإكمال الدفع"
      ,"Veuillez copier l'adresse du portefeuille pour effectuer le paiement, conservez votre preuve de paiement ; De retour à l'application, téléchargez votre preuve de paiement pour finaliser le paiement"],
      text9:["Click copy wallet address","انقر فوق نسخ عنوان المحفظة"
      ,"Cliquez sur copier l'adresse du portefeuille"],
      text10:["Upload the proof of payment","تحميل شهادة الدفع"
      ,"Télécharger la preuve de paiement"],
      text11:["Choose picture","اختيار الصور","Choisissez l'image"],
      text12:["Please enter the top-up amount","من فضلك أدخل مبلغ الشحن","Veuillez saisir le montant de la recharge"],
      lang_list: [],
    select_lang: {},
    langs: {},
    cate: [],
    };
  },
      created() {
    console.log(this.$route.query);
       api.cz_data({
        token: local.readInfo('jwt'),
        lang:local.readInfo('car_lang'),
        }, (err, data) => {
          if (!err && data.code === 1) {
       
            this.setting=data.data.setting
     
            this.get_price()   
          } else {
              console.log(data)
          //  if(data&&'msg' in data){
          //    Toast(data.msg)
          //  }
            //this.block = false
         
          }
        
        })
      // this.get_price()
        this.lang=local.readInfo('setting_lang')==undefined||local.readInfo('setting_lang')==null?0:local.readInfo('setting_lang')
     },
  methods: {
    init_price:function(event){
    console.log(event)
    if(this.price2<45000){
      this.price2=45000
    }
  },
   get_price:function(){
   // this.price2=(this.price*this.setting.huilv).toFixed(2)
   api.get_huilv({
        token: local.readInfo('jwt'),
        lang:local.readInfo('car_lang'),
        price:this.price2
      }, (err, data) => {
            this.logining = false
        if (!err && data.code === 1) {
             this.huilv=data.data.huilv
             this.price=data.data.price
             this.lang_list = data.lang_list;
              this.select_lang = data.select_lang;
               this.langs = data.lang;
               this.cz_msg=data.data.cz_msg1
        }
      
      })


   },
  //  formatter:function(x){
  //     // console.log(x)
  //     // if(x>=0){
  //     //   this.get_price()
  //     //   return x
  //     // }
      
    
  //   },
    wepay_bank1:function(){
      this.show=true
    },
  
   
    onOversize :function(file){
      console.log(file);
      Toast(this.lang==0?'The file size is no more than 3 m':this.lang==1?"حجم الملف لا يتجاوز 3 م":"La taille du fichier ne dépasse pas 3 m");
    },
     afterRead: function(file) {
      const toast1= Toast.loading({
  message: 'On the cross...',
  forbidClick: true,
  duration:0
});
       console.log(file)
      if (this.fileList.length > 1) {
        this.$toast.fail(this.lang==0?"Only upload an image":this.lang==1?"فقط تحميل صورة":"Télécharger uniquement une image");
        this.fileList.splice(1);
        return;
      }
    //  let _this=this
     let fd = new FormData() // 实例化表单，提交数据使用
      fd.append('type', 'material')
      fd.append('file', file.file) // 将img追加进去
      fd.append("token",local.readInfo("jwt"))
      api.uploadPic(fd, (err, data) => {
        toast1.clear();
        if(data.code==0){
              Toast(this.lang==0?"Picture is too big":this.lang==1?"الصورة كبيرة جدا":"L'image est trop grande")
        }else{
          console.log(err)
            console.log(data)
            this.url=data.data.url
         
        }
        
      }, {
        onUploadProgress: progressEvent => {
              console.log(progressEvent)
        }
      })
    
    },
 

    
     // 复制成功时的回调函数
    onCopy(e) {
      console.log(e)
      if(this.setting.img1){
        this.show1=true
      }
      Toast("Contents of which have been copied to the clipboard.");
  
    },
    // 复制失败时的回调函数
    onError(e) {
            console.log(e)
            Toast("Sorry, copy failed!");
      if(this.setting.img1){
        this.show1=true
      }
    },
        // 复制成功时的回调函数
        onCopy1(e) {
      console.log(e)
      if(this.setting.img2){
        this.show2=true
      }
 
      Toast("Contents of which have been copied to the clipboard.");
      },
    // 复制失败时的回调函数
     onError1(e) {
            console.log(e)
            if(this.setting.img2){
        this.show2=true
      }
      Toast("Sorry, copy failed!");
    },
    back() {
      this.$router.go(-1); //返回上一层
    },
    to_list() {
      let _this = this;
      _this.$router.push({ name: "cz_history", query: { redId: 1111 } });
    },
   


  
   usdt_pay: function() {
     if(!this.lock11){
       return;
     }
    
       if(this.price2<100){
        Toast('Please input the top-up amount')
        return
      }
      //     if(this.url==""){
      //   Toast(this.lang==0?'Please upload the transfer successful capture':
      //   this.lang==1?"يرجى تحميل نقل بنجاح التقاط":"Veuillez télécharger la capture réussie du transfert")
      //   return
      // }
      this.lock11=false
      Toast.loading({
        message: "loading...",
        forbidClick: true
      });
      api.usdt_pay(
        {
          token: local.readInfo("jwt"),
          pay_type: 122,
          id: 0,
          price:this.result,
          url:this.url,
          price1:this.price2
        },
        (err, data) => {
          this.lock11=true;
          if (!err && data.code === 1) {
              Toast(data.msg);
          } else {
            console.log(data);
            if (data && "msg" in data) {
              Toast(data.msg);
            }
            // this.logining = false
          }
        }
      );
    }
   

  },

};
</script>
<style  scoped>
/* .baise>>>.van-field__label{color:white}
.baise>>>.van-field__control{color:white} */
.baise{background: #fff;}
.qianbao{
  width: 96%;
  margin: 0 auto;
  border-radius: 10px;
  overflow: hidden;
}
  .preview-cover {
    position: absolute;
    bottom: 0;
    box-sizing: border-box;
    width: 100%;
    padding: 4px;
    color: #fff;
    font-size: 12px;
    text-align: center;
    background: rgba(0, 0, 0, 0.3);
  }
/* .qianbao>>>.van-cell__title{color:white;flex: 1.5;} */

.Main {
  position: relative;
}
.Main {
  width: 100%;
  height: 100%;

}
.IndexBox {

  /* padding-bottom: 64px; */
}

.IndexBox,
.ScrollBox {
  position: relative;
  width: 100%;
  height: 100%;
}
.ScrollBox {
  overflow-x: hidden;
  overflow-y: auto;
}
.van-nav-bar {
  background-color: transparent !important;
  color: white !important;
}
.van-nav-bar {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: 46px;
  line-height: 1.5;
  text-align: center;
  background-color: #fff;
  -webkit-user-select: none;
  user-select: none;
}
.van-nav-bar__left {
  left: 0;
  padding: 0 12px;
}
.van-nav-bar__left {
  left: 0;
}
.van-nav-bar__left,
.van-nav-bar__right {
  position: absolute;
  top: 0;
  bottom: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 0 16px;
  font-size: 14px;
  cursor: pointer;
}
.van-nav-bar__title {
  max-width: 60%;
  margin: 0 auto;
  color: #323233;
  font-weight: 500;
  font-size: 16px;
}
.van-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.van-nav-bar__right {
  right: 0;
  padding: 0 12px;
}
.van-nav-bar__right {
  right: 0;
}
.van-nav-bar__left,
.van-nav-bar__right {
  position: absolute;
  top: 0;
  bottom: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 0 16px;
  font-size: 14px;
  cursor: pointer;
}
.about3{min-height:100vh;   width: 100%;
  height: auto;
  background:  #f7fbe1;
  background-size: 100%;}

</style>