<template>
  <div class="content">
  <van-nav-bar
  :title="text2[lang]"
  :left-text="text1[lang]"
  :right-text="text3[lang]"
  left-arrow
  @click-left="onClickLeft"
  @click-right="onClickRight" 
/>
<div class="shuye">
      <img src="../assets/img1/nc_bg.png" />
    </div>
<div class="dajiange"></div>
<!-- <div class="zhifu public_flex">
   <div class="zf1"  @click="show_z"></div>
   <div class="zf2"  @click="cz"></div>
</div> -->


<div class="list">
  <div   v-for="(item,index) in lists" :key="index">
  <div style="height:20px"></div>
  <div class="title">{{ lang==0?item.name:lang==1?item.alb_name:item.bs_name }}</div>

<!-- <van-cell  /> -->

<div class="cz_list"   v-for="(item1,index1) in item.zz" :key="index1">
  <!-- <div class="icon tag0">I</div> -->
  <div  class="order_no public_flex">
    <div class="order">{{lang==0?item1.name:lang==1?item1.alb_name:item1.bs_name}}
    </div>
     <div class="status">
      <div class="kuang">{{text8[lang]}}：{{ item1.level }}{{text16[lang]}}</div>
     </div>
     </div>
     
    <div class="money public_flex">
      <div class="money1">{{text4[lang]}}</div>
       <div class="money3">{{item1.price}} {{text10[lang]}}</div>
    </div>
    <div class="date public_flex">  
     
      <div class="d1"> {{text5[lang]}}： {{item1.zhangcheng}}{{text9[lang]}}</div>
    </div>
    <div class="date public_flex">  
      <div class="d1">{{text6[lang]}}： ${{item1.guoshi}}</div>
      <div class="d2" @click="buy11(item1)">   <van-button round type="success" size="small" color="#ffb53c">&nbsp;&nbsp;&nbsp;&nbsp;{{text7[lang]}}&nbsp;&nbsp;&nbsp;&nbsp;</van-button></div>
    
    </div>
  </div>
  <van-empty :description="lang==0?'No data':lang==1?'لايوجد بيانات':'Pas de données'" v-if="item.zz.length<=0"/>
</div>


  <div style="height:20px"></div>
</div>



<van-action-sheet v-model="show1_1" :title="text11[lang]">
  <div class="buy">
    <div class="name public_flex">
         <div class="n1">{{text12[lang]}}</div>
         <div class="n2">{{ lang==0?buy.name:lang==1?buy.alb_name:buy.bs_name }}</div>
    </div>
    <div class="value public_flex">
         <div class="n1">{{text13[lang]}}</div>
         <div class="n2">   <van-stepper v-model="value" disable-input min="1" max="10000"/></div>
    </div>
    <div class="price public_flex">
         <div class="p1">{{text14[lang]}}</div>
         <div class="p2">{{ buy.price*value }} {{ text10[lang] }}</div>
    </div>
    <div class="btn" @click="tobuy"><van-button round type="success"  size="large" color="linear-gradient(to right, #ff6034, #ee0a24)">{{ text15[lang] }}</van-button></div>
  </div>
</van-action-sheet>



<div style="height:80px"></div>



</div>

</template>

<script>
import Vue from "vue";
import { Tab, Tabs } from "vant";
import { List } from "vant";
import { Toast } from "vant";
import * as api from '@/api';
import * as local from "@/libs/local";
import { Form } from 'vant';
import { Field } from 'vant';
import { Dialog } from 'vant';
import { NavBar } from 'vant';
import { Empty } from 'vant';
import { Loading } from 'vant';
import { Button } from 'vant';
Vue.use(NavBar);
Vue.use(Form);
Vue.use(Field);
Vue.use(List);
Vue.use(Toast);
Vue.use(Tab);
Vue.use(Button);
Vue.use(Tabs);
Vue.use(Empty);
Vue.use(Dialog);
Vue.use(Loading);
export default {
  name: "Home123213",
components: {
  //HelloWorld
},
data() {
  return {
  
    user:{},
    lists:[],
    value:1,
   sub: false,
   show1_1:false,
   buy:{},
   lang:0,
   text1:["back","رد : .","dos"],
   text2:["Buy seeds ","شراء البذور","Acheter des graines"],
   text3:["my seeds ","بذري","mes graines"],
   text4:["price ","الأسعار","prix"],
   text5:["Mature period ","مرحلة النضج","Période de maturité"],
   text6:["Output value ","قيمة الانتاج","Valeur de sortie"],
   text7:["buy ","شراء","acheter"],
   text8:["level ","مستوى","niveau"],
   text16:["class ","فصل","classe"],
   text9:["minutes ","دقيقة .","minutes"],
   text10:["integral"," نقاط","points"],
   text11:["Confirm the purchase quantity","تأكيد كمية الشراء","Confirmer la quantité d'achat"],
   text12:["Name of the seed","اسم البذور","Nom de la graine"],
   text13:["Purchase quantity","كمية الشراء","Quantité d'achat"],
   text14:["The price","الأسعار","Le prix"],
   text15:["Buy Now","شراء على الفور","Acheter"],
  };
},
created() {
 this.get_imdex_data()
   this.lang=local.readInfo('setting_lang')==undefined||local.readInfo('setting_lang')==null?0:local.readInfo('setting_lang')
},
methods: {
  get_imdex_data:function(){
        api.zzlist({
        token: local.readInfo('jwt'),
        }, (err, data) => {
          if (!err && data.code === 1) {
              this.user=data.data.user
              this.lists=data.data.list
          } else {
              console.log(data)
              Toast("Network error")
              this.sub = false
         
          }
        
        })
    }, 

  tobuy:function(){
    if(this.sub){
      return;
    }
    this.sub==true
    Toast.loading({
        message: "loading...",
        forbidClick: true
      });
    api.buyzz({
        token: local.readInfo('jwt'),
        zz_id:this.buy.id,
        nub:this.value
        }, (err, data) => {
          this.sub=false;
          if (!err && data.code === 1) {
            //Toast(data.msg)
              Dialog.alert({
              message: data.yy[this.lang],
              confirmButtonText: this.lang==0?"confirm":this.lang==1?"أكّد":"confirmer",
             theme: 'round-button',
              }).then(() => {
              // on close
              });
         
          }else{
            if(data){
            Toast.fail(data.yy[this.lang])
            }
          }
        })

  },
  onClickLeft() {
    this.$router.go(-1); //返回上一层
  },   
  onClickRight: function () {
    let _this = this;
    _this.$router.push({ name: "seed_record", query: { redId: 1111 } });
  },
  buy11(item){
    this.show1_1=true
    this.value=1
    this.buy=item
  }
 
}
};
</script>

<style scoped>
.home222 {
background-color: #79c752;
max-width: 750px;
margin: 0 auto;
}
.content {
width: 100%;
height: auto;
min-height: 1624px;
background: url("../assets/img1/my_bg.jpg") no-repeat #79c752;
background-size: 100%;
}
.people{
width:700px;
margin: 0 auto;
background: white;
border-radius: 20px;
overflow: hidden;
height: 280px;
}
.shuye {
width: 100%;
height: 122px;
position: fixed;
bottom: 0px;
left: 0;
}
.shuye img {
width: 100%;
}
.dajiange{
height: 185px;
}
.zhifu{
width: 100%;
height: 203px;
background: url("../assets/img1/cz_btn.png") no-repeat;
background-size: 100%;
}
.zhifu .zf1{
width:50%;
height: 203px;
}
.zhifu .zf2{
width:50%;
height: 203px;
}

.list{
width:700px;
margin: 0 auto;
min-height: 400px;
background: #fff;
border-radius: 25px;

}
.list .title{
text-align: center;
color:#666;
font-size: 28px;
line-height: 40px;
}

.list .cz_list{
width:670px;
height: 250px;
background: white;
border-radius: 20px;
margin: 0 auto;
margin-top: 20px;
padding: 5px;
box-sizing: border-box;
box-shadow: 3px 5px 10px 1px #dbdbdb;

}
.list .cz_list .order_no{
  width: 100%;
  height: 35px;
  padding-top: 10px;
}
.list .cz_list .order_no .order{
  width:50%;
  background: url("../assets/img1/dd.png") no-repeat;
 background-size: 24px 30px;
 padding-left: 30px;
 box-sizing: border-box;
 height: 35px;
 text-align: left;
 font-size: 24px;
 font-weight: 700;
 color:#333;
 line-height: 35px;
}
.list .cz_list .order_no .status{
  width: 50%;
  height: 35px;
}
.list .cz_list .order_no .status .kuang{
  width: 230px;
  height: 33px;
  border: 1px solid #e4b025;
  padding:0 15px;
  line-height: 33px;
  font-size: 12px;
  color:#e4b025;
  margin-left: 60px;
  border-radius: 7px;
}
.list .cz_list .date{
  line-height: 35px;
  text-align: left;
  font-size: 24px;
 font-weight: 100;
 color:#999999;
 margin-top: 10px;
}
.list .cz_list .money{
  width: 100%;
  height: 35px;
  padding-top: 10px;
}
.list .cz_list .money .money1{
  width:50%;
  text-align: left;
  font-size: 24px;
 font-weight: 100;
 color:#999999;

}
.list .cz_list .money .money3{
  width:50%;
  text-align: right;
  font-size: 24px;
 font-weight: 100;
 color:#f13b17;
}
.tixian >>>.van-field__label{
width: 7.2em;
}
.tixian >>> .van-field__body {
border: 1px solid rgb(222, 221, 221);
}
.tixian{
width:700px;
margin: 0 auto;
min-height: 400px;
background: white;
border-radius: 25px;
padding: 25px 0;

}


.buy{
  padding: 30px;
}
.buy .name{
  height: 40px;
}
.buy .name .n1{
  color:#333
}
.buy .name .n2{
  color:#999
}
.buy .value{
  height: 80px;
}
.buy .value .n1{
  color:#333
}
.buy .price{
  height: 50px;
}
.buy .price .p1{
  color:#333
}
.buy .price .p2{
  color:red;
  font-size: 28px;
}
.buy .btn{
  width:70%;
  margin: 0 auto;
  margin-top: 20px;
}

</style>