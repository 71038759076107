<template>
  <div class="about88">
    <div data-v-bf483026 data-v-4d9388c2 class="Main">
      <div data-v-bf483026 class="van-nav-bar van-nav-bar--fixed">
        <div class="van-nav-bar__left" v-on:click="back">
          <i class="van-icon van-icon-arrow-left van-nav-bar__arrow" style="color:white">
            <!---->
          </i>
        </div>
        <div class="van-nav-bar__title van-ellipsis" style="color: white;">{{text1[lang]}}</div>
        <div class="van-nav-bar__right"></div>
      </div>
      <div data-v-bf483026 class="PageBox">
        <div data-v-bf483026 class="ScrollBox">
          <div data-v-bf483026 class="van-cell">
            <div data-v-bf483026 class="van-cell__title">
              <span data-v-bf483026>{{text2[lang]}}</span>
            </div>
            <div data-v-bf483026 class="van-cell__value">
              <span data-v-bf483026>www***.app</span>
            </div>
          </div>
          <van-radio-group v-model="radio">
            <div data-v-bf483026 role="button" tabindex="0" class="van-cell van-cell--clickable">
              <div data-v-bf483026 class="van-cell__title">
                Line www***.app
                <i data-v-bf483026>326 ms</i>
              </div>
              <div data-v-bf483026 class="van-cell__value">
                 <van-radio name="1">
            </van-radio>
              </div>
            </div>
               <div data-v-bf483026 role="button" tabindex="1" class="van-cell van-cell--clickable">
              <div data-v-bf483026 class="van-cell__title">
                Line www***.app
                <i data-v-bf483026>521 ms</i>
              </div>
              <div data-v-bf483026 class="van-cell__value">
                 <van-radio name="2">
            </van-radio>
              </div>
            </div>
              <div data-v-bf483026 role="button" tabindex="2" class="van-cell van-cell--clickable">
              <div data-v-bf483026 class="van-cell__title">
                Line www***.app
                <i data-v-bf483026>633 ms</i>
              </div>
              <div data-v-bf483026 class="van-cell__value">
                 <van-radio name="3">
                  </van-radio>
              </div>
            </div>
       
          </van-radio-group>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import Vue from "vue";
import { RadioGroup, Radio } from "vant";
import * as local from "@/libs/local";
Vue.use(Radio);
Vue.use(RadioGroup);
export default {
  data() {
    return {
      radio: "1",
      lang:0,
      text1:["Line selection","اختيار الخط","Sélection de ligne"],
      text2:["Current line","الخط الحالي","Ligne actuelle"],
    };
  },
  created: function() {
      this.lang=local.readInfo('setting_lang')==undefined||local.readInfo('setting_lang')==null?0:local.readInfo('setting_lang')

 },
  methods:{
    back(){
        this.$router.go(-1);//返回上一层
    },
},
};
</script>
<style  scoped>

.Login .href {
  color: #999;
}
.fr {
  float: right !important;
}
.Login {
  text-align: center;
}
.van-nav-bar__title{
  font-size: 24px;
}
.ScrollBox {
  overflow-x: hidden;
  overflow-y: auto;
}
.IndexBox,
.ScrollBox {
  position: relative;
  width: 100%;
  height: 100%;
}
.van-nav-bar {
  background-color: transparent !important;
}
.van-nav-bar--fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
.van-nav-bar {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: 46px;
  line-height: 1.5;
  text-align: center;
  background-color: #fff;
  -webkit-user-select: none;
  user-select: none;
}
.mt15 {
  margin-top: 0.9375rem !important;
}
.ScrollBox .van-form .van-field {
  background-color: rgba(0,0,0,0) !important;
  border-radius: 0.5rem;
}
.Login .van-cell {
  font-size: 12px;
  color: #888;
  background: transparent !important;
}
.Site .van-cell {
  align-items: flex-start;
}
.about88 {
  background-image:url("../assets/img1/login_bg.jpg");
  background-size: cover;
  background-color: #08b6ad;
  height: 100vh;
  max-width: 750px;
  margin: 0 auto;
}
.van-button--round {
  border-radius: 6.25rem;
}
.van-button--danger {
  border-color: transparent;
  background: linear-gradient(90deg, #4b34c3, #3d3ff7);
}
body,
html {
  height: 100%;
}
#app {
  height: 100%;
}

.PageBox .van-cell {
  background-color: rgba(0,0,0,0) !important;
    color: white;
}
.ScrollBox .van-cell {
    line-height: 1;
}
.van-cell__title, .van-cell__value {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    flex: 1;
}
.van-cell__title{text-align: left;}
.van-cell__title i[data-v-bf483026] {
    color: red;
    margin-left: 15px;
}
.van-cell__value[data-v-bf483026] {
    flex: none;
}
.van-cell__value {
    position: relative;
    overflow: hidden;
    color: white;
    text-align: right;
    vertical-align: middle;
    word-wrap: break-word;
}
</style>
