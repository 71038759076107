<template>
  <div class="content">
    <van-nav-bar
  :title="text2[lang]"
  :left-text="text1[lang]"
  :right-text="text3[lang]"
  left-arrow
  @click-left="onClickLeft"
  @click-right="onClickRight"
/>
<div style="height:10px"></div>
 <van-tabs
      v-model="active"
      style="border-color: rgb(64, 135, 241); background: rgb(21, 29, 49);"
      @click="onClick"
    >
      <van-tab :title="text4[lang]" name=1>
      </van-tab>
      <van-tab :title="text5[lang]" name=2></van-tab>
      
    </van-tabs>

    <div class="tuandui_jiaoshui">
 <van-pull-refresh v-model="refreshing" @refresh="onRefresh" v-show="is_has" :loading-text="lang==0?'lodding':lang==1?'لودنج':'soudure'" lodding="lodding" 
 :loosing-text="lang==0?'Release can refresh':lang==1?'الإصدار يمكن أن تحديث':'La version peut rafraîchir'"
    :pulling-text="lang==0?'The drop-down to refresh':lang==1?'الدسم لأسفل لتحديث':'La liste déroulante pour rafraîchir'">
          <van-list v-model="loading" :finished="finished" finished-text="No more" @load="onLoad" :loading-text="lang==0?'lodding':lang==1?'لودنج':'soudure'" lodding="lodding">
            <!-- <van-cell  /> -->

            <div
             class="public_li"
              v-for="(item,index) in list"
              :key="index"
              :title="item"
            >
      

<div class="user public_flex">
  <div class="name  one_hide">{{text6[lang]}}:{{active==1?item.user_name:item.jiao_user_name}}</div>
<div class="date  one_hide">{{text7[lang]}}:{{ item.create_time }}</div>
</div>
<div  class="zz public_flex">
 <div class="li1">
   <div class="head">
    <img :src="active==1?item.user.head:item.jiaouser.head" v-if="active==0||active==1&&item.jiaouser"/>
    <img src="../assets/img/logo.png" width="100%"  v-else/> 
   </div>
 </div>
 <div class="li2">
   <p>{{text8[lang]}}:{{item.zhong.code}}</p>
   <!-- <p>For the rest of:1241242142</p> -->
   <p>{{text9[lang]}}:+{{active==1?item.fenrun:item.zengjia}} points</p>
 </div>
 <div class="li3">
  <!-- <p>earnings:+{{item.fenrun}} points</p> -->
 </div>
</div>  

            </div>
      
          </van-list>
        </van-pull-refresh>
        <van-empty :description="lang==0?'No data':lang==1?'لايوجد بيانات':'Pas de données'"/>
        </div>

        <div class="shuye">
        <img src="../assets/img1/nc_bg.png" />
      </div>
  </div>
</template>
 <script>
 import Vue from "vue";
import { Tab, Tabs } from "vant";
import { List } from "vant";
import { Toast } from "vant";
import * as api from '@/api';
import * as local from "@/libs/local";
import { NavBar } from 'vant';
import { Grid, GridItem } from 'vant';
Vue.use(List);
Vue.use(Toast);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(NavBar);
Vue.use(Grid);
Vue.use(GridItem);
export default {
  data() {
    return {
      radio: "1",
      is_has:1,
      active: 1,
      page:1,
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      user:{},
      my_data:{},
      is_jia:false,
      block:false,
      lang:0,
      text1:["back","رد : .","retour"],
      text2:["Watering record","سجل سقي","Registre d'arrosage"],
      text3:["Watering team","فريق سقي","Équipe d'arrosage"],
      text4:["I water the record","أنا سقي السجلات .","j'arrose le disque"],
      text5:["Riego los registros","سجل الري","j'arrose les bûches"],
      text6:["name","الإسم","nom"],
      text7:["date","تواريخ","date"],
      text8:["Growing number","عدد متزايد","Nombre croissant"],
      text9:["Salario","الرواتب","Salaire"],
      text10:["Salario","الرواتب","Salaire"],
    };
  },
  created: function() {
      this.lang=local.readInfo('setting_lang')==undefined||local.readInfo('setting_lang')==null?0:local.readInfo('setting_lang')
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1); //返回上一层
    },   
    onClickRight: function () {
      let _this = this;
      _this.$router.push({ name: "team", query: { redId: 1111 } });
    },
     onLoad(name=false) {
          if(this.is_jia){return false}
      this.is_jia=true
      if(name){
        this.list=[];
        this.page = 1
         this.is_has=1
             this.finished = false;
      }
            api.jiao_list({
          token: local.readInfo('jwt'),
          type:this.active,
          page:this.page
        }, (err, data) => {
             this.loading= false
               this.refreshing = false;
               this.is_jia=false
          if (!err && data.code === 1) {
                   let oldArr = this.list
                   let newArr = data.data.data.data
                   this.list = oldArr.concat(newArr)
                   this.page++
                   if((data.data.data.last_page-data.data.data.current_page)<=0){
                       this.finished = true;//this.is_has=0
                   }
                  if(data.data.data.total<=0){
                      this.is_has=0
                   }
          } else {
              console.log(data)
           if(data&&'msg' in data){
             Toast(data.msg)
           }
           // this.logining = false
         
          }
        
        })
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
       if(this.logining){return}
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
    this.onLoad(true);
    },
    onClick(name) {
     // Toast(name + "已被禁用" + title);
          if(this.logining){return}
     this.list=[];
     this.active=name
      this.onLoad(true)
    }
  }
};
</script>

<style  scoped>
.home222 {
  background-color: #79c752;
  max-width: 750px;
  margin: 0 auto;
}
.content {
  width: 100%;
  height: auto;
  min-height: 1624px;
  background: url("../assets/img1/my_bg.jpg") no-repeat #79c752;
  background-size: 100%;
}
.people{
  width:700px;
  margin: 0 auto;
  background: white;
  border-radius: 20px;
  overflow: hidden;
  height: 280px;
}
.shuye {
  width: 100%;
  height: 122px;
  position: fixed;
  bottom: 0px;
  left: 0;
}
.shuye img {
  width: 100%;
}
.tuandui_jiaoshui{
  width:750px;
  margin: 0 auto;
  background: white;
  min-height: 380px;
  background: rgba(255, 255, 255, 0.5);
}
.tuandui_jiaoshui .public_li{
  width:680px;
  margin: 0 auto;
  height: 160px;
  border-radius: 19px;
  padding: 5px;
  box-sizing: border-box;
  margin-top: 10px;
  background: #fff
}
.tuandui_jiaoshui .public_li .zz{
  height: 120px;
}
.tuandui_jiaoshui .public_li .li1{
  width:80px;
}
.tuandui_jiaoshui .public_li .li1 .head{
  width:80px;
  height:80px;
  border-radius: 100%;
  overflow: hidden;
}
.tuandui_jiaoshui .public_li .li1 .head img{
  width:100%
}
.tuandui_jiaoshui .public_li .li2{
  width:414px;
  padding-left: 16px;

}
.tuandui_jiaoshui .public_li .li2 p{
  color:#333;
  text-align: left;
  font-size: 15px;
  line-height: 22px;
}
.tuandui_jiaoshui .public_li .li3{
  width:160px;
}
.tuandui_jiaoshui .public_li .user{
  height: 30px;
  border-bottom: 1px solid rgb(219, 216, 216);
}
.tuandui_jiaoshui .public_li .user div{color:#ff8917;width:50%}
.tuandui_jiaoshui .public_li .user .name{
  text-align: left;
}
.tuandui_jiaoshui .public_li .user .date{
  text-align: right;
}
</style>