<template>
 <div class="about2332">
 <div class="Site IndexBox">
    <div class="van-nav-bar van-nav-bar--fixed">
      <div class="van-nav-bar__left" v-on:click="back">
        <i class="van-icon van-icon-arrow-left van-nav-bar__arrow">
          <!---->
        </i>
      </div>
      <div class="van-nav-bar__title van-ellipsis">Piggy bank</div>
      <div class="van-nav-bar__right"></div>
    </div>

    <van-pull-refresh v-model="refreshing" @refresh="onRefresh" v-show="is_has" loading-text="lodding" lodding="lodding" loosing-text="Release can refresh" pulling-text="The drop-down to refresh">
      <van-list v-model="loading" :finished="finished" finished-text="No more" @load="onLoad" loading-text="lodding" lodding="lodding">
        <!-- <van-cell  /> -->
        <div
          
          class="TaskItem van-cell"
          v-for="item in list"
          :key="item.id"
          :title="item.title"
        >
          <div class="icon">
            <h4 >Piggy bank</h4>
            <a href="javascript:;">
              <img
                
                src="../assets/img/jb.png"
              />
            </a>
            <span class="van-tag van-tag--primary">money</span>
          </div>
          <div class="van-ce2ll__title">
            <div >
              <span >The product name:{{item.name}}</span>
              <i >PAY</i>
            </div>
            <div >
              <span >
                Remaining:
                <b >{{item.fengbi}}days</b>
              </span>
              <span >
                
                <em >{{item.price}}</em>
              </span>
            </div>
            <div >
              <span >Returns every day:{{item.shouyi}}/1000</span>
            
              <span >
           
                <van-button color="linear-gradient(to right, #ff6034, #ee0a24)" round type="primary" size="mini" @click="get_task(item)">
subscribe
</van-button>
              </span>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>


    <div data-v-40838d58 role="tabpanel" class="van-tab__pane" style="padding: 0px 12px;" v-show="!is_has">
          <div data-v-40838d58 class="van-pull-refresh">
            <div class="van-pull-refresh__track" style="transition-duration: 0ms;">
              <div class="van-pull-refresh__head"></div>
              <div
                data-v-40838d58
                role="feed"
                class="van-list Empty"
                pulling-text="pulling"
                loosing-text="loosing"
              >
                <div data-v-40838d58></div>
                <div class="van-list__finished-text">No data</div>
                <div class="van-list__placeholder"></div>
              </div>
            </div>
          </div>
        </div>
</div>

<div class="jilu" @click="mycqg"><van-image :src="require('../assets/img/jb.png')" /> </div>

   <!-- <div class="ziyoucunru">
       <van-button size="large" round type="primary" @click="dakai">deposit</van-button>
     </div> -->
<div class="waiceng">
<van-popup v-model="show"
  round
  position="bottom"
  :style="{ height: '70%' }"
>
<div class="cunru">
  <p class="wenzi">Piggy bank to save money</p>
   <p class="jinbi">{{user.jinbi}}</p>
<van-cell-group inset>
  <van-field v-model="jinbi" label="lines"  :formatter="formatter" format-trigger="onBlur" type="digit" placeholder="Please input into line" />
</van-cell-group>
<van-cell-group inset>
  <van-field v-model="days" type="digit"   label="In the time" readonly placeholder="Please enter the deposit period" />
</van-cell-group>
<van-cell-group inset>
  <van-field v-model="shouyi" label="earnings" placeholder="" readonly />
</van-cell-group>
  <div class="ziyoucunru1">
       <van-button size="large" round type="primary" @click="zhuanru">immediately</van-button>
     </div>
</div>
</van-popup>
 </div>

  </div>

  
</template>

<script>
import Vue from "vue";
import { Tab, Tabs } from "vant";
import { List } from "vant";
import { Toast } from "vant";
import * as api from '@/api';
import * as local from "@/libs/local";
import { Popup } from 'vant';
import { Cell, CellGroup } from 'vant';
import { Field } from 'vant';
import { Dialog } from 'vant';
import { Button } from 'vant';
import * as update from '@/utils/update'
Vue.prototype.upd=update
Vue.use(Field);
Vue.use(List);
Vue.use(Toast);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Button);
Vue.use(Popup);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Dialog);
export default {
  data() {
    return {
      is_has:1,
      active: 2,
      page:1,
      list: [],
      xuan:{},
      jinbi:0.00,
      days:0,
      shouyi:"0%",
      shouyi1:0,
      show:false,
      user:{},
      loading: false,
      finished: false,
      refreshing: false,
      is_jia:false
    };
  },
  methods: {
    dakai:function(){
      this.show=true
    },
    formatter:function(x){
      // if(this.jinbi<=0||this.days<=0){
      //   return x
      // }
      // let jinbi=[
      //   {"min":0,"max":2000,"shouyi":1},
      //    {"min":2000,"max":5000,"shouyi":1.5},
      //      {"min":5000,"max":10000,"shouyi":2},
      //        {"min":10000,"max":200000000000000,"shouyi":3},
      //   ];
      //    let day=[
      //   {"min":0,"max":20,"shouyi":0.5},
      //    {"min":20,"max":50,"shouyi":1},
      //      {"min":50,"max":100,"shouyi":1.5},
      //        {"min":100,"max":20000000000000,"shouyi":2},
      //   ];
      //   let sy=0;
      //   for(let i=0;i<jinbi.length;i++){
      //     if(this.jinbi>jinbi[i]['min']&this.jinbi<jinbi[i]['max']){
      //        sy=jinbi[i]['shouyi']
      //     }
      //   }
      //     let sy1=0;
      //   for(let i=0;i<day.length;i++){
      //     if(this.days>day[i]['min']&this.days<day[i]['max']){
      //        sy1=day[i]['shouyi']
      //     }
      //   }
      //   this.shouyi=(sy+sy1)+'%'
      //     this.shouyi1=(sy+sy1)
      console.log(x)
      if(x>=this.xuan.price){
        return x
      }else{
        return this.xuan.price
      }
    
    },
     zhuanru:function(){
       if(this.jinbi>0&this.days>0&this.shouyi1>0){
             var Toast1=Toast.loading({
        message: "loading...",
        forbidClick: true,
        duration:0
      });
        api.get_task({
          token: local.readInfo('jwt'),
          type:"zd",
          id:this.xuan.id,
          jinbi:this.jinbi,
          day:this.days,
          shouyi1:this.shouyi1
        }, (err, data) => {
           Toast1.clear
          if (!err && data.code === 1) {
                 Toast(data.msg)
                 // this.onLoad(true);
          } else {
              console.log(data)
           if(data&&'msg' in data){
             Toast(data.msg)
           }
           // this.logining = false
         
          }
        
        })
       }else{
          Toast('Please try again later')
       }
     
    },
     back(){
        this.$router.go(-1);//返回上一层
    },
    get_task:function(d) {
      //   var Toast1=Toast.loading({
      //   message: "loading...",
      //   forbidClick: true,
      //   duration:0
      // });
        this.show=true
        this.xuan=d
        this.jinbi=d.price
        this.shouyi=d.shouyi+"/1000"
        this.shouyi1=d.shouyi
        this.days=d.fengbi
        // api.get_task({
        //   token: local.readInfo('jwt'),
        //   id:d,
        // }, (err, data) => {
        //    //Toast1.clear
        //   if (!err && data.code === 1) {
        //          Toast(data.msg)
        //          // this.onLoad(true);
        //   } else {
        //       console.log(data)
        //    if(data&&'msg' in data){
        //      Toast(data.msg)
        //    }
        //    // this.logining = false
         
        //   }
        
        // })
    },
     mycqg: function() {
      let _this = this;
      _this.$router.push({ name: "yuebao", query: { redId: 1111 } });
    },
    onLoad(name=false) {
         if(this.is_jia){return false}
      this.is_jia=true
          if(name){
        this.list=[];
        this.page = 1;
         this.finished = false;
         this.is_has=1
      }
         api.get_task_list({
          token: local.readInfo('jwt'),
          type:this.$route.query.id,
          page:this.page
        }, (err, data) => {
            this.loading= false
               this.refreshing = false;
               this.is_jia=false
          if (!err && data.code === 1) {
                   let oldArr = this.list
                   let newArr = data.data.data
                   this.list = oldArr.concat(newArr)
                   this.user=data.data.user
                    this.page++
                     if((data.data.last_page-data.data.current_page)<=0){
                        this.finished = true;
                   }
                   if(data.data.total<=0){
                      this.is_has=0
                   }
          
          } else {
              console.log(data)
           if(data&&'msg' in data){
             Toast(data.msg)
           }
           // this.logining = false
         
          }
        
        })
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      if(this.logining){return}
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad(true);
    },
    onClick(name, title) {
    console.log(name + "已被禁用" + title);
    }
  }
};
</script>

<style  scoped>

.jilu{
  width:94px;
  height: 94px;
  position: fixed;
  right: 40px;
  bottom: 260px;
}
.IndexBox {
  /* padding-bottom: 68px; */
}
 .TaskItem {
  align-items: center !important;
 
}
.cunru{
      height: 100%;
    background: #ff6034;
    padding: 30px;
}
 .cunru>>>.van-field__label{
    color:white;
    padding-left: 10px
  }
   .cunru>>>.van-field{
   /* height: 30px; */
  }
  .cunru>>>.van-cell-group{margin-top: 15px;}
 .cunru>>>.van-field__control{
   color:white;
 
 }
.cunru .wenzi{
  padding-top: 40px;
  color: white;
  margin: 30px 0px;
  font-size: 36px;
}
.cunru .jinbi{
    margin: 30px 0px;
  color: white;
  font-size: 32px;
}
.ziyoucunru1{

  width: 100%;
  padding: 40px;
  box-sizing: border-box;
  height: 90px;

}
.ziyoucunru{
  position: fixed;
  width: 100%;
  padding: 40px;
  box-sizing: border-box;
  height: 90px;
  left:0;
  bottom: 150px;
}
.TaskItem {
 padding: 15px;
}
.TaskItem .icon {
  min-width: 60px;
}
.TaskItem .icon {
  align-items: center;
  line-height: 1;
}
.TaskItem .icon h4 {
  margin-bottom: 6px;
}
.TaskItem .icon a {
  display: block;
  width: 65px;
  height: 65px;
  border-radius: 100%;

  overflow: hidden;
  margin: 0 auto;
}
.TaskItem .icon img {
  display: block;
  width: 100%;
  height: 100%;
}
.TaskItem .van-ce2ll__title {
  margin-left: 18px;
  color: #fff;
  text-align: left;
  line-height: 1;
}
.van-ce2ll__title,
.about2332 .van-cell__value {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1 !important;
}
.TaskItem .van-ce2ll__title > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ccc;
}
/* .TaskItem .van-ce2ll__title {
  margin-left: 18px;
  color: #fff;
  text-align: left;
  line-height: 1;
}
.van-ce2ll__title,
.about2332 .van-cell__value {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1 !important;
}
.TaskItem .van-ce2ll__title > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ccc;
}
.TaskItem .van-ce2ll__title > div + div {
  margin-top: 10px;
}
.TaskItem .van-ce2ll__title > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ccc;
}
.TaskItem .van-ce2ll__title > div span + span {
  margin-left: 12px;
  text-align: right;
}
.TaskItem .van-ce2ll__title > div + div {
  margin-top: 10px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}
*,
:after,
:before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.TaskItem .van-ce2ll__title {
  margin-left: 18px;
  color: #fff;
  text-align: left;
  line-height: 1;
}
.TaskItem .van-ce2ll__title > div + div {
  margin-top: 10px;
}
.TaskItem .van-ce2ll__title > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ccc;
} */
h4 {
  display: block;
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}
.TaskItem .van-ce2ll__title > div em {
  font-weight: 600;
  color: #4087f1;
  font-size: 22px;
}
.TaskItem .van-ce2ll__title > div i {
  color: #f1c70d;
  font-size: 20px;
}
/* .TaskItem .van-ce2ll__title > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ccc;
}
.TaskItem .van-ce2ll__title > div span + span {
  margin-left: 12px;
}
.TaskItem .van-ce2ll__title > div i {
  color: #f1c70d;
  font-size: 20px;
}
.TaskItem .van-ce2ll__title > div em {
  font-weight: 600;
  color: #4087f1;
  font-size: 22px;
} */
.TaskItem {
  align-items: center !important;
}
.Site .van-cell {
  align-items: flex-start;
}
.Site .van-cell {
  background-color: #151d31;
  color: #bbb;
}
.ScrollBox .van-cell {
  line-height: 1;
}
.PageBox .van-cell {
  background-color: #151d31;
  color: #bbb;
}
.TaskItem {
  margin: 3px 0;
  border-radius: 5px;
  box-shadow: 2px 2px rgba(0, 0, 0, 0.2);
}

.IndexBox,
.ScrollBox {
  position: relative;
  width: 100%;
  height: 100%;
}
.van-nav-bar {
  background-color: transparent !important;
}
.van-nav-bar .van-icon {
  font-size: 30px;
  color: #fff;
}
.van-nav-bar--fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
.van-nav-bar__title {
    max-width: 60%;
    margin: 0 auto;
    color: #323233;
    font-weight: 500;
    font-size: 16px;
}
.van-nav-bar__title {
    font-size: 23px;
    color: #fff;
}
.van-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.van-nav-bar {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: 46px;
  line-height: 1.5;
  text-align: center;
  background-color: #fff;
  -webkit-user-select: none;
  user-select: none;
}
.about2332 {
min-height:100vh;  background-color: #151d31;
}
body,
html {
  height: 100%;
}

#app {
  background-color: #0e1526;
}
.van-tab__pane, .van-tab__pane-wrapper {
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    width: 100%;
}.van-list__error-text, .van-list__finished-text, .van-list__loading {
    color: #969799;
    font-size: 14px;
    line-height: 50px;
    text-align: center;
}.Empty .van-list__finished-text:before {
    content: "";
    height: 160px;
    display: block;
    background: url(../assets/images/no_data.7d5de337.png) no-repeat center 0;
    background-size: contain;
    margin-top: 40%;
}
</style>