<template>
  <div class="content">
    <van-nav-bar
      :title="langs.t1"
      :left-text="langs.t2"
      left-arrow
      @click-left="onClickLeft"

    />
    <div style="height: 30px"></div>
    <div class="people">
      <van-grid :column-num="2">
        <van-grid-item
          icon="manager"
          :text="langs.t3 +' '+ my_data.zt  +' '+ langs.t5"
        />
        <van-grid-item
          icon="friends"
          :text="langs.t4 +' '+ my_data.zrs"
        />
      </van-grid>
    </div>

    <div style="height: 30px"></div>
   
    <div class="tuandui_jiaoshui">
      <van-tabs
      v-model="active"
      style="border-color: rgb(64, 135, 241);"
      @click="onClick"
    >
      <van-tab :title="langs.t6" name=1>
      </van-tab>
      <van-tab :title="langs.t7" name=2>
      </van-tab>
      <!-- <van-tab :title="text15[lang]" name=3></van-tab> -->
      
    </van-tabs>
    <van-pull-refresh v-model="refreshing" :loading-text="langs.tp13"
    lodding="lodding" @refresh="onRefresh" v-show="is_has" 
    :loosing-text="langs.tp14"
    :pulling-text="langs.tp16">

<van-list v-model="loading" :finished="finished" :finished-text="langs.tp15" @load="onLoad" 
:loading-text="langs.tp13" lodding="lodding" >
          <!-- <van-cell  /> -->

          <div
            class="public_li"
            v-for="(item, index) in list"
            :key="index"
            :title="item"
          >
            <div class="user public_flex">
              <div class="name one_hide">Account:{{ item.user_name }}</div>
              <div class="date one_hide">{{text8[lang]}}:{{item.create_time}}</div>
            </div>
            <div class="zz public_flex">
              <div class="li1">
                <div class="head">
                  <img :src="item.head" />
                </div>
              </div>
              <div class="li2">
                <p>{{langs.t8}}:{{item.nainiu_count}}</p>
                <!-- <p>{{text10[lang]}}:{{item.sysj}} seconds</p>
                <p>{{text11[lang]}}:{{ item.yjsy }} points</p> -->
              </div>
           
            </div>
          </div>
        </van-list>
      </van-pull-refresh>

      <van-empty :description="langs.tp15" v-if="list.length<=0"/>
    </div>

    <Footer :lang_list="lang_list" :lang="langs" />
  </div>
</template>
 <script>
import Vue from "vue";
import { Tab, Tabs } from "vant";
import { List } from "vant";
import { Toast } from "vant";
import * as api from "@/api";
import * as local from "@/libs/local";
import { NavBar } from "vant";
import { Grid, GridItem } from "vant";

import Footer from './lyout/footer.vue';
Vue.use(List);
Vue.use(Toast);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(NavBar);
Vue.use(Grid);
Vue.use(GridItem);
export default {
  components: {
    //HelloWorld
    Footer,
  },
  data() {
    return {
      radio: "1",
      is_has: 1,
      active: 1,
      page: 1,
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      user: {},
      my_data: {},
      is_jia: false,
      block: false,
      lang:0,
      text1:["back","خلف","retour"],
      text2:["My team","","TMon équipe"],
      text4:["Watering record","","Registro de riego"],
      text5:["Direct push","دفع مباشر","membres directs de l'équipe"],
      text6:["The number of team","المجموع","total des membres de l'équipe"],
      text7:["Invitation code","شفرة الدعوة","Code d'invitation"],
      text8:["date","تاريخ","date"],
      text9:["Number of land","عدد الأرض","Nombre de terrains"],
      text10:["For the rest of","","El resto del tiempo"],
      text11:["Expected earnings","","Ingresos esperados"],
      text12:["Go to the water","","Ve al agua."],
      text13:["First -level","مستوى اول","Premier niveau"],
      text14:["Secondary level","المرحلة الثانوية","Niveau secondaire"],
      text15:["Three -level","ثلاثة مستويات","Trois niveaux"],
      lang_list: [],
      select_lang: {},
      langs: {},
      cate: [],
    };
  },
  created: function () {
    api.team_init(
      {
        token: local.readInfo("jwt"),
        lang:local.readInfo('car_lang'),
      },
      (err, data) => {
        this.logining = false;
        if (!err && data.code === 1) {
          this.block = true;
          this.user = data.data.user;
          this.my_data = data.data.my_data;
          this.lang_list = data.lang_list;
              this.select_lang = data.select_lang;
               this.langs = data.lang;
        } else {
          console.log(data);
          if (data && "msg" in data) {
            Toast(data.msg);
          }
          // this.logining = false
        }
      }
    );
    this.lang=local.readInfo('setting_lang')==undefined||local.readInfo('setting_lang')==null?0:local.readInfo('setting_lang')
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1); //返回上一层
    },
    onLoad(name = false) {
      if (this.is_jia) {
        return false;
      }
      this.is_jia = true;
      if (name) {
        this.list = [];
        this.page = 1;
        this.is_has = 1;
        this.finished = false;
      }
      api.team1(
        {
          token: local.readInfo("jwt"),
          type: this.active,
          page: this.page,
        },
        (err, data) => {
          this.loading = false;
          this.refreshing = false;
          this.is_jia = false;
          if (!err && data.code === 1) {
            let oldArr = this.list;
            let newArr = data.data.data;
            this.list = oldArr.concat(newArr);
            this.page++;
            if (data.data.last_page - data.data.current_page <= 0) {
              this.finished = true; //this.is_has=0
            }
            if (data.data.total <= 0) {
              this.is_has = 0;
            }
          } else {
            console.log(data);
            if (data && "msg" in data) {
              Toast(data.msg);
            }
            // this.logining = false
          }
        }
      );
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      if (this.logining) {
        return;
      }
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad(true);
    },
    onClickRight: function () {
      let _this = this;
      _this.$router.push({ name: "watering_record", query: { redId: 1111 } });
    },
    onClick(name) {
      // Toast(name + "已被禁用" + title);
      if (this.logining) {
        return;
      }
      this.list = [];
      this.active = name;
      this.onLoad(true);
    },
    detail(id1) {
      let _this = this;
      _this.$router.push({ name: "watering", query: { id: id1 } });
    },
  },
};
</script>

<style  scoped>
.home222 {
  background-color: #ffff;
  max-width: 750px;
  margin: 0 auto;
}
.content {
  width: 100%;
  height: auto;
  min-height: 1624px;
  background-color: #ffff;
  background-size: 100%;
}
.people {
  width: 700px;
  margin: 0 auto;
  background: white;
  border-radius: 20px;
  overflow: hidden;
  height: 200px;
}
.shuye {
  width: 100%;
  height: 122px;
  position: fixed;
  bottom: 0px;
  left: 0;
}
.shuye img {
  width: 100%;
}
.tuandui_jiaoshui {
  width: 700px;
  margin: 0 auto;
  background: white;
  border-radius: 20px;
  overflow: hidden;
  min-height: 380px;

}
.tuandui_jiaoshui .public_li {
  width: 680px;
  margin: 0 auto;
  height: 160px;
  border-radius: 19px;
  padding: 5px;
  box-sizing: border-box;
  margin-top: 10px;
  background: white;
  box-shadow: 5px 5px 15px 5px #e3e3e3;
}
.tuandui_jiaoshui .public_li .zz {
  height: 120px;
}
.tuandui_jiaoshui .public_li .li1 {
  width: 80px;
}
.tuandui_jiaoshui .public_li .li1 .head {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  overflow: hidden;
}
.tuandui_jiaoshui .public_li .li1 .head img {
  width: 100%;
}
.tuandui_jiaoshui .public_li .li2 {
  width: 414px;
  padding-left: 16px;
}
.tuandui_jiaoshui .public_li .li2 p {
  color: #333;
  text-align: left;
  font-size: 15px;
  line-height: 22px;
}
.tuandui_jiaoshui .public_li .li3 {
  width: 160px;
}
.tuandui_jiaoshui .public_li .user {
  height: 30px;
  border-bottom: 1px solid rgb(219, 216, 216);
}
.tuandui_jiaoshui .public_li .user div {
  color: #ff8917;
  width: 50%;
}
.tuandui_jiaoshui .public_li .user .name {
  text-align: left;
}
.tuandui_jiaoshui .public_li .user .date {
  text-align: right;
}
</style>