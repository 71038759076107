<template>
<div class="update">



  <van-nav-bar
  :title="langs.t1"
  :left-text="langs.t2"
  left-arrow
  @click-left="onClickLeft"
/>
   <div style="height: 40px;"></div>
  <div class="home IndexBox">

      <div data-v-203ebee2 class="van-cell"  v-for="(item,index) in vip" :key="index" >
        <div data-v-203ebee2 class="van-cell__title">
          <div data-v-203ebee2 class="top">
            <font style="vertical-align: inherit;">
              <font style="vertical-align: inherit;">{{item.name}}</font>
            </font>
            <small data-v-203ebee2>
              <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">&nbsp;&nbsp;{{item.price}} COP</font>
              </font>
            </small>
          </div>
          <p data-v-203ebee2>
            <font style="vertical-align: inherit;">
              <font style="vertical-align: inherit;">{{ langs.t4 }}:{{item.zt_num}}&nbsp;&nbsp;---&nbsp;&nbsp;{{ zt }}/{{item.zt_num}}</font>
            </font>
          </p>
          <p data-v-203ebee2>
            <font style="vertical-align: inherit;">
              <font style="vertical-align: inherit;">{{langs.t5}}:{{item.all_num}}&nbsp;&nbsp;------------------&nbsp;&nbsp;{{ zrs }}/{{item.all_num}}</font>
            </font>
          </p>
          <div style="width: 50%;margin: 0 auto;margin-top: 10px;" :class="item.yes==0&&item.ke==1?'':'jinyong'" @click="update(item.id)">
            <van-button block round type="success" size="small" color="#ffb53c">&nbsp;&nbsp;&nbsp;&nbsp;
              {{item.yes==0&&item.ke==1?'upgrade':item.yes==1?langs.t6:langs.t7}}&nbsp;&nbsp;&nbsp;&nbsp;</van-button>
          </div>
          <!---->
        </div>
     

    <!-- <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
  </div>
    </div>

    <Footer :lang_list="lang_list" :lang="langs" />
    </div>
    
</template>

<script>
// @ is an alias to /src

//import HelloWorld from '@/components/HelloWorld.vue';
import * as local from "@/libs/local";
//import HelloWorld from '@/components/HelloWorld.vue';
import { Toast } from "vant";
import Vue from "vue";
import { Swipe, SwipeItem } from "vant";
import { Tabbar, TabbarItem } from "vant";
import { Grid, GridItem } from "vant";
import * as api from "@/api";
import { Tab, Tabs } from "vant";
import { Picker } from 'vant';
import { Col, Row } from "vant";
import { Empty } from "vant";
import { Popup } from "vant";
import { Cell, CellGroup } from "vant";
import { Step, Steps } from "vant";
import { Rate } from 'vant';
import { Dialog } from 'vant';
import Footer from './lyout/footer.vue';
Vue.use(Step);
Vue.use(Steps);
Vue.use(Rate);
//import * as loginUtil from '@/utils/loginUtil';
Vue.use(Picker);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(local);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Tab);
Vue.use(Dialog);
Vue.use(Tabs);
Vue.use(Col);
Vue.use(Row);
Vue.use(Empty);
Vue.use(Popup);
Vue.use(Cell);
Vue.use(CellGroup);
export default {
  name: "Home",
  components: {
    //HelloWorld
    Footer,
  },
  data() {
    return {
      title: "商品列表",
      active: 0,
      productList: [],
      howExchangeItem: null,
      popHowExchange: false,
      zt:0,
      zrs:0,
      isshow: 0,
      vip:[],
      user:{dengji:{title:"",max_task:0},create_time:""},
      lang_list: [],
      select_lang: {},
      langs: {},
      cate: [],
    };
  },

  created: function() {
    //Toast("提示内容");
    //this.$http.post("token", {})
    // api.getConfig("123", (err, data) => {
    //   console.log(err);
    //   console.log(data);
    // });
    // api.login(
    //   {
    //     username: 111,
    //     password: 22222,
    //     captcha: "1234",
    //     env: "app"
    //   },
    //   (err, data) => {
    //     console.log(err);
    //     console.log(data);
    //   }
    // );
    let _this = this;
    console.log(_this.$store);
    console.log(_this.$store);
    this.index_data()
 
    //let _this = this
    //_this.$router.push({'name': 'About', query: {redId: 1111}})
  },
  methods: {
    index_data(){
      api.get_vip({
          token: local.readInfo('jwt'),
          lang:local.readInfo('car_lang'),
        }, (err, data) => {
          if (!err && data.code === 1) {
               
                 let _this = this
               _this.vip=data.data.dengji
               _this.user=data.data.user
               _this.zt=data.data.zt
               _this.zrs=data.data.zrs
               this.lang_list = data.lang_list;
              this.select_lang = data.select_lang;
               this.langs = data.lang;
          
          } else {
              console.log(data)
          if(data&&'msg' in data){
             Toast(data.msg)
           }
           // this.logining = false
         
          }
        
        })
    },
    howExchange: function(item) {
      // 如何兑换
      this.howExchangeItem = item;
      this.popHowExchange = true;
    },
    update:function(id){
      api.all("/qintai_public/api.user1/up_vip",{
          token: local.readInfo('jwt'),
          id:id
        }, (err, data) => {
          if (!err && data.code === 1) {
            Toast(data.msg)
            this.index_data()
          } else {
              console.log(data)
           if(data&&'msg' in data){
             Toast(data.msg)
           }
           // this.logining = false
         
          }
        
        })


    },
    onClickLeft() {
      this.$router.go(-1); //返回上一层
    },
    to_not_open: function() {
      Toast("A task is not open");
    },
    close: function() {
      this.isshow = 1;
    },
    toline: function() {
      let _this = this;
      _this.$router.push({ name: "line", query: { redId: 1111 } });
    },
    tolang: function() {
      let _this = this;
      _this.$router.push({ name: "language", query: { redId: 1111 } });
    },
    to_update: function(d) {
      let _this = this;
      _this.$router.push({ name: "chongzhi", query: { id: d } });
    }
  }
};
</script>
<style  scoped>
.IndexBox {
    min-height: 1.1vh;
    padding-bottom: 3.25rem;
    width: 95%;
    margin: 0 auto;
}

.van-cell::after{
  border: 0px;
}

.ScrollBox .van-cell {
    line-height: 1;
}.van-cell .van-cell__title[data-v-203ebee2] {
    color: #fff;
    text-align: center;
    line-height: 1;
    overflow: hidden;
}
.van-cell__title, .van-cell__value {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    flex: 1;
}
*, :after, :before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}.van-cell .van-cell__title .top[data-v-203ebee2] {
    font-size: 18px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}.van-cell .van-cell__title .bottom[data-v-203ebee2] {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    text-align: right;
}

element.style {
}
.van-cell[data-v-203ebee2] {
    font-size: 16px;
    background: url(../assets/images/vip_new_bg.png) no-repeat 40px,linear-gradient(60deg,#ccd7e9,#7085a5);
    background-size: contain;
    box-shadow: 0 1px 2px rgba(0,0,0,.2);
    border-radius: 5px;
    padding: 10px 15px;
}
.van-cell[data-v-203ebee2]:nth-of-type(2) {
    background: url(../assets/images/vip_new_bg.png) no-repeat 40px,linear-gradient(60deg,#fd9e02,#d86a25);
    background-size: contain;
}
.van-cell[data-v-203ebee2]:nth-of-type(3) {
    background: url(../assets/images/vip_new_bg.png) no-repeat 40px,linear-gradient(60deg,#3044b3,#273580);
    background-size: contain;
}
.van-cell[data-v-203ebee2]:nth-of-type(4) {
    background: url(../assets/images/vip_new_bg.png) no-repeat 40px,linear-gradient(60deg,#31b489,#3e91b8);
    background-size: contain;
}.van-cell[data-v-203ebee2]:nth-of-type(5) {
    background: url(../assets/images/vip_new_bg.png) no-repeat 40px,linear-gradient(60deg,#47484c,#1f2025);
    background-size: contain;
}
.van-cell[data-v-203ebee2]:nth-of-type(6) {
    background: url(../assets/images/vip_new_bg.png) no-repeat 40px,linear-gradient(60deg,#fd9e02,#d86a25);
    background-size: contain;
}
.van-cell[data-v-203ebee2]:nth-of-type(7) {
    background: url(../assets/images/vip_new_bg.png) no-repeat 40px,linear-gradient(60deg,#3044b3,#273580);
    background-size: contain;
}
.van-cell[data-v-203ebee2]:nth-of-type(8) {
    background: url(../assets/images/vip_new_bg.png) no-repeat 40px,linear-gradient(60deg,#31b489,#3e91b8);
    background-size: contain;
}.van-cell[data-v-203ebee2]:nth-of-type(9) {
    background: url(../assets/images/vip_new_bg.png) no-repeat 40px,linear-gradient(60deg,#47484c,#1f2025);
    background-size: contain;
}
.van-cell+.van-cell[data-v-203ebee2] {
    margin-top: 12px;
}.van-cell .van-cell__title[data-v-203ebee2] {
    color: #fff;
    text-align: center;
    line-height: 1;
    overflow: hidden;
}
.van-cell__title, .van-cell__value {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    flex: 1;
}.van-cell .van-cell__title>p[data-v-203ebee2] {
    margin-top: 10px;
    font-size: 14px;
}

element.style {
    vertical-align: inherit;
}
*, :after, :before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
.van-cell .van-cell__title .top[data-v-203ebee2] {
    font-size: 30px;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
}
.van-cell .van-cell__title[data-v-203ebee2] {
    color: #fff;
    text-align: center;
    line-height: 1;
    overflow: hidden;
}
.update {
  background-color: #ffff;
  padding-bottom: 60px;
  min-height: 1.1vh;
}
body{    background-color: #f7fbe1;}
</style>
