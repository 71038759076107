<template>
  <div class="home222">
    <van-nav-bar
  :title="langs.t1"
  :left-text="langs.t2"
  left-arrow
  @click-left="onClickLeft"

/>
    <div class="content245">
   

      <!--顶部内容-->
      <div class="header public_flex">
        <div class="header1 public_flex">
          <div class="touxiang" @click="tomy">
            <img :src="user.head" style />
          </div>
          <div class="name one_hide">{{ user.user_name }}</div>
        </div>
        <div class="header2 public_flex">
          <div class="usdt one_hide" @click="tochongzhi" style="width:100%">
            {{ user.jinbi }}
          </div>
       
        </div>
      </div>

      <div v-for="index in 8" :key="index" class="anboxclass" :id="'anbox'+index" v-show="shou_lock">
                <img class="gildclass"  :id="'gild'+index" src="../assets/img1/jb.png" alt="">
            </div>
     
      <div style="height: 20px"></div>
      <!-- <div class="daojishi" style="color: #852121;" v-if="cow&&cow.cow.is_xn==1">Adult date:{{cow.cow.zc_time1}}</div>
        <div class="daojishi" style="color: #852121;" v-else>The remaining {{cow.cow.cndjs}} seconds</div> -->
      <!-- <img src="../assets/img1/xn11.png" class="xiaoniao up_move" /> -->
      <!-- <img src="../assets/img1/mf11.png" class="mifeng up_move1" /> -->

      <div class="detail_li">
        <!-- <div class="mupai">
            <van-image :src="require('../assets/img/images/mupai.png')" /> 
          </div>-->
        <div >
          <div >
            <div class="max_shu">
                  
                      <img :src="item.logo1" />
             </div>   
        
            <!-- <div class="jindou up_move3" @click="shou">
              <img src="../assets/img1/jb.png" />
            </div> -->
      
          </div>
     
         
        </div>
      

        <!-- <p class="code one_hide">{{ item.code }}</p> -->
      </div>
      <div class="ctr_btn public_flex">
        <div class="ctr_btn1"  @click="sheng">
          <van-button round type="success" color="#ff9966"
            >{{langs.t3}}</van-button
          >
        </div>
        <div class="ctr_btn2"  @click="shou">
          <van-button round type="success" color="#3366cc"
            >{{langs.t4}}</van-button
          >
        </div>
        <!-- <van-button round type="success" color="#ffb53c">Fast charging</van-button> -->
      </div>
      <!-- <img src="../assets/img/images/st.png" class="shuitong" @click="to_zhihui('kssn')"/> -->
   
 
    </div>
    <div style="height:20px"></div>
  
    <div class="new_shu">
        <div class="shuli public_flex">
              <div class="icon"> <img src="../assets/img/wd (3).png"  /></div>
              <div class="title">{{langs.t5}}</div>
              <div class="data">{{item.code}}</div>
             
        </div>
         
        <div class="shuli public_flex">
              <div class="icon"> <img src="../assets/img/wd (6).png"  /></div>
              <div class="title">{{langs.t6}}</div>
              <div class="data">{{item.jr_sy}} </div>
          
        </div>

        <div class="shuli public_flex">
              <div class="icon"> <img src="../assets/img/wd (7).png"  /></div>
              <div class="title">{{langs.t7}}</div>
              <div class="data">{{item.shengyu}} {{langs.t11}}s</div>
    
        </div>

        <!-- <div class="shuli public_flex">
              <div class="icon"> <img src="../assets/img/wd (8).png"  /></div>
              <div class="title">The total number of vehicles</div>
              <div class="data">{{item.z_count}}</div>
     
        </div> -->




      </div>
 <div style="height:20px"></div>

    <!--自制的tabbar-->
    <div style="height: 80px"></div>

    <!-- <van-tabbar v-model="active_bar" @change="click_tab">
  <van-tabbar-item icon="wap-home">{{ text31[lang] }}</van-tabbar-item>
  <van-tabbar-item icon="todo-list">{{ text31[lang] }}</van-tabbar-item>
  <van-tabbar-item icon="logistics">{{ text31[lang] }}</van-tabbar-item>
  <van-tabbar-item icon="chart-trending-o">{{ text32[lang] }}</van-tabbar-item>
  <van-tabbar-item icon="manager">{{ text33[lang] }}</van-tabbar-item>
</van-tabbar> -->




    <!--土地-->
    <van-popup
      v-model="show_td"
      round
      closeable
      position="bottom"
      :style="{ height: '60%' }"
    >
      <div style="height: 10px"></div>
      <div class="jiaoshui_list">
        <div class="list1">
          <div class="title">{{ langs.t8 }}</div>
          <div
            class="cz_list"
            v-for="item in sj_list"
            :key="item.id"
            :title="item.id"
          >
            <div class="order_no public_flex">
              <div class="order">{{item.name}}</div>
              <div class="status">
                <div class="kuang">{{ langs.t9 }}：{{ item.level }}</div>
              </div>
            </div>

            <div class="money public_flex">
              <div class="money1">{{ langs.t10 }}:</div>
              <div class="money3">COP{{ item.sj_price }}</div>
            </div>
            <div class="money public_flex">
              <div class="money1">{{ langs.t12 }}:</div>
              <div class="money3">
              {{item.days}} days
              </div>
            </div>
           
            <div class="date public_flex" style="margin-top: 5px;">
          
              <div class="d2" @click="shengji(item.id)" style="width: 100%;">
                <van-button round type="success" size="small" block  color="#07c160"
                  >&nbsp;&nbsp;&nbsp;&nbsp;
                  {{ langs.t13 }}&nbsp;&nbsp;&nbsp;&nbsp;</van-button
                >
              </div>
            </div>
          </div>

          <van-empty
            :description="   text47[lang]"
            v-if="sj_list.length <= 0"
          />
        </div>
        <div style="height: 20px"></div>
      </div>
    </van-popup>

    <Footer :lang_list="lang_list" :lang="langs" />
  </div>
</template>

<script>
// @ is an alias to /src

import * as local from "@/libs/local";
//import HelloWorld from '@/components/HelloWorld.vue';
import { Toast } from "vant";
import Vue from "vue";
import { Swipe, SwipeItem } from "vant";
import { Tabbar, TabbarItem } from "vant";
import { Grid, GridItem } from "vant";
import * as api from "@/api";
import { ref } from "vue";
import { Tab, Tabs } from "vant";
import { Col, Row } from "vant";
import { Empty } from "vant";
import { Popup } from "vant";
import { Cell, CellGroup } from "vant";
import { Dialog } from "vant";
import Footer from './lyout/footer.vue';
//import * as loginUtil from '@/utils/loginUtil';

Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(local);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Col);
Vue.use(Row);
Vue.use(Empty);
Vue.use(Popup);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Dialog);
export default {
  name: "Home",
  components: {
    //HelloWorld
    Footer,
  },
  data() {
    return {
      title: "",
      active: -1,
      active_bar: -1,
      productList: [],
      actions: [{ text: "Planting seeds" }],
      off: [30, 30],
      howExchangeItem: null,
      popHowExchange: false,
      isshow: 1,
      user: {
        niunai: 0.0,
        jinbi: 0.0,
      },
      showPopover: false,
      show: false,
      show_td: false,
      kpzlb: [],
      ksjlb: [],
      fmjl: [],
      cnjl: [],
      pzjl: [],
      js_list: [],
      sy_list: [],
      zz_list: [],
      sj_list: [],
      zhongzi_list: [],
      setting: {},
      manghe_setting: {
        count: 0,
      },
      category: [],
      cow: {
        cow: { cndjs: 0 },
      },
      item: {},
      lock: false,
      shou_lock:false,
      lang:0,
      text1:["Orchard Name","اسم بستان","Nom du verger"],
      text2:["Orchard No","بستان عدد","Numéro du verger"],
      text3:["Orchard Grade","بستان الصف","Niveau du verger"],
      text4:["Maximum seed level","أعلى مستوى البذور","Grade de graine le plus élevé"],
      text5:["Watering team","فريق سقي","Arrosage"],
      text6:["Planting record","سجل الزراعة","dossiers de plantation"],
      text7:["Watering record","سجل سقي","Registre d'arrosage"],
      text8:["Land to upgrade","ترقية الأرض","renouveler la terre"],
      text9:["Harvest records","سجل الحصاد","Registres de récolte"],
      text10:["Planting seeds","زرع البذور","Semer des graines"],
      text11:["Harvest the fruit","حصاد الفاكهة","Récolter les fruits"],
      text12:["Watering record","سجل سقي","Registre d'arrosage"],
      text13:["status:Watering success","الحالة : سقي بنجاح","statut : Arrosage réussi"],
      text14:["Watering time","وقت الري","Temps d'arrosage"],
      text15:["Planting record","سجل الزراعة","dossiers de plantation"],
      text16:["status","ولاية","État"],
      text17:["Planting time","وقت الزراعة","Temps de plantation"],
      text18:["Growing number","رقم الزراعة","code de plantation"],
      text19:["earnings","الرواتب","gains"],
      text20:["Choose seeds can be planted","اختيار البذور التي يمكن زراعتها","Choisissez des graines qui peuvent être plantées"],
      text21:["Number remaining","الكمية المتبقية","Nombre restant"],
      text22:["seeding"," بذر ","ensemencement"],
      text23:["Can be upgraded","يمكن ترقية","Peut être amélioré"],
      text24:["upgrade","ترقية","améliorer"],
      text25:["Earnings record","سجل الأرباح","Record de gains"],
      text26:["earnings","دخل","gains"],
      text27:["earnings","دخل","gains"],
      text28:["Output value ","قيمة الانتاج","Valeur de sortie"],
      text31:["Home","منزل .","Maison"],
      text32:["The farm","مزرعه","La ferme"],
      text33:["My"," فرد . ","Le mien"],
      text40:["star "," نجم ","étoile"],
      text41:["integral","أساسي","intégral"],
      text42:["level ","الكمية","niveau"],
      text43:["price ","الأسعار","prix"],
      text44:["Give the seed","إعطاء البذور","Donnez la graine"],
      text45:["Award points","مكافأة نقاط","Attribuer des points"],
      text46:["Highest seed grade "," أعلى مستوى البذور ","Qualité de semence la plus élevée"],
      text47:["No data "," بيانات مؤقتة ","Pas de données"],
      text48:["Purchase time"," وقت الشراء ","Temps d'achat"],
      text49:["For the time ","مؤقت","Pour le moment"],
      text50:["prompt ","حث","rapide"],
      text51:["No data ","",""],
      text52:["No data ","",""],
      text53:["second "," ثانية . ","second"],
      text91:["Seed bank","بنك البذور","Banque de graines"],

      lang_list: [],
      select_lang: {},
      langs: {},
      cate: [],
     
    };
  },
  setup() {
    const active = ref(0);
    return { active };
  },
  created: function () {
    window.scrollTo(0, 0);
    this.get_imdex_data();
    setTimeout(() => {
      this.showPopover = true;
    }, 2000);
      this.lang=local.readInfo('setting_lang')==undefined||local.readInfo('setting_lang')==null?0:local.readInfo('setting_lang')
      this.lang==0?this.actions[0].text="Planting seeds":this.lang==1?this.actions[0].text="زرع البذور":this.actions[0].text="Semis"
     // Vue.data.active_bar=-1
  },
  methods: {
      onSelect :function(action){
      console.log(action)
      Toast(this.lang==0?'Please enter the land to complete seeding':
      this.lang==1?"يرجى الدخول إلى الأرض لإكمال البذر":"Veuillez saisir le terrain pour terminer l'ensemencement")
    },
    onClickLeft() {
    this.$router.go(-1); //返回上一层
  },  
    click_tab: function (index) {
      console.log(index);
      if (index == 1) {
        this.muchang();
      } else if (index == 2) {
        this.tomy();
      } else if (index == 0) {
        this.index();
      }
    },
    //锚点跳转
    goAnchor(selector) {
      /*参数selector是id选择器（#anchor14）*/
      document.querySelector(selector).scrollIntoView({
        behavior: "smooth",
      });
    },
    shengji: function (s_id) {
      if (this.lock) {
        return;
      }
      this.lock = true;
      Dialog.confirm({
        title: this.text50[this.lang],
        message:this.langs.t14,
        confirmButtonText: this.langs.tp2,
       cancelButtonText: this.langs.tp1,
      })
        .then(() => {
          api.zhihui11(
            {
              token: local.readInfo("jwt"),
              td_id: this.item.id,
              s_id: s_id,
              type: "shengji",
            },
            (err, data) => {
              if (!err && data.code === 1) {
                Toast(data.msg)
                this.get_imdex_data();
              } else {
                console.log(data);
                Toast(data.msg)
                //this.block = false
              }
              this.lock = false;
            }
          );
        })
        .catch(() => {
          console.log("quxiao");
          this.lock = false;
        });
    },
    bozhong: function (id) {
      if (this.lock) {
        return;
      }
      this.lock = true;
      Dialog.confirm({
        title: this.text50[this.lang],
        message:this.lang==0? "Confirm to grow the seeds?":this.lang==1?"تأكيد زراعة البذور ؟":"Confirmer pour faire pousser les graines ?",
        confirmButtonText: this.lang==0?"confirm":this.lang==1?"أكّد":"confirmer",
       cancelButtonText: this.lang==0?"cancel":this.lang==1?"ألغى":"Annuler"
      })
        .then(() => {
          api.zhihui11(
            {
              token: local.readInfo("jwt"),
              td_id: this.item.id,
              zz_gm_id: id,
              type: "bo",
            },
            (err, data) => {
              if (!err && data.code === 1) {
                Toast(data.msg)
                this.get_imdex_data();
              } else {
                console.log(data);
                Toast(data.yy[this.lang])
                //this.block = false
              }
              this.lock = false;
            }
          );
        })
        .catch(() => {
          console.log("quxiao");
          this.lock = false;
        });
    },
    shou: function () {
      if (this.lock) {
        return;
      }
      this.lock = true;
  
      Dialog.confirm({
        title: this.text50[this.lang],
        message:this.langs.t15,
        confirmButtonText: this.langs.tp2,
       cancelButtonText: this.langs.tp1,
      })
        .then(() => {
          api.zhihui11(
            {
              token: local.readInfo("jwt"),
              td_id: this.item.id,
              type: "shou",
            },
            (err, data) => {
              if (!err && data.code === 1) {
                this.shou_lock= true;
                setTimeout(()=>{ this.shou_lock= false;},3000)
                Toast(data.msg)
                this.get_imdex_data();
              } else {
                console.log(data);
                Toast(data.msg)
                //this.block = false
              }
              this.lock = false;
            }
          );
        })
        .catch(() => {
          console.log("quxiao");
          this.lock = false;
        });
    },
    zhihui() {
      this.show1 = true;
    },
    howExchange: function (item) {
      // 如何兑换
      this.howExchangeItem = item;
      this.popHowExchange = true;
    },
    get_imdex_data: function () {
      api.cowdetail(
        {
          token: local.readInfo("jwt"),
          id: this.$route.query.id,
          lang:local.readInfo('car_lang'),
        },
        (err, data) => {
          if (!err && data.code === 1) {
            this.user = data.data.user;
            this.item = data.data.cow;
            this.sj_list = data.data.td;
            this.lang_list = data.lang_list;
              this.select_lang = data.select_lang;
               this.langs = data.lang;
          } else {
            console.log(data);
            Toast(data.msg);
            //this.block = false
          }
        }
      );
    },
    buy: function (cow) {
      Dialog.confirm({
        title: "Buy a cow",
        message:
          "Buy the cows need " + cow.price + " grams of milk, confirm to buy",
        confirmButtonText: "confirm",
        cancelButtonText: "cancel",
      })
        .then(() => {
          //确认前往盲盒
          api.buy(
            {
              token: local.readInfo("jwt"),
              id: cow.id,
            },
            (err, data) => {
              if (!err && data.code === 1) {
                Toast(data.msg);
              } else {
                console.log(data);
                Toast(data.msg);
                //this.block = false
              }
            }
          );
        })
        .catch(() => {
          console.log("quxiao");
        });
    },
    to_not_open: function () {
      Toast("A task is not open");
    },
    close: function () {
      this.isshow = 1;
    },
    index: function () {
      let _this = this;
      _this.$router.push({ name: "Home", query: { redId: 1111 } });
    },
    tolang: function () {
      let _this = this;
      _this.$router.push({ name: "language", query: { redId: 1111 } });
    },

    gengduo: function () {
      let _this = this;
      _this.$router.push({ name: "buy", query: { redId: 1111 } });
    },
    muchang: function () {
      let _this = this;
      _this.$router.push({ name: "muchang", query: { redId: 1111 } });
    },
    tomy: function () {
      let _this = this;
      _this.$router.push({ name: "my", query: { redId: 1111 } });
    },
    jiaoyi: function () {
      let _this = this;
      _this.$router.push({ name: "jiaoyi", query: { redId: 1111 } });
    },
    tochongzhi: function () {
      let _this = this;
      _this.$router.push({ name: "cz_history", query: { redId: 1111 } });
    },
    jiao: function () {
      let _this = this;
      _this.$router.push({ name: "team", query: { redId: 1111 } });
    },
    zzku: function () {
      let _this = this;
      _this.$router.push({ name: "my_seeds", query: { redId: 1111 } });
    },
    zhongzhi: function () {
      this.show = true;
    },
    sheng: function () {
      this.show_td = true;
    },
  },
};
</script>
<style scoped>
.baise >>> .van-cell__title {
  color: white !important;
}

.home222 {
  background-color: #ffff;
  padding-bottom: 200px;
}
.content245 {
  width: 100%;
  height: auto;
  min-height: 1120px;
  background: url('../assets/img/cl_bg.jpg') no-repeat #f7fbe1;
   background-size: 100%;
   background-color: #fff;
  padding-top: 10px;
}
.shuye {
  width: 100%;
  height: 122px;
  position: fixed;
  bottom: 50px;
  left: 0;
}
.shuye img {
  width: 100%;
}
.up_move3 {
  animation: up5 1s linear infinite;
}
@keyframes up5 {
  0% {
    top: 15px;
  }
  50% {
    top: 30px;
  }
  100% {
    top: 10px;
  }
}
.detail_li {
  width: 510px;
  height: 330px;
  position: relative;
  margin: 0 auto;
  background-size: 100%;
  margin-top: 450px;
}
.detail_li .code {
  display: block;
  width: 145px;
  height: 30px;
  font-size: 19px;
  line-height: 30px;
  color: white;
  position: absolute;
  bottom: 80px;
  left: 170px;
}

.ctr_btn {
  width: 600px;
  margin: 0 auto;
}

.ctr_btn .ctr_btn1 {
  width: 50%;
}
.ctr_btn .ctr_btn2 {
  width: 50%;
}
.detail_li .jia img {
  width: 100%;
}
.detail_li .max_shu {
  position: absolute;
  width:510px;
  height: auto;
  top:-200px;
  left:0px
}
.detail_li .max_shu img {
  width: 100%;
}

.detail_li  .guoshi1 img{
  width:100%
}


.detail_li .jindou {
  position: absolute;
  width: 66px;
  height: 66px;
  top: 10px;
  right: 30px;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 100%;
  z-index: 9;
}
.detail_li .jindou img {
  width: 46px;
  display: block;
  margin: 5px;
}


.van-tab__text {
  color: white !important;
}
.tab_nairong {
  width: 720px;
  margin: 0 auto;
  min-height: 100vh;
  background: #ffffff;
  border: 5px solid #ffdd96;
  border-radius: 0 0 10px 10px;
  box-sizing: border-box;
  padding: 20px;
}
.tab_nairong .li {
  width: 100%;
  height: auto;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 10px;
  background: #fff4d4;
  margin-top: 15px;
}
.tab_nairong .miumin {
  font-size: 16px;
  font-weight: bold;
  word-wrap: break-word;
  word-break: break-all;
  color: #ba8a0a;
}
.tab_nairong .dengji {
  font-size: 16px;
  font-weight: bold;
  word-wrap: break-word;
  word-break: break-all;
  color: #ba8a0a;
}
.tab_nairong .jiage {
  font-size: 16px;
  font-weight: bold;
  word-wrap: break-word;
  word-break: break-all;
  color: #ba8a0a;
}
.tab_nairong .miumin1 {
  padding-left: 10px;
  text-align: left;
}
.gengduo {
  width: 100%;
  height: 40px;
  position: relative;
}
.gengduo img {
  display: block;
  position: absolute;
  width: 97px;
  height: 52px;
  top: -20px;
  left: 323px;
}

.jiaoshui_list {
  width: 700px;
  margin: 0 auto;
  border-radius: 25px;
   background-color: #f7fbe1;
  min-height: 300px;
}
.ncc {
  width: 385px;
  height: 270px;
  position: absolute;
  left: 183px;
  top: 760px;
}
.xiaoniao {
  width: 100px;
  height: 113px;
  position: absolute;
  left: 80px;
  top: 256px;
}
.mifeng {
  width: 108px;
  height: 128px;
  position: absolute;
  right: 0px;
  top: 911px;
}
.shuitong {
  width: 146px;
  height: 134px;
  position: absolute;
  left: 300px;
  top: 1057px;
}
.shouzhi {
  width: 90px;
  height: 100px;
  position: absolute;
  left: 500px;
  top: 1197px;
}
.naidi {
  width: 166px;
  height: 140px;
  position: absolute;
  left: 390px;
  top: 436px;
}
.daojishi {
  width: 550px;
  height: 50px;
  position: absolute;
  text-align: center;
  line-height: 50px;
  font-size: 34px;
  color: #fff;
  left: 100px;
  top: 480px;
}
.up_move {
  animation: up 2s linear infinite;
}
@keyframes up {
  0% {
    top: 256px;
  }
  50% {
    top: 270px;
  }
  100% {
    top: 250px;
  }
}
.up_move1 {
  animation: up1 2s linear infinite;
}
@keyframes up1 {
  0% {
    right: 15px;
  }
  50% {
    right: 30px;
  }
  75% {
    right: 0px;
  }
  100% {
    right: 15px;
  }
}

.up_move2 {
  animation: up2 1s linear infinite;
}
@keyframes up2 {
  0% {
    top: 800px;
    left: 500px;
  }

  50% {
    top: 850px;
    left: 540px;
  }

  100% {
    top: 800px;
    left: 500px;
  }
}

.xq_btn {
  width: 100%;
  height: auto;
}
.xq_btn .li_btn {
  width: auto;
  height: 65px;
  padding: 0 15px;
  line-height: 65px;
  background: #fd9a08;
  color: white;
  font-size: 32px;
  margin-left: 20px;
  float: left;
  margin-top: 25px;
  border: 2px solid white;
  border-radius: 25px;
}
.cz_btn {
  width: 350px;
  height: 65px;
  padding: 0 15px;
  line-height: 65px;
  background: #fd9a08;
  color: white;
  font-size: 32px;

  float: left;
  margin-top: 25px;
  border: 2px solid white;
  border-radius: 25px;
  position: fixed;
  left: 200px;
  bottom: 155px;
}
.liebiao {
  min-height: 100vh;
}
.zidin1 >>> .van-col {
  font-size: 18px;
  color: #fff;
  line-height: 25px;
}
.zidin .van-col {
  font-size: 14px;
  color: #fff;
  line-height: 25px;
  /* text-align: left; */
}

.list {
  width: 700px;
  margin: 0 auto;
  min-height: 400px;
  background-color: #f7fbe1;
  border-radius: 25px;
}
.list .title {
  text-align: center;
  color: white;
  font-size: 28px;
  line-height: 40px;
}

.list .cz_list {
  width: 670px;
  height: 200px;
  background: white;
  border-radius: 20px;
  margin: 0 auto;
  margin-top: 20px;
  padding: 5px;
  box-sizing: border-box;
}
.list .cz_list .order_no {
  width: 100%;
  height: 35px;
  padding-top: 20px;
}
.list .cz_list .order_no .order {
  width: 50%;
  background: url("../assets/img1/dd.png") no-repeat;
  background-size: 24px 30px;
  padding-left: 30px;
  box-sizing: border-box;
  height: 35px;
  text-align: left;
  font-size: 24px;
  font-weight: 700;
  color: #333;
  line-height: 35px;
}
.list .cz_list .order_no .status {
  width: 50%;
  height: 35px;
}
.list .cz_list .order_no .status .kuang {
  width: 100%;
  height: 33px;
  border: 1px solid #e4b025;

  line-height: 33px;
  font-size: 12px;
  color: #e4b025;
  border-radius: 7px;
}
.list .cz_list .date {
  line-height: 35px;
  text-align: left;
  font-size: 24px;
  font-weight: 100;
  color: #999999;
  margin-top: 20px;
}
.list .cz_list .money {
  width: 100%;
  height: 35px;
  padding-top: 20px;
}
.list .cz_list .money .money1 {
  width: 50%;
  text-align: left;
  font-size: 24px;
  font-weight: 100;
  color: #999999;
}
.list .cz_list .money .money3 {
  width: 50%;
  text-align: right;
  font-size: 24px;
  font-weight: 100;
  color: #f13b17;
}

.list1 {
  width: 700px;
  margin: 0 auto;
  min-height: 700px;
  background-color: #f7fbe1;
  border-radius: 25px;
}
.list1 .title {
  text-align: center;
  color: #333;
  font-size: 28px;
  line-height: 40px;
  padding-bottom: 50px;
  padding-top: 20px;
}

.list1 .cz_list {
  width: 670px;
  height: 240px;
  background: white;
  border-radius: 20px;
  margin: 0 auto;
  margin-top: 20px;
  padding: 5px;
  box-sizing: border-box;
}
.list1 .cz_list .order_no {
  width: 100%;
  height: 35px;
  padding-top: 20px;
  padding-bottom: 10px;
}
.list1 .cz_list .order_no .order {
  width: 50%;
  background: url("../assets/img1/dd.png") no-repeat;
  background-size: 24px 30px;
  padding-left: 30px;
  box-sizing: border-box;
  height: 35px;
  text-align: left;
  font-size: 24px;
  font-weight: 700;
  color: #333;
  line-height: 35px;
}
.list1 .cz_list .order_no .status {
  width: 50%;
  height: 35px;
}
.list1 .cz_list .order_no .status .kuang {
  width: 230px;
  height: 33px;
  border: 1px solid #e4b025;
  padding: 0 15px;
  line-height: 33px;
  font-size: 12px;
  color: #e4b025;
  margin-left: 60px;
  border-radius: 7px;
}
.list1 .cz_list .date {
  line-height: 35px;
  text-align: left;
  font-size: 24px;
  font-weight: 100;
  color: #999999;
  margin-top: 0px;
}
.list1 .cz_list .money {
  width: 100%;
  height: 30px;
  padding-top: 0px;
}
.list1 .cz_list .money .money1 {
  width: 50%;
  text-align: left;
  font-size: 24px;
  font-weight: 100;
  color: #999999;
}
.list1 .cz_list .money .money3 {
  width: 50%;
  text-align: right;
  font-size: 24px;
  font-weight: 100;
  color: #f13b17;
}
.fuchuang {
  width: 330px;
  height: 303px;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 25px;
  position: absolute;
  right: 20px;
  top: 180px;
  padding: 30px;
  box-sizing: border-box;
  z-index: 10;
}
.fuchuang .public_flex {
  width: 100%;
}
.fuchuang .public_flex .p1 {
  color: #666;
  width: 60%;
  text-align: left;
}
.fuchuang .public_flex .p2 {
  color: #999;
  width: 40%;
  text-align: right;
}
.fuchuang .name{
  height: 35px;
}
.fuchuang .code{
  height: 65px;
}
.fuchuang .level{
  height: 35px;
}
.fuchuang .dengji{
  height: 65px;
}


.qq1{
  width:31px;height:79px;position:absolute;top:20%;right:35%;z-index: 9;
}
.qq2{
  width:53px;height:124px;position:absolute;top:17%;left:5%;z-index: 9;
}
.qq3{
  width:36px;height:93px;position:absolute;top:29%;left:35%;z-index: 9;
}
.fs{
  width:180px;height:180px;position:absolute;top:395px;left:5px;z-index: 9;
}
.up_movefs{
	animation: upfs 4s linear infinite;
       }
  @keyframes upfs {
	
	0%{
    transform:rotate(0deg)
	}
	
    100%{
      transform:rotate(360deg)
	}
  }

  .up_moveqq1{
	animation: upqq1 5s linear infinite;
       }
  @keyframes upqq1 {
	
	0%{
        top:20%;
	}
    50%{
        top:25%;
	}
  100%{
    top:20%;
	}
  }
  .up_moveqq2{
	animation: upqq2 5s linear infinite;
       }
  @keyframes upqq2 {
	
	0%{
        top:17%;
	}
    50%{
        top:14%;
	}
  100%{
    top:17%;
	}
  }
  .up_moveqq3{
	animation: upqq3 5s linear infinite;
       }
  @keyframes upqq3 {
	
	0%{
        top:29%;
	}
  50%{
        top:35%;
	}
  100%{
    top:29%;
	}
  }
  /* .home222>>>.van-tabbar{
  background: url('../assets/img1/tab_bg.png') no-repeat;
  background-size: cover;
  padding-top: 1.1rem;
  border: 0!important;
}
.home222>>>.van-tabbar::after{
  border: 0!important;
}
.home222>>>.van-tabbar .van-tabbar-item{
  color:#fff
}
.home222>>>.van-tabbar .van-tabbar-item--active{
  background: rgba(0, 0, 0, 0);
  color:#1989fa
} */



.new_title{
  width:666px;
  height:85px;
  margin: 0 auto;
  color:152331;
  font-size: 42px;
  font-weight: 600;
  text-align: left;
}
.new_shu{
  margin-bottom: 50px;
}
.new_shu .shuli{
  width:688px;
  height: 110px;
  padding: 30px;
  box-sizing: border-box;
  border-bottom:1px solid #9999cc;
  margin:0 auto;
  margin-bottom: 20px;
}
.new_shu .shuli .icon{
  width: 52px;
  height: 52px;
}
.new_shu .shuli .icon img{width:100%}
.new_shu .shuli .title{
  text-align: left;
  width: 250px;
  color:003333;
  font-size: 24px;
}
.new_shu .shuli .data{
  text-align: right;
  width: 250px;
  color:003333;
  font-size: 24px;
}

.menuli{
  width:690px;
  height: 85px;
  border-bottom:1px solid #9999cc;
  margin:0 auto;
}
.menuli .title{
  text-align: left;
  width: 600px;
  color:003333;
  font-size: 24px;
  padding-left: 10px;

}
.menuli .link{
  padding-right: 10px;
}









/* 金币动画-基础样式 */
.anboxclass{
    animation-name: boxanname1;
    animation-duration: 1.5s;
    animation-delay: 1s;
    animation-timing-function: cubic-bezier(0,0,0,0);
    animation-fill-mode:forwards;
    /* animation-iteration-count: infinite; */
}
.gildclass{
    position: absolute;
        /* top: 15%; */
        /* left: 50%; */
        margin-left: 0%;
        margin-top: 35%;
        width: 25px;
        
        animation: myfirst 1.5s;
        animation-delay: 1s;
        animation-timing-function: cubic-bezier(0.14,-0.2,1,0.18);
        animation-fill-mode:forwards;
        z-index: 9999999999;
        /* animation-iteration-count: infinite; */
}

/* 只动盒子的left就行 */
/* 第一个金币 */
#anbox1{
    animation-name: boxanname1; 
    animation-delay: 0.5s;
}
#gild1{
    animation-delay: 0.5s;    
}   
@keyframes myfirst {
    0% { }
    10%  {width: 15px}
    20%  {width: 35px}
    100% {  margin-top: 1100px; width: 40px; transform:rotateX(1500deg); opacity: 0;}
}
@keyframes boxanname1 {
    0% { }
    100% { margin-left: 60%; }
}

/* 第二个金币 */
#anbox2{
    animation-name: boxanname2;
    animation-delay: 0.7s;   
}
#gild2{
    animation-delay: 0.7s;    
} 
@keyframes boxanname2 {
    0% { }
    100% { margin-left: 20%; }
}

/* 第三个金币 */
#anbox3{
    animation-name: boxanname3;
    animation-delay: 0.8s;  
}
#gild3{
    animation-delay: 0.8s;    
}
@keyframes boxanname3 {
    0% { }
    100% { margin-left: 0%; }
}

/* 第四个金币 */
#anbox4{
    animation-name: boxanname4;
    animation-delay: 0.3s;
}
#gild4{
    animation-delay: 0.3s;    
}
@keyframes boxanname4 {
    0% { }
    100% { margin-left: -20%; }
}

/* 第五个金币 */
#anbox5{
    animation-name: boxanname5;
    animation-delay: 0.3s; 
}
#gild5{
    animation-delay: 0.3s;    
}
@keyframes boxanname5 {
    0% { }
    100% { margin-left: -60%; }
}

/* 第五个金币 */
#anbox6{
    animation-name: boxanname6;
    animation-delay: 0.2s; 
}
#gild6{
    animation-delay: 0.3s;    
}
@keyframes boxanname6 {
    0% { }
    100% { margin-left: -50%; }
}

/* 第五个金币 */
#anbox7{
    animation-name: boxanname7;
    animation-delay: 0.2s; 
}
#gild7{
    animation-delay: 0.3s;    
}
@keyframes boxanname7 {
    0% { }
    100% { margin-left: 40%; }
}
/* 第五个金币 */
#anbox8{
    animation-name: boxanname8;
    animation-delay: 0.2s; 
}
#gild8{
    animation-delay: 0.3s;    
}
@keyframes boxanname8 {
    0% { }
    100% { margin-left: 15%; }
}
</style>
