<template>
  <div class="about55555555">
    <h1>This is an Wenzhang page</h1>
    <form action="http:://www.baidu.com" method="post" id="myForm"  enctype="multipart/form-data">
      <input type="text"  placeholder="请输入昵称" >
      <input type="password" placeholder="请输入密码" >
      <input type="text" placeholder="请输入手机号">
      <input type="submit" value="登录">
    </form>


  <van-list
  v-model="loading"
  :finished="finished"
  finished-text="没有更多了"
  @load="onLoad"
>
  <van-cell v-for="item in list" :key="item" :title="item" />
</van-list>



  </div>
  
</template>
<script>
import { List } from 'vant';
import { Toast } from 'vant';
import Vue from 'vue';
Vue.use(List);
Vue.use(Toast);
export default {
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
    };
  },
  methods: {
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      setTimeout(() => {
        for (let i = 0; i < 10; i++) {
          this.list.push(this.list.length + 1);
        }

        // 加载状态结束
        this.loading = false;

        // 数据全部加载完成
        if (this.list.length >= 40) {
          this.finished = true;
        }
      }, 1000);
    },
  },
};
</script>