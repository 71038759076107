<template>
  <div class="home">
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item>
        <img src="../assets/images/202108171837478775358034.png" style />
      </van-swipe-item>
      <van-swipe-item>
        <img src="../assets/images/202108171837544544177793.png" style />
      </van-swipe-item>
    </van-swipe>

    <div data-v-5c5d3a78 class="van-tabs van-tabs--line">
      <div class="van-tabs__wrap van-tabs__wrap--scrollable">
        <div
          role="tablist"
          class="van-tabs__nav van-tabs__nav--line van-tabs__nav--complete"
          style="border-color: rgb(64, 135, 241); background: rgb(21, 29, 49);"
        >
          <div
            role="tab"
            aria-selected="true"
            class="van-tab van-tab--active"
            style="color: rgb(64, 135, 241);"
          >
            <span class="van-tab__text">Instagram</span>
          </div>
          <div
            class="van-tabs__line"
            style="background-color: rgb(64, 135, 241); width: 60px; transform: translateX(207px) translateX(-50%);"
          ></div>
        </div>
      </div>
      <div class="van-tabs__content">
        <div data-v-5c5d3a78 role="tabpanel" class="van-tab__pane" style="padding: 0px 12px;">
          <div data-v-5c5d3a78 class="van-cell"   v-for="item in vip"
            :key="item.id"
          :title="item.title" v-on:click="to_task(item.id)">
            <div data-v-5c5d3a78 class="van-cell__title">
              <b data-v-5c5d3a78>{{item.title}}</b>
              <div data-v-5c5d3a78>Daily Tasks:{{item.max_task}}</div>
            </div>
          </div>
         
        </div>
      </div>
    </div>

    <!-- <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
  </div>
</template>

<script>
// @ is an alias to /src

import * as local from "@/libs/local";
//import HelloWorld from '@/components/HelloWorld.vue';
import { Toast } from "vant";
import Vue from "vue";
import { Swipe, SwipeItem } from "vant";
import { Tabbar, TabbarItem } from "vant";
import * as api from '@/api';
//import * as api from "@/api";
import { ref } from "vue";
//import * as loginUtil from '@/utils/loginUtil';

Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(local);
export default {
  name: "Home",
  components: {
    //HelloWorld
  },
  data() {
    return {
      title: "商品列表",
      active: 0,
      productList: [],
      howExchangeItem: null,
      popHowExchange: false,
      isshow: 0,
      vip:[],
      info:{}
    };
  },
  setup() {
    const active = ref(0);
    const onChange = index => Toast("标签" + index);
    return {
      active,
      onChange
    };
  },
  created: function() {
    //Toast("提示内容");
    //this.$http.post("token", {})
    // api.getConfig("123", (err, data) => {
    //   console.log(err);
    //   console.log(data);
    // });
    // api.login(
    //   {
    //     username: 111,
    //     password: 22222,
    //     captcha: "1234",
    //     env: "app"
    //   },
    //   (err, data) => {
    //     console.log(err);
    //     console.log(data);
    //   }
    // );
    let _this = this;
    console.log(_this.$store);
        api.getinfo({
          token: local.readInfo('jwt'),
        }, (err, data) => {
          if (!err && data.code === 1) {
               
                 let _this = this
               _this.vip=data.data.dengji
          
          } else {
              console.log(data)
          if(data&&'msg' in data){
             Toast(data.msg)
           }
           // this.logining = false
         
          }
        
        })
    //let _this = this
    //_this.$router.push({'name': 'About', query: {redId: 1111}})
  },
  methods: {
    howExchange: function(item) {
      // 如何兑换
      this.howExchangeItem = item;
      this.popHowExchange = true;
    },
    to_not_open: function() {
      Toast("A task is not open");
    },
    close: function() {
      this.isshow = 1;
    },
    toline: function() {
      let _this = this;
      _this.$router.push({ name: "line", query: { redId: 1111 } });
    },
      to_task: function(d) {
      let _this = this;
      _this.$router.push({ name: "tasklist", query: { id: d } });
    },
    tolang: function() {
      let _this = this;
      _this.$router.push({ name: "language", query: { redId: 1111 } });
    }
  }
};
</script>
<style >

</style>
