<template>
  <div class="about333">
    <div class="Site PageBox">
      <van-nav-bar
    :title="langs.t1"
    :right-text=" select_lang.name "
     left-arrow
      @click-left="back"
    @click-right="onClickRight"
     />

 
      <div class="Login ScrollBox" >
        <h1 style="text-align: center;">{{langs.t9}}</h1>
        <div class="logo">
        <img src="../assets/img/logo.png" width="100%" /> 
      </div>
          <div class="login-form11">
             <van-form  >
              <van-field
  readonly
  clickable
  name="bank"
  :value="value"
   left-icon="add-o"
  placeholder="Country code"
  @click="showPicker = true" style=""
/>
<van-popup v-model="showPicker" position="bottom" height="500px" style="">
  <van-picker
    show-toolbar
    :confirm-button-text="text10[lang]"
    :cancel-button-text="text8[lang]"
    :title="text3[lang]"
    :columns="columns"
    :default-index="index"
    @confirm="onConfirm"
    @cancel="showPicker = false"

  />
</van-popup>
  <van-field
    v-model="username"
    name="user_name"
    label=""
      left-icon="manager"
    :placeholder="langs.t2"
    :rules="[{ required: true, message: '' }]"
  />
  <van-field
    v-model="sms"
    center
    clearable
    left-icon="comment-o"
    :placeholder="langs.t8"
  >
    <template #button >
      <van-button size="small" type="primary"  @click="send">{{msg}}</van-button>
    </template>
  </van-field>
  <van-field
    v-model="password"
    type="password"
    name="password"
    label=""
    left-icon="lock"
    :placeholder="langs.t5"
    :rules="[{ required: true, message: '' }]"
  />
  <van-field
    v-model="zf_password"
    type="password"
    name="zf_password"
    label=""
    left-icon="lock"
    :placeholder="langs.t10"
    :rules="[{ required: true, message: '' }]"
  />
    <van-field
    v-model="code"
    type="digit"
    name="code"
    label=""
    left-icon="friends"
    :readonly="is_yq"
    :placeholder="langs.t7"
    :rules="[{ required: true, message: '' }]"
  />
  <div style="padding: 20px 16px;">
    <van-button round block type="info" class="van-button--danger" native-type="submit" color="#07c160"  style="font-size: 18px;"  @click="onSubmit">
        <span class="van-button__text">{{langs.t6}}
</span></van-button>
    
  </div>
  
</van-form>
  </div>
      </div>
      <div class="BrowserTips" style="display: none;">
        <img src="../assets/images/down-zy.png" width="80%" />
      </div>
    </div>





    <van-popup v-model="lang_show" round position="bottom">
    <van-picker
  :title="langs.tp3"
  :columns="lang_list"
  @confirm="onConfirm_lang"
  @cancel="lang_show = false"
  :show-toolbar=true
  :confirm-button-text="langs.tp2"
  :cancel-button-text="langs.tp1"
/>
</van-popup>
  </div>


</template>
<script>
import Vue from 'vue';
import { Form } from 'vant';
import { Field } from 'vant';
import * as api from '@/api';
import { Toast } from 'vant';
import { Dialog } from 'vant';
import { getUrlKey } from '@/utils';
import * as local from "@/libs/local";
import { Picker } from 'vant';
Vue.use(Form)
Vue.use(Field)
Vue.use(Dialog);
Vue.use(Picker);
export default {
  name:"reg",
    data() {
    return {
      miao:120,
      msg:"send",
      logining:false,
      logining1:false,
      username: '',
      value:'+57',
      index:0,
      select_index:0,
      sms:'',
      password: '',
      zf_password: '',
      code: '',
      is_yq:false,
      biao:'',
       showPicker: false,
      columns1:["57","880","966","964","20","233"],
      columns:["+57","+880","+966","+964","+20","+233"],
      lang:0,
      text1:["register","التسجيل","enregistrer"],
      text2:["Welcome to register","مرحبا بكم في التسجيل","Bienvenue pour vous inscrire"],
      text3:["select Country code","اختر رمز البلد / المنطقة","sélectionnez Code pays"],
      text8:["cancel","ألغى","Annuler"],
      text10:["confirm","أكّد","confirmer"],
      text9:["Please enter phone number","الرجاء إدخال رقم الهاتف","Veuillez entrer le numéro de téléphone"],
      text4:["verification code","رمز التحقق","le code de vérification"],
      text5:["Please enter login password","الرجاء إدخال كلمة السر","Veuillez entrer le mot de passe de connexion"],
      text6:["Please enter the invitation code","الرجاء إدخال رمز","Veuillez entrer le code"],
      text7:["register","مسجل","enregistrer"],
      text11:["send","البريدية","envoyer"],
      lang_list: [],
      select_lang: {},
      langs: {},
      lang_show:false,
    };
  },
  created(){
    let code=getUrlKey("code",window.location.href)
  //  if(code){
  //    this.code=code
  //  }
  console.log(code)
   this.set_biao()
     this.lang=local.readInfo('setting_lang')==undefined||local.readInfo('setting_lang')==null?0:local.readInfo('setting_lang')
   this.msg=this.text11[this.lang]
   console.log(this.$route.query)
 
  this.code=this.$route.query.code?this.$route.query.code:"0000";
  if(this.code!="0000"){
     this.is_yq=true;
  }
  //this.lang=this.$route.query.lang?this.$route.query.lang:this.lang;
  this.lang=0;
   local.saveInfo('setting_lang',this.lang)
   this.get_imdex_data()
  },
  methods: {
    get_imdex_data:function(){
        api.all("/qintai_public/api.user1/reg_init",{
        token: local.readInfo('jwt'),
        lang:local.readInfo('car_lang'),
        }, (err, data) => {
          if (!err && data.code === 1) {
          this.lang_list = data.lang_list;
          this.select_lang = data.select_lang;
          this.langs = data.lang;
       //   this.columns[0]=data.lang.tu1
          this.msg=this.langs.tu1   
          } else {
              console.log(data)
          //  if(data&&'msg' in data){
          //    Toast(data.msg)
          //  }
            //this.block = false
         
          }
        
        })
    },
    onClickRight:function(){
      this.lang_show=true
    },
    onConfirm_lang(value,index){
      console.log(index)
      console.log(value)
      local.saveInfo('setting_lang',index)
      local.saveInfo('car_lang',value.lang)
      this.lang_show=false
      window.location.reload();
    },
    onCancel_lang:function(){},
    send:function(){
      if(this.value==''){
      //  Toast('Please select country area code first')
        Toast(this.langs.t4)
        return
      }
      if(this.username==''){
        //Toast('Please enter your phone number')
        Toast(this.langs.t2)
       
        return
      }
    
        if (this.logining1) return
                Toast.loading({
        message: "loading...",
        forbidClick: true
      });
      if(this.msg!=this.langs.tu1){
      
        Toast(this.langs.tu2)
        return
      }
       this.logining1=true

        api.sms({
          user_name: this.username,
          biaoshi:this.biao,
          code:this.sms,
          guo:this.columns1[this.select_index],
          yanzheng:1,
          lang:this.lang
        }, (err, data) => {
         
          if (!err && data.code === 1) {
            this.logining1 = false
                // Toast(data.yy[this.lang])
                Dialog.alert({
              message:    data.msg,
              confirmButtonText: this.langs.tp2,
              theme: 'round-button',
              }).then(() => {
              // on close
              });
                 this.jishi()
          } else {
              this.set_biao()
              console.log(data)
              Toast(data.msg)
             this.logining1 = false
         
          }
        
        })
    },
         onConfirm(value, index) {
      this.value = value;
      this.select_index=index
      this.showPicker = false;
        },
    set_biao:function(){
           
            api.biao({
          biaoshi:this.biao,
          code:this.sms,
          guo:this.value
        }, (err, data) => {
          if (!err && data.code === 1) {
                let biaoshi=data.data.ssxx
                local.saveInfo('biaoshi',biaoshi);
                this.biao=biaoshi
          
          } else {
          Toast("Unable to service")
          }
        
        })
 
  
    },
    jishi:function(){
       let time= setInterval(() => {
                     if(this.miao>0){
                     this.miao=this.miao-1;
                     this.msg=this.miao
                     }else{
                       this.miao=120;
                       this.msg=this.langs.tu1
                       clearInterval(time)
                     }
                   }, 1000);
    },
      back(){
        //this.$router.go(-1);//返回上一层
        let _this = this;
      _this.$router.push({ name: "Login", query: { redId: 1111 } });
    },
    onSubmit(values) {
      // if(!is_mile(values.user_name)){
      //    Toast('User name must be the E-mail number')
      //    return
      // }
       if (this.logining) return
                Toast.loading({
        message: "loading...",
        forbidClick: true
      });
       this.logining=true
      console.log('submit', values);
        api.register({
          user_name: this.username,
          password: this.password,
          zf_password:this.zf_password,
          yaoqin_code:this.code,
          biaoshi:this.biao,
          guo:this.value,
          code:this.sms
        }, (err, data) => {
          if (!err && data.code === 1) {
            this.logining = false
         
                Toast(data.msg)
                 let _this = this;
                _this.$router.push({ name: "Login", query: { redId: 1111 } });
          
          } else {
              console.log(data)
              Toast(data.msg)
            this.logining = false
         
          }
        
        })
    },
  },
}
</script>
<style scoped>

.Login .href {
  color: #999;
}
.fr {
  float: right !important;
}
.Login {
  text-align: center;
}
.logo{
  width: 200px;
  margin: 0 auto;
}
.login-form11{
  /* position: absolute; */
  width: 670px;
  height: auto;
  background: white;
  /* left: 90px;
  top:580px; */
  padding: 20px;
  box-sizing: border-box;
  border-radius: 20px;
  margin: 0 auto;
  margin-top: 70px;
  box-shadow: 5px 5px 15px 5px #e3e3e3;
}


.Login .van-cell {
  font-size: 12px;
  color: #888;
  background: transparent !important;
}
/* .Site .van-cell {
  align-items: flex-start;
} */
.about333 {
  background: #f9f9f9;
  background-image:url("../assets/img/banner-bg.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: 50px;
  min-height: 100vh;
  max-width: 750px;
  margin: 0 auto;
}

/* body,
html {
  height: 100%;
}
#app {
  height: 100%;
} */
.Login h1 {
    margin: 0.975rem 0.1rem 0.1rem;
    text-align: left;
    color: #fff;
}
</style>
