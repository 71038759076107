<template>
  <div class="about434343">
<div data-v-65207978 class="van-nav-bar van-nav-bar--fixed">
      <div class="van-nav-bar__left">
        <i class="van-icon van-icon-arrow-left van-nav-bar__arrow">
          <!---->
        </i>
      </div>
      <div class="van-nav-bar__title van-ellipsis">bankinfo</div>
      <div class="van-nav-bar__right"></div>
    </div>
   <van-form @submit="onSubmit">
  <van-field
    v-model="username"
    name="用户名"
    label="用户名"
    placeholder="用户名"
    :rules="[{ required: true, message: '请填写用户名' }]" style="background:rgb(21 29 49)"
  />
  <van-field
    v-model="password"
    type="password"
    name="密码"
    label="密码"
    placeholder="密码"
    :rules="[{ required: true, message: '请填写密码' }]" style="background:rgb(21 29 49)"
  />
  <van-field
  readonly
  clickable
  name="picker"
  :value="value"
  label="选择器"
  placeholder="点击选择城市"
  @click="showPicker = true" style="background:rgb(21 29 49)"
/>
<van-popup v-model="showPicker" position="bottom">
  <van-picker
    show-toolbar
    :columns="columns"
    @confirm="onConfirm"
    @cancel="showPicker = false"
  />
</van-popup>
  <div style="margin: 16px;">
    <van-button round block type="info" native-type="submit">提交</van-button>
  </div>
</van-form>
  </div>
</template>
<script>
import Vue from 'vue';
import { Form } from 'vant';
import { Field } from 'vant';
import { NavBar } from 'vant';

Vue.use(NavBar);
Vue.use(Form);
Vue.use(Field);
export default {
    data() {
    return {
      value: '',
      columns: ['杭州', '宁波', '温州', '嘉兴', '湖州'],
      showPicker: false,
       username: '',
      password: '',
    };
  },
  methods: {
     onSubmit(values) {
      console.log('submit', values);
    },
    onConfirm(value) {
      this.value = value;
      this.showPicker = false;
    },
  },
}
</script>