<template>
  <div class="about99">

    <div class="Login11111 ScrollBox11111" >
      <van-nav-bar
  :title="langs.t1"
  :right-text=" select_lang.name "

  @click-right="onClickRight"
/>

<!-- 
      <img src="../assets/img1/login_bg.jpg" style="height: 100vh;width: auto;"/> -->
      <div class="logo">
        <img src="../assets/img/logo.png" width="100%" /> 
      </div>
      <div class="login-form">
      <van-form @submit="onSubmit" >
  <van-field
    v-model="username"
    name="user_name"
    label=""
      left-icon="manager"
    :placeholder="langs.t2"
    :rules="[{ required: true, message: '' }]"
  />
  <van-field
    v-model="password"
    type="password"
    name="password"
    label=""
    left-icon="lock"
    :placeholder="langs.t5"
    :rules="[{ required: true, message: '' }]"
  />
  <div style="padding: 20px 16px;">
    <van-button round block type="info" class="van-button--danger" native-type="submit"  color="#07c160" style="font-size: 18px;">  
      <span class="van-button__text">{{langs.t6}}</span></van-button>
     <div style="text-align: left; color:#333; margin-top: 90px;">
            {{langs.t4}}
            <a href="#/registeremail?code=0000" class="href" style="color:#ff6034">{{langs.t3}}</a>
            <span>&nbsp;&nbsp;</span>
         
            <a href="javascript:" v-on:click="to_kefu" class="href fr">{{langs.t7}}</a>
            <!-- <a href="#/line" class="href fr">{{text7[lang]}}</a> -->
          </div>
  </div>
  
</van-form>
      </div>
    </div>
    <van-popup v-model="lang_show" round position="bottom">
    <van-picker
  :title="langs.tp3"
  :columns="lang_list"
  @confirm="onConfirm_lang"
  @cancel="lang_show = false"
  :show-toolbar=true
  :confirm-button-text="langs.tp2"
  :cancel-button-text="langs.tp1"
/>
</van-popup>
<van-popup v-model="showPicker"  position="bottom" :style="{background:'white',height:'40%'}" >
  <van-picker
    show-toolbar
    :confirm-button-text="langs.tp2"
    :cancel-button-text="langs.tp1"
    :title="langs.t8"
    :columns="columns"
    :default-index="index1"
    @confirm="onConfirm"
    @cancel="showPicker = false"
  />
</van-popup>

  </div>
</template>
<script>
import Vue from 'vue';
import { Form } from 'vant';
import { Field } from 'vant';
import * as api from '@/api';
import { Toast } from 'vant';
import * as local from '@/libs/local'
import { NavBar } from 'vant';
import { Picker } from 'vant';
Vue.use(Form)
Vue.use(Field)
Vue.use(NavBar)
Vue.use(Picker);
export default {
    data() {
    return {
      logining:false,
      username: '',
      password: '',
      lang:"",
      columns: ["Aisin Car Customer Service"],
     // columns1:["Aisin Car Customer Service"],
      type:0,
      index1:0,
      kefu:{},
      showPicker: false,
      select_index:0,
    //  lang_text:["English"],
      lang_show:false,
      // text1:["Login","سجل","Connexion"],
      // text2:["Please enter phone number","الرجاء إدخال رقم الهاتف","Bitte geben Sie die Telefonnummer ein"],
      // text3:["Please enter login password","الرجاء إدخال كلمة السر","Veuillez entrer le mot de passe de connexion"],
      // text4:["Login now","تسجيل الدخول الفوري","Connecte-toi maintenant"],
      // text5:[" No account? ","لا حساب ؟","Pas de compte?"],
      // text6:[" Register","التسجيل","Enregistrer"],
      // text7:["Line","خط .","Doubler"],
      // text8:["cancel","ألغى","Annuler"],
      // text9:["Select the language","اختيار اللغة","Sélectionnez la langue"],
      // text10:["confirm","أكّد","confirmer"],
      // text11:["Forget password","نسيت كلمة السر","Mot de passe oublié"],
      // text20:["cancel","ألغى","Annuler"],
      // text21:["Select customer service platform","اختيار منصة خدمة العملاء","Sélectionnez la plateforme de service client"],
      // text22:["confirm","أكّد","confirmer"],
      lang_list: [],
      select_lang: {},
      langs: {},
      cate: [],
    };
  },
 created: function() {
   
   if(local.readInfo('setting_lang')==undefined||local.readInfo('setting_lang')==null){
       local.saveInfo('setting_lang',0)
       }
          //控制弹窗
    local.saveInfo('tanchuang',0)
      this.lang=local.readInfo('setting_lang')==undefined||local.readInfo('setting_lang')==null?0:local.readInfo('setting_lang')

      let token=this.$route.query.token?this.$route.query.token:"0000";
   if(token!='0000'){
    local.saveInfo("jwt",token)
    this.$router.push({ name: "Home", query: { redId: 1111 } });
   }

   this.get_imdex_data()
 },
  methods: {

    get_imdex_data:function(){
        api.all("/qintai_public/api.user1/login_init",{
        token: local.readInfo('jwt'),
        lang:local.readInfo('car_lang'),
        }, (err, data) => {
          if (!err && data.code === 1) {
          this.lang_list = data.lang_list;
          this.select_lang = data.select_lang;
          this.langs = data.lang;
          this.columns[0]=data.lang.tu1   
          } else {
              console.log(data)
          //  if(data&&'msg' in data){
          //    Toast(data.msg)
          //  }
            //this.block = false
         
          }
        
        })
    },
    onClickRight:function(){
      this.lang_show=true
    },
    onConfirm_lang(value,index){
      console.log(index)
      console.log(value)
      local.saveInfo('setting_lang',index)
      local.saveInfo('car_lang',value.lang)
      this.lang_show=false
      window.location.reload();
    },
    onCancel_lang:function(){},
    onSubmit(values) {
       if (this.logining) return
                Toast.loading({
        message: "loading...",
        forbidClick: true
      });
       this.logining=true
      console.log('submit', values);
        api.login({
          user_name: values.user_name,
          password: values.password,
        }, (err, data) => {
          if (!err && data.code === 1) {
            this.logining = false
            if (data.data.token) {
              Toast(data.msg)
               local.saveInfo('jwt',data.data.token)
                 let _this = this;
                _this.$router.push({ name: "Home", query: { redId: 1111 } });
            } else {
              console.log(data)
               Toast(data.msg)
            }
          } else {
              console.log(data)
              Toast(data.msg)
            this.logining = false
         
          }
        
        })
    },
    to_kefu: function () {
     // let baseUrl = "https://api.whatsapp.com/send?phone=";
      api.kf(
        {
          token: local.readInfo("jwt"),
        },
        (err, data) => {
          if (!err && data.code === 1) {

            //window.location.href = baseUrl + data.data.kefu.code;
            this.kefu=data.data.kefu
            this.type=0;
            this.showPicker=true
          } else {
            console.log(data);
            Toast(data.msg);
          }
        }
      );
    },
    onConfirm(value, index) {
           console.log(index)
                 console.log(this.type)
      
            let baseUrl = "https://api.whatsapp.com/send?phone=";
      this.select_index=index
      if(index==0){
        if(this.type==0){
          if(this.kefu.code==null||this.kefu.code==''||this.kefu.code=='0'||this.kefu.code==undefined){
            Toast(this.langs.tu2)
          }else{
          window.location.href = baseUrl + this.kefu.code;
           }
        }
     
      }
      if(index==1){
        if(this.type==0){
          if(this.kefu.code1==null||this.kefu.code1==''||this.kefu.code1=='0'||this.kefu.code1==undefined){
            Toast(this.langs.tu2)
          }else{
              window.location.href = this.kefu.code1;
          }
        }
      }
      this.showPicker = false;
        },
  },
}
</script>
<style scoped>

.about99{
  max-width: 640px;
  margin: 0 auto;
  background: #f9f9f9;
  height: 100vh;
  background-image:url("../assets/img/banner-bg.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: 50px;
  min-height: 100vh;
  max-width: 750px;
  margin: 0 auto;
}

/* .van-field__control{color:white!important} */
.Login11111 .href {
  color: #999;
}
.fr {
  float: right !important;
}
.Login11111 {
  text-align: center;
  position: relative;
  width:100%;
  height: 100%;
  overflow: hidden;
}
.ScrollBox11111 {
  overflow-x: hidden;
  overflow-y: auto;
}
.logo{
  width:200px;
  height: 200px;
  position: absolute;
  left: 50%;
  margin-left: -100px;
  top:200px
}
.login-form{
  position: absolute;
  width: 670px;
  height: auto;
  background: white;
  left: 35px;
  top:500px;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 20px;
  box-shadow: 5px 5px 15px 5px #e3e3e3;
}
.IndexBox11111,
.ScrollBox11111 {
  position: relative;
  width: 100%;
  height: 100%;
  overflow:hidden
}

.login-form >>>.van-field{
  margin-top: 10px;
}
/* .ScrollBox .van-form .van-field {
  background-color: #151d31 !important;
  border-radius: 0.5rem;
}
.Login .van-cell {
  font-size: 12px;
  color: #888;
  background: transparent !important;
}
.Site .van-cell {
  align-items: flex-start;
} */

/* .van-button--danger {
  border-color: transparent;
  background: linear-gradient(90deg, #4b34c3, #3d3ff7);
} */
body,
html {
  height: 100%;
}
#app {
  height: 100%;
}

</style>
