<template>
  <div class="content">
  <van-nav-bar
  :title="text2[lang]"
  :left-text="text1[lang]"
  :right-text="text3[lang]"
  left-arrow
  @click-left="onClickLeft"
  @click-right="onClickRight" 
/>
<div class="shuye">
      <img src="../assets/img1/nc_bg.png" />
    </div>
<div class="dajiange"></div>
<!-- <div class="zhifu public_flex">
   <div class="zf1"  @click="show_z"></div>
   <div class="zf2"  @click="cz"></div>
</div> -->


<div class="list">
  <div   v-for="(item,index) in lists" :key="index">
  <div style="height:20px"></div>
  <div class="title">{{ item.name }}</div>

<!-- <van-cell  /> -->

<div class="cz_list"   v-for="(item1,index1) in item.zz" :key="index1">
  <!-- <div class="icon tag0">I</div> -->
  <div  class="order_no public_flex">
    <div class="order">{{lang==0?item1.title:lang==1?item1.alb_name:item1.bs_name}}
    </div>
     <div class="status">
       <div class="kuang">{{text9[lang]}}:{{item.role}}</div>
     </div>
     </div>
     
    <div class="money public_flex">
      <div class="money1"><img src="../assets/img1/sh (1).png" v-if="item.id==1"><img src="../assets/img1/sh (2).png" v-else></div>
       <div class="money3">{{text7[lang]}}:{{item1.price}} {{text10[lang]}}</div>
    </div>
    <div class="date public_flex">  
      <div class="d1">{{text8[lang]}}： {{item1.end_day}}{{text11[lang]}}</div>
      <div class="d2" @click="buy(item1.id,item.id)">   <van-button round type="success" size="small" color="#ffb53c">&nbsp;&nbsp;&nbsp;&nbsp;
        {{text5[lang]}}&nbsp;&nbsp;&nbsp;&nbsp;</van-button></div>
    
    </div>
  </div>
</div>
  
  <div style="height:20px"></div>
</div>







<div style="height:80px"></div>



</div>

</template>

<script>
import Vue from "vue";
import { Tab, Tabs } from "vant";
import { List } from "vant";
import { Toast } from "vant";
import * as api from '@/api';
import * as local from "@/libs/local";
import { Form } from 'vant';
import { Field } from 'vant';
import { Dialog } from 'vant';
import { NavBar } from 'vant';
import { Empty } from 'vant';
import { Loading } from 'vant';
Vue.use(NavBar);
Vue.use(Form);
Vue.use(Field);
Vue.use(List);
Vue.use(Toast);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Empty);
Vue.use(Dialog);
Vue.use(Loading);
export default {
  name: "Home123213",
components: {
  //HelloWorld
},
data() {
  return {
    user:{},
    lists:[],
    sub: false,
    lang:0,
    text1:["back","رد : .","retour"],
     text2:["Buy props","شراء الدعائم","Acheter des accessoires"],
     text3:["my props","بلدي الدعائم","mes accessoires"],
     text4:["The kettle props","غلاية الدعائم","arrosoir"],
     text5:["buy","شراء","acheter"],
     text7:["price","الأسعار","prix"],
     text8:["Use time","استخدام الوقت","Utiliser le temps"],
     text9:["role","دور","rôle"],
     text10:["integral","أساسي","points"],
     text11:[" days","أيام .","jours"],
  };
},
created() {
   this.lang=local.readInfo('setting_lang')==undefined||local.readInfo('setting_lang')==null?0:local.readInfo('setting_lang')
 this.get_imdex_data()
},
methods: {
  get_imdex_data:function(){
        api.djlist({
        token: local.readInfo('jwt'),
        lang:this.lang
        }, (err, data) => {
          if (!err && data.code === 1) {
              this.user=data.data.user
              this.lists=data.data.list
          } else {
              console.log(data)
              Toast("Network error")
              this.sub = false
         
          }
        
        })
    },
  onClickLeft() {
    this.$router.go(-1); //返回上一层
  },   
  onClickRight: function () {
    let _this = this;
    _this.$router.push({ name: "my_props", query: { redId: 1111 } });
  },
  buy(id,id1){
    if(this.sub){
      return;
    }
    this.sub==true
    Toast.loading({
        message: "loading...",
        forbidClick: true
      });
    api.buydj({
        token: local.readInfo('jwt'),
        dj_id:id,
        cate_id:id1
        }, (err, data) => {
          this.sub=false;
          if (!err && data.code === 1) {
            //Toast(data.msg)
              Dialog.alert({
              message: data.yy[this.lang],
              confirmButtonText: this.lang==0?"confirm":this.lang==1?"أكّد":"confirmer",
             theme: 'round-button',
              }).then(() => {
              // on close
              });
         
          }else{
            if(data){
            Toast.fail(data.yy[this.lang])
            }
          }
        })

  }
}
};
</script>

<style scoped>
.home222 {
background-color: #79c752;
max-width: 750px;
margin: 0 auto;
}
.content {
width: 100%;
height: auto;
min-height: 1624px;
background: url("../assets/img1/my_bg.jpg") no-repeat #79c752;
background-size: 100%;
}
.people{
width:700px;
margin: 0 auto;
background: white;
border-radius: 20px;
overflow: hidden;
height: 280px;
}
.shuye {
width: 100%;
height: 122px;
position: fixed;
bottom: 0px;
left: 0;
}
.shuye img {
width: 100%;
}
.dajiange{
height: 185px;
}
.zhifu{
width: 100%;
height: 203px;
background: url("../assets/img1/cz_btn.png") no-repeat;
background-size: 100%;
}
.zhifu .zf1{
width:50%;
height: 203px;
}
.zhifu .zf2{
width:50%;
height: 203px;
}

.list{
width:700px;
margin: 0 auto;
min-height: 400px;
background: #fff;
border-radius: 25px;

}
.list .title{
text-align: center;
color:#333;
font-size: 28px;
line-height: 40px;
}

.list .cz_list{
width:670px;
height: 200px;
background: white;
border-radius: 20px;
margin: 0 auto;
margin-top: 20px;
padding: 5px;
box-sizing: border-box;
box-shadow: 3px 5px 10px 1px #dbdbdb;
}
.list .cz_list .order_no{
  width: 100%;
  height: 35px;
  padding-top: 20px;
}
.list .cz_list .order_no .order{
  width:50%;
  background: url("../assets/img1/dd.png") no-repeat;
 background-size: 24px 30px;
 padding-left: 30px;
 box-sizing: border-box;
 height: 35px;
 text-align: left;
 font-size: 24px;
 font-weight: 700;
 color:#333;
 line-height: 35px;
}
.list .cz_list .order_no .status{
  width: 50%;
  height: 35px;
}
.list .cz_list .order_no .status .kuang{
  width: 100%;
  height: 33px;
  border: 1px solid #e4b025;
  line-height: 33px;
  font-size: 12px;
  color:#e4b025;
  border-radius: 7px;
}
.list .cz_list .date{
  line-height: 35px;
  text-align: left;
  font-size: 24px;
 font-weight: 100;
 color:#999999;
 margin-top: 20px;
}
.list .cz_list .money{
  width: 100%;
  height: 35px;
  padding-top: 20px;
}
.list .cz_list .money .money1{
  width:50%;
  text-align: left;
  font-size: 24px;
 font-weight: 100;
 color:#999999;

}
.list .cz_list .money .money1 img{
  height: 64px;
}
.list .cz_list .money .money3{
  width:50%;
  text-align: right;
  font-size: 24px;
 font-weight: 100;
 color:#f13b17;
}
.tixian >>>.van-field__label{
width: 7.2em;
}
.tixian >>> .van-field__body {
border: 1px solid rgb(222, 221, 221);
}
.tixian{
width:700px;
margin: 0 auto;
min-height: 400px;
background: white;
border-radius: 25px;
padding: 25px 0;

}
</style>