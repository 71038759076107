<template>
  <div class="home222">
    <van-nav-bar
  :title="langs.t1"


/>
    <div class="content">

      <div class="new_tou public_flex">
    
            <div class="touxiang1" @click="to_my_info('info')">
                <img :src="data.user.head"  />
            </div>
            <div class="nameli">
              <div class="name one_hide">{{ langs.t2 }}:{{biaoshi+data.user.id+biaoshi1}}</div>
              <div class="name one_hide">{{langs.t3}}:{{data.user.user_name}}</div>
              <div class="dengji public_flex">
                <div>VIP:</div>
                <div @click="to_my_info('update')"><van-rate v-model="data.user.dengji" icon="gem" readonly  :size="18" void-icon="gem-o" color="#ee0a24" :count="10"/></div>
              </div>
            </div>

      </div>


      <div class="new_title">{{langs.t5}}</div>





      <!-- <div class="shuju">
  <van-grid :border="false" :column-num="2">
       <van-grid-item text="ces" @click="to_my_info('history')">
        <div class="title">{{langs.t6}}</div>
         <div class="shuzhi">{{data.user.zongchongzhi}}</div>
      </van-grid-item>

      <van-grid-item text="ces" @click="to_my_info('product_income')">
        <div class="title">{{langs.t7}}</div>
         <div class="shuzhi">{{data.jr_zzsy}}</div>
      </van-grid-item>

      <van-grid-item text="ces" @click="to_my_info('fundRecord')">
         <div class="biaoti">{{langs.t8}}</div>
         <div class="shuzhi">{{data.team}}</div>
      </van-grid-item>

      <van-grid-item text="ces" @click="to_my_info('withdrawal')">
         <div class="biaoti">{{langs.t9}}</div>
         <div class="shuzhi">{{ parseFloat(data.user.zongtixian)+ parseFloat(data.user.zongshouxufei)}}</div>
      </van-grid-item>

      <van-grid-item text="ces" @click="to_my_info('usdt_record')">
         <div class="biaoti">{{langs.t10}}</div>
         <div class="shuzhi">{{data.user.jinbi}}</div>
      </van-grid-item>

      <van-grid-item text="ces" @click="to_my_info('income')">
         <div class="biaoti">{{langs.t11}}</div>
         <div class="shuzhi">{{data.user.zongshouyi}}</div>
      </van-grid-item>

      <van-grid-item text="ces">
         <div class="biaoti">Fund income</div>
         <div class="shuzhi">{{data.user.zongshouyi}}</div>
      </van-grid-item>

      <van-grid-item text="ces">
         <div class="biaoti">Fund income</div>
         <div class="shuzhi">{{data.user.zongshouyi}}</div>
      </van-grid-item> -->

      <!-- <van-grid-item text="ces">
         <div class="biaoti">{{sj9[lang]}}</div>
         <div class="shuzhi">{{data.zz_days}}</div>
      </van-grid-item>
</van-grid>
</div> -->

<div style="height:20px"></div>



      <div class="new_shu">
        <div class="shuli public_flex" @click="to_my_info('history')" >
              <div class="icon"> <img src="../assets/img/wd (1).png"  /></div>
              <div class="title">{{langs.t6}}</div>
              <div class="data">{{data.user.zongchongzhi}} </div>
              <div class="link"><van-icon name="arrow" /></div>
        </div>
        <div class="shuli public_flex" @click="to_my_info('product_income')">
              <div class="icon"> <img src="../assets/img/wd (3).png"  /></div>
              <div class="title">{{langs.t7}}</div>
              <div class="data">{{data.jr_zzsy}} </div>
              <div class="link"><van-icon name="arrow" /></div>
        </div>
        <div class="shuli public_flex" @click="to_my_info('fundRecord')" >
              <div class="icon"> <img src="../assets/img/wd (6).png"  /></div>
              <div class="title">{{langs.t8}}</div>
              <div class="data">{{data.team}}</div>
              <div class="link"><van-icon name="arrow" /></div>
        </div>
        <div class="shuli public_flex" @click="to_my_info('withdrawal')">
              <div class="icon"> <img src="../assets/img/wd (2).png"  /></div>
              <div class="title">{{langs.t9}}</div>
              <div class="data">{{ parseFloat(data.user.zongtixian)+ parseFloat(data.user.zongshouxufei)}} </div>
              <div class="link"><van-icon name="arrow" /></div>
        </div>
        <div class="shuli public_flex"  @click="to_my_info('usdt_record')">
              <div class="icon"> <img src="../assets/img/wd (7).png"  /></div>
              <div class="title">{{langs.t10}}</div>
              <div class="data">{{data.user.jinbi}} </div>
              <div class="link"><van-icon name="arrow" /></div>
        </div>
        <!-- <div class="shuli public_flex" @click="to_my_info('my_fund')">
              <div class="icon"> <img src="../assets/img/wd (7).png"  /></div>
              <div class="title">{{langs.t28}}</div>
              <div class="data">{{data.user.jijinye}}</div>
              <div class="link"><van-icon name="arrow" /></div>
        </div> -->

        <div class="shuli public_flex" @click="to_my_info('income')">
              <div class="icon"> <img src="../assets/img/wd (8).png"  /></div>
              <div class="title">{{langs.t32}}</div>
              <div class="data">{{data.user.zongshouyi}}</div>
              <div class="link"><van-icon name="arrow" /></div>
        </div>

 
  
        <!-- <div class="shuli public_flex" @click="to_my_info('product_income')">
              <div class="icon"> <img src="../assets/img/wd (3).png"  /></div>
              <div class="title">{{}}</div>
              <div class="data">{{data.sy_zzsy}} </div>
              <div class="link"><van-icon name="arrow" /></div>
        </div> -->

   
      </div>




      <div class="new_title">{{langs.t11}}</div>

      <div class="new_menu">
        <div class="menuli public_flex"  @click="to_my_info('farm')">
              <div class="title">{{langs.t12}}</div>
              <div class="link"><van-icon name="plus" /></div>
        </div>
        <div class="menuli public_flex"  @click="to_my_info('my_team')">
              <div class="title">{{langs.t13}}</div>
              <div class="link"><van-icon name="plus" /></div>
        </div>
        <div class="menuli public_flex"  @click="to_my_info('update')">
              <div class="title">{{langs.t14}}</div>
              <div class="link"><van-icon name="plus" /></div>
        </div>
        <div class="menuli public_flex"  @click="to_my_info('info')">
              <div class="title">{{langs.t15}}</div>
              <div class="link"><van-icon name="plus" /></div>
        </div>
        <div class="menuli public_flex"  @click="to_my_info('order')">
              <div class="title">{{langs.t16}}</div>
              <div class="link"><van-icon name="plus" /></div>
        </div>
        <div class="menuli public_flex" @click="to_hong">
              <div class="title">{{langs.t17}}</div>
              <div class="link"><van-icon name="plus" /></div>
        </div>
        <div class="menuli public_flex"  @click="to_my_info('my_fund')">
              <div class="title">{{langs.t18}}</div>
              <div class="link"><van-icon name="plus" /></div>
        </div>
        <div class="menuli public_flex" @click="close">
              <div class="title">{{langs.t19}}</div>
              <div class="link"><van-icon name="plus" /></div>
        </div>
        <div class="menuli public_flex" @click="lay_out">
              <div class="title">{{langs.t20}}</div>
              <div class="link"><van-icon name="plus" /></div>
        </div>

       </div>
       <div style="height:20px"></div>



        <div style="width: 100%; height: 20px"></div>

        <div class="kefu " style="">
          <div class="ctr_btn1"   @click="to_kefu" style="margin: 0 auto;">
             <van-button round type="success" size="small" color="#ff8917"> {{langs.t21}}</van-button>
                </div>
              <!-- <div  class="ctr_btn2"  @click="to_kefu1">
                  <van-button round type="success" size="small" color="#ff8917"> {{text7[lang]}}</van-button>
              </div> -->
          </div>


          <div style="width: 100%; height: 20px"></div>




     
    </div>



    <div style="height:80px"></div>
<!-- 
    <van-tabbar v-model="active_bar" @change="click_tab">
  <van-tabbar-item icon="wap-home">{{ text31[lang] }}</van-tabbar-item>
  <van-tabbar-item icon="todo-list">{{ text31[lang] }}</van-tabbar-item>
  <van-tabbar-item icon="logistics">{{ text31[lang] }}</van-tabbar-item>
  <van-tabbar-item icon="chart-trending-o">{{ text32[lang] }}</van-tabbar-item>
  <van-tabbar-item icon="manager">{{ text33[lang] }}</van-tabbar-item>
</van-tabbar> -->
<van-popup v-model="showPicker"  position="bottom" :style="{background:'white',height:'40%'}" >
  <van-picker
    show-toolbar
    :confirm-button-text="langs.tp2"
    :cancel-button-text="langs.tp1"
    :title="langs.t27"
    :columns="columns"
    :default-index="index1"
    @confirm="onConfirm"
    @cancel="showPicker = false"
  />
</van-popup>

<van-action-sheet v-model="show1_1" :title="langs.t22">
  <div class="buy">

    <div class="name public_flex" style="">
         <div class="n1">{{langs.t23}}</div>
    
    </div>
    <div class="name public_flex" style="height: 70px;">
         <div class="n2" style="width: 100%;border: 1px solid #eee;">   
          <van-field v-model="pass" type="text" label="" :placeholder="langs.t23" />

        </div>
    </div>

    <div class="btn" @click="buy11"><van-button round type="success"  size="large" color="linear-gradient(to right, #ff6034, #ee0a24)">
      {{langs.t24}}</van-button></div>
      <div style="height:200px"></div>
  </div>
</van-action-sheet>
<Footer :lang_list="lang_list" :lang="langs" />
  </div>
</template>

<script>
// @ is an alias to /src

import * as local from "@/libs/local";
//import HelloWorld from '@/components/HelloWorld.vue';
import { Toast } from "vant";
import Vue from "vue";
import { Swipe, SwipeItem } from "vant";
import { Tabbar, TabbarItem } from "vant";
import { Grid, GridItem } from "vant";
import * as api from "@/api";
import { Tab, Tabs } from "vant";
import { Picker } from 'vant';
import { Col, Row } from "vant";
import { Empty } from "vant";
import { Popup } from "vant";
import { Cell, CellGroup } from "vant";
import { Step, Steps } from "vant";
import { Rate } from 'vant';
import { Dialog } from 'vant';
import Footer from './lyout/footer.vue';
Vue.use(Step);
Vue.use(Steps);
Vue.use(Rate);
//import * as loginUtil from '@/utils/loginUtil';
Vue.use(Picker);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(local);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Tab);
Vue.use(Dialog);
Vue.use(Tabs);
Vue.use(Col);
Vue.use(Row);
Vue.use(Empty);
Vue.use(Popup);
Vue.use(Cell);
Vue.use(CellGroup);
export default {
  components: {
    //HelloWorld
    Footer,
  },
  data() {
    return {
      biaoshi:"00",
      biaoshi1:"00",
      active: 2,
      active_bar:2,
      showPicker: false,
      columns: ["Aisin Car Customer Service","USDT Customer Service"],
      columns1:["Aisin Car Customer Service","USDT Customer Service"],
      data: {
        user: {
          head: "",
        },
      },
      dengji:5,
      type:0,
      show1_1:false,
      index1:0,
      kefu:{},
      pass:"",
       select_index:0,
      show: false,
      show1:false,
      block: true,
      lang:0,
      text1:["Account No","رقم الحساب ","N ° de compte"],
      text2:["top-up","  تعبئة رصيد"," &nbsp; &nbsp;recharger&nbsp; &nbsp; "],
      text3:["withdrawal","  سحب المال  ","retrait"],
      text4:["exchange"," &nbsp; &nbsp; تبادل &nbsp; &nbsp;"," &nbsp; &nbsp;échange  &nbsp; &nbsp;"],
      text5:["The props","&nbsp;&nbsp;الدعائم&nbsp;&nbsp;"," Les accessoires"],
      text6:["Contact customer service","خدمة الزبائن","service Clients"],
      text7:["Contact the team leader","تواصل مع قائد الفريق"," Chef d'équipe"],
      text8:["top-up","سجل الشحن","recharger"],
      text9:["withdrawal","سحب","retrait"],
      text10:["integral record","سجل النقاط","record de points"],
      text11:["Seed record","سجل البذور","Record de plantation"],
      text12:["Watering record","سجلات السحب","Registre d'arrosage"],
      text13:["USDT record","سجل USDT","Enregistrement USDT"],
      text14:["information","معلومات","information"],
      text15:["News center","مركز الأخبار","Centre d'Information"],
      text16:["Invite friends","دعوة الأصدقاء","Inviter des amis"],
      text17:["Props center","مركز الدعائم","Centre d'accessoires"],
      text18:["my team","فريقي","mon équipe"],
      text19:["exit","يترك","sortie"],
      text31:["Home","منزل .","Maison"],
      text32:["The farm","مزرعه","La ferme"],
      text33:["My"," فرد . ","Le mien"],
      text20:["cancel","ألغى","Annuler"],
      text21:["Select customer service platform","اختيار منصة خدمة العملاء","Sélectionnez la plateforme de service client"],
      text22:["confirm","أكّد","confirmer"],
      text23:["integral"," نقاط","intégral"],
      sj1:["Today's earnings","مكاسب اليوم","Les gains d'aujourd'hui"],
      sj2:["Yesterday's earnings","مكاسب الأمس","Les gains d'hier"],
      sj3:["Income of this month","دخل هذا الشهر","Revenu de ce mois"],
      sj4:["Last month's earnings","دخل الشهر الماضي","Revenus du mois dernier"],
      sj5:["total revenue","إجمالي الإيرادات","revenu total"],
      sj6:["Number of orchards","بستان الكمية","Nombre de vergers"],
      sj7:["Number of seeds","عدد البذور","Nombre de graines"],
      sj8:["Residual income","فائض الدخل","Revenu résiduel"],
      sj9:["Plantable days","أيام الزراعة","Jours plantables"],
      lang_list: [],
      select_lang: {},
      langs: {},
      cate: [],
    };
  },
  created() {
    this.biaoshi=Math.round(Math.random()*9).toString()
    this.biaoshi1=Math.round(Math.random()*9).toString()
    // let EXPIRESTIME = 30000;
    // let setting1 = local.readInfo("my_data");
    // if (setting1 === null || setting1 === undefined || setting1 === "") {
      this.get_my_data();
    // } else {
    //   let date = new Date().getTime();
    //   // 如果大于就是过期了，如果小于或等于就还没过期
    //   if (date - (setting1.startTime ? setting1.startTime : 0) > EXPIRESTIME) {
    //     this.get_my_data();
    //   } else {
    //     this.data = setting1;
    //     this.block = true;
    //   }
    // }

      this.lang=local.readInfo('setting_lang')==undefined||local.readInfo('setting_lang')==null?0:local.readInfo('setting_lang')
  },
  methods: {
    click_tab:function(index){
      console.log(index)
      if(index==1){
        this.muchang();
      }else if(index==2){
        this.tomy()
      }else if(index==0){
        this.index()
      }
    },
         onConfirm(value, index) {
           console.log(index)
                 console.log(this.type)
           console.log(this.data.user)
            //let baseUrl = "https://api.whatsapp.com/send?phone=";
      this.select_index=index
      if(index==0){
        if(this.type==0){
          if(this.kefu.code==null||this.kefu.code==''||this.kefu.code=='0'||this.kefu.code==undefined){
            Toast(this.langs.tu2)
          }else{
          window.location.href = this.kefu.code;
           }
        }
       if(this.type==1){
          if(this.data.user.yuangong.team_nub==null||this.data.user.yuangong.team_nub==''||this.data.user.yuangong.team_nub=='0'||this.data.user.yuangong.team_nub==undefined){
            Toast(this.langs.tu2)
          }else{
          window.location.href =this.data.user.yuangong.team_nub;
        }
        }
      }
      if(index==1){
        if(this.type==0){
          if(this.kefu.code1==null||this.kefu.code1==''||this.kefu.code1=='0'||this.kefu.code1==undefined){
            Toast(this.langs.tu2)
          }else{
              window.location.href = this.kefu.code1;
          }
        }
       if(this.type==1){
          if(this.data.user.yuangong.team_nub1==null||this.data.user.yuangong.team_nub1==''||this.data.user.yuangong.team_nub1=='0'||this.data.user.yuangong.team_nub1==undefined){
            Toast(this.langs.tu2)
          }else{
          window.location.href =this.data.user.yuangong.team_nub1
           }
        }
      }
      this.showPicker = false;
        },
    get_my_data: function () {
      api.my(
        {
          token: local.readInfo("jwt"),
          lang:local.readInfo('car_lang'),
        },
        (err, data) => {
          if (!err && data.code === 1) {
            this.data = data.data;
            this.block = true;
            var datas = Object.assign(data.data, {
              startTime: new Date().getTime(),
            });
            local.saveInfo("my_data", datas);
            this.lang_list = data.lang_list;
              this.select_lang = data.select_lang;
               this.langs = data.lang;
               this.columns[0]=data.lang.tp11   
               this.columns[1]=data.lang.tp12   
               this.columns1[0]=data.lang.tp11   
               this.columns1[1]=data.lang.tp12   
          } else {
            console.log(data);
            Toast(data.msg);
            this.block = false;
          }
        }
      );
    },
    to_my_info: function (d) {
      let _this = this;
      _this.$router.push({ name: d, query: { redId: 1111 } });
    },
    index: function () {
      let _this = this;
      _this.$router.push({ name: "Home", query: { redId: 1111 } });
    },
    muchang: function () {
      let _this = this;
      _this.$router.push({ name: "farm", query: { redId: 1111 } });
    },
    to_kefu: function () {
     // let baseUrl = "https://api.whatsapp.com/send?phone=";
      api.kf(
        {
          token: local.readInfo("jwt"),
        },
        (err, data) => {
          if (!err && data.code === 1) {

            //window.location.href = baseUrl + data.data.kefu.code;
            this.kefu=data.data.kefu
            this.type=0;
            this.showPicker=true
          } else {
            console.log(data);
            Toast(data.msg);
          }
        }
      );
    },
       to_kefu1: function () {
     // let baseUrl = "https://api.whatsapp.com/send?phone=";
         this.type=1;
          this.showPicker=true
       // window.location.href = baseUrl + this.data.user.yuangong.team_nub;
      
    },
    to_hong: function () {
          this.show1_1=true
    },
    buy11(){
 
    Toast.loading({
        message: "loading...",
        forbidClick: true
      });
       api.all("/qintai_public/api.user1/get_red",{
        token: local.readInfo('jwt'),
        pass:this.pass
        }, (err, data) => {
    
          if (!err && data.code === 1) {
            //Toast(data.msg)
              Dialog.alert({
              message:    data.msg,
              confirmButtonText: this.langs.tp2,
               theme: 'round-button',
              }).then(() => {
              // on close
                this.show1_1=false
              });
         
          }else{
            if(data){
              Dialog.alert({
              message:    data.msg,
              confirmButtonText: this.langs.tp2,
               theme: 'round-button',
              }).then(() => {
              // on close
                this.show1_1=false
              });
            }
          }
        })

  },
    lay_out: function () {
      local.deleteInfo("jwt");
      //Toast("Exit the success");
      Toast(this.langs.t34);

      this.$router.push({ name: "Login", query: { redId: 1111 } });
    },
    close: function () {
      Toast(this.langs.t33);
    },
  },
};
</script>
<style  scoped>
/* .zidinyi1 {
  max-width: 33%;
  overflow: hidden;
} */
.my-swipe .van-swipe-item img {
  width: 100%;
  height: auto;
}
.van-tab1s__nav--card {
  margin: 0 !important;
}
.home222 {
  background:  #f7fbe1;
  max-width: 750px;
  margin: 0 auto;
  overflow: hidden;
}
.content {
  height: auto;
  min-height: 1624px;
  background:  #fff;
  background-size: 100%;
  padding-top: 50px;
}
.header .header2 .code{
  width: 100%;
  box-sizing: border-box;
  padding-left: 4px;
   color:#666;
   text-align: left;
}
.new_tou{
  width:700px;
  height:180px;
  border-bottom:1px solid #9999cc;
  margin:0 auto;
  margin-bottom:30px
}
.new_tou .touxiang1{
  width:100px;
  height: 100px;
  border-radius: 100%;
  border: 2px solid #ff8917;
  overflow: hidden;
  box-sizing: border-box;
}
.new_tou .touxiang1 img{
  width:100%
}
.new_tou .nameli{
  width:550px;
  text-align:left
}
.new_tou .nameli .name{
  color:#003333;
  font-size:24px;
  line-height:40px;
}
.new_tou .nameli .dengji{
  color:#003333;
  font-size:24px;
  line-height:60px;
}
.new_tou .nameli .dengji div:nth-of-type(1){
  width:80px
}
.new_tou .nameli .dengji div:nth-of-type(2){
  width:460px;
  padding-top:16px
}
.new_tou .nameli .dengji div:nth-of-type(2)>>>.van-rate{
  width:480px
}


.new_title{
  width:666px;
  height:85px;
  margin: 0 auto;
  color:152331;
  font-size: 42px;
  font-weight: 600;
  text-align: left;
}
.new_shu{
  margin-bottom: 50px;
}
.new_shu .shuli{
  width:688px;
  height: 110px;
  padding: 30px;
  box-sizing: border-box;
  border:1px solid #9999cc;
  border-radius: 10px;
  margin:0 auto;
  margin-bottom: 20px;
}
.new_shu .shuli .icon{
  width: 52px;
  height: 52px;
}
.new_shu .shuli .icon img{width:100%}
.new_shu .shuli .title{
  text-align: left;
  width: 250px;
  color:003333;
  font-size: 24px;
}
.new_shu .shuli .data{
  text-align: right;
  width: 250px;
  color:003333;
  font-size: 24px;
}

.menuli{
  width:690px;
  height: 85px;
  border-bottom:1px solid #9999cc;
  margin:0 auto;
}
.menuli .title{
  text-align: left;
  width: 600px;
  color:003333;
  font-size: 24px;
  padding-left: 10px;

}
.menuli .link{
  padding-right: 10px;
}











.zijin{
  width:700px;
  height: 225px;
  margin: 0 auto;
  background: rgba(255,255,255,1);;
  border-radius: 25px;
  padding: 5px;
  box-sizing: border-box;
}
.zijin .zijin1{
  width:100%;
  height: 105px;
  border-bottom: 1px solid rgb(181, 181, 181);
}
.zijin .zijin1 .nub{
  width: 59%;
  box-sizing: border-box;
  padding-left:30px;
  background: url('../assets/img1/jb.png') no-repeat;
   background-size: contain;
   color:#666;
   text-align: left;
}
.zijin .zijin1 .caozuo{
  width:40%
}

.zijin .zijin2{
  width:100%;
  height: 105px;
}
.zijin .zijin2 .nub{
  width: 55%;
  box-sizing: border-box;
  padding-left: 30px;
  background: url('../assets/img1/jf.png') no-repeat;
   background-size: contain;
   color:#666;
   text-align: left;
}
.zijin .zijin2 .caozuo{
  width:44%
}

.shuju{
  width:700px;
  height: auto;
  margin: 0 auto;
  background: rgba(255,255,255,.7);
  border-radius: 25px;
  padding: 5px;
  box-sizing: border-box;
  box-shadow: 5px 5px 5px 5px #e3e3e3;
}
.shuju>>>.van-grid-item__content{
  padding: 10px 0px;
  background: rgba(34,177,130,0);
}
.shuju .biaoti{
  color:#666;
  font-size: 18px;
  height: 50px;
}
.shuju .shuzhi{
  color:#ff6034;
  font-size: 32px;
  margin-top:5px

}
.kefu{
  width:700px;
  height: 95px;
  margin: 0 auto;
  background: rgba(255,255,255,1);
  border-radius: 25px;
  padding: 5px 40px;
  box-sizing: border-box;
}
.gongge{
    width:700px;
  margin: 0 auto;
  background: url('../assets/img1/menu_bg2.jpg') no-repeat;
   background-size: 100%;
   height:791px;
   box-sizing: border-box;
   border-radius: 20px;
}
.gongge>>>.van-grid{
  padding-top: 0.2rem;
}
.gongge>>>.van-grid .van-grid-item{
  height:1.72rem
}
.gongge .biaoti{
  color:#666;
  width:40%;
  margin-left: 15%;
  word-break:break-all
}
.gongge>>>.van-grid-item__content{
  padding: 10px 0px;
  background: rgba(34,177,130,0);
}
.gongge>>>.van-grid-item__content .van-image{
  width: 60%;
  margin: 0 auto;
}
/* .home222>>>.van-tabbar{
  background: url('../assets/img1/tab_bg.png') no-repeat;
  background-size: cover;
  padding-top: 1.1rem;
  border: 0!important;
}
.home222>>>.van-tabbar::after{
  border: 0!important;
}
.home222>>>.van-tabbar .van-tabbar-item{
  color:#fff
}
.home222>>>.van-tabbar .van-tabbar-item--active{
  background: rgba(0, 0, 0, 0);
  color:#1989fa
} */
.buy{
  padding: 30px;
}
.buy .name{
  height: 40px;
}
.buy .name .n1{
  color:#333
}
.buy .name .n2{
  color:#999
}
.buy .value{
  height: 80px;
}
.buy .value .n1{
  color:#333
}
.buy .price{
  height: 50px;
}
.buy .price .p1{
  color:#333
}
.buy .price .p2{
  color:red;
  font-size: 28px;
}
.buy .btn{
  width:70%;
  margin: 0 auto;
  margin-top: 20px;
}
.buy>>>.van-stepper__input{
  width:80px
}
.buy >>>.van-password-input{
  margin: 0;
  margin-left: 20px;
}
</style>
