<template>
  <div class="content">
    <van-nav-bar
      :title="text2[lang]"
      :left-text="text1[lang]"
      :right-text="text4[lang]"
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
    />
    <div style="height: 30px"></div>
    <div class="people">
      <van-grid :column-num="2">
        <van-grid-item
          icon="manager"
          :text="text5[lang] + my_data.zt + text13[lang]"
        />
        <van-grid-item
          icon="friends"
          :text="text6[lang] + my_data.zrs"
        />
      </van-grid>
    </div>

    <div style="height: 30px"></div>

    <div class="tuandui_jiaoshui">
      <van-pull-refresh v-model="refreshing" :loading-text="langs.tp13"
    lodding="lodding" @refresh="onRefresh" v-show="is_has" 
    :loosing-text="langs.tp14"
    :pulling-text="langs.tp16">

<van-list v-model="loading" :finished="finished" :finished-text="langs.tp15" @load="onLoad" 
:loading-text="langs.tp13" lodding="lodding" >
          <!-- <van-cell  /> -->

          <div
            class="public_li"
            v-for="(item, index) in list"
            :key="index"
            :title="item"
          >
            <div class="user public_flex">
              <div class="name one_hide">{{text7[lang]}}:{{ item.user.user_name }}</div>
              <div class="date one_hide">{{text8[lang]}}:{{item.create_time}}</div>
            </div>
            <div class="zz public_flex">
              <div class="li1">
                <div class="head">
                  <img :src="item.user.head" />
                </div>
              </div>
              <div class="li2">
                <p>{{text9[lang]}}:{{item.code}}</p>
                <p>{{text10[lang]}}:{{item.sysj}} {{text15[lang]}}</p>
                <p>{{text11[lang]}}:{{ item.yjsy }} {{text16[lang]}}</p>
              </div>
              <div class="li3" :class="[{ jinyong: item.sysj == 0 },{ jinyong: item.is_jiao == 1 }]">
                <van-button round type="success" size="small" color="#ffb53c" @click="detail(item.td_id)"
                  >{{text12[lang]}}</van-button
                >
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>

      <van-empty :description="langs.tp15" v-if="list.length<=0"/>
    </div>

    <div class="shuye">
      <img src="../assets/img1/nc_bg.png" />
    </div>
  </div>
</template>
 <script>
import Vue from "vue";
import { Tab, Tabs } from "vant";
import { List } from "vant";
import { Toast } from "vant";
import * as api from "@/api";
import * as local from "@/libs/local";
import { NavBar } from "vant";
import { Grid, GridItem } from "vant";
Vue.use(List);
Vue.use(Toast);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(NavBar);
Vue.use(Grid);
Vue.use(GridItem);
export default {
  data() {
    return {
      radio: "1",
      is_has: 1,
      active: 1,
      page: 1,
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      user: {},
      my_data: {},
      is_jia: false,
      block: false,
      lang:0,
      text1:["back","رد : .","retour"],
      text2:["Watering team","فريق سقي","Équipe d'arrosage"],
      text4:["Watering record","سجل سقي","Registre d'arrosage"],
      text5:["Direct push","دفع مباشر","membres directs de l'équipe"],
      text6:["The number of team","المجموع","total des membres de l'équipe"],
      text7:["name","الإسم","nom"],
      text8:["date","تواريخ","date"],
      text9:["Growing number","عدد متزايد","Nombre croissant"],
      text10:["For the rest of","الوقت المتبقي","Pour le reste de"],
      text11:["Expected earnings","الإيرادات المتوقعة","Bénéfices attendus"],
      text12:["Go to the water","الذهاب إلى الماء","Aller à l'eau"],
      text13:["People","رجل .","Personnes"],
      text14:["No team grow record","لا فريق سجل النمو","Aucun record de croissance d'équipe"],
      text15:["seconds","ثواني","seconds"],
      text16:["points","نقاط","points"]
    };
  },
  created: function () {
    api.team_init(
      {
        token: local.readInfo("jwt"),
      },
      (err, data) => {
        this.logining = false;
        if (!err && data.code === 1) {
          this.block = true;
          this.user = data.data.user;
          this.my_data = data.data.my_data;
        } else {
          console.log(data);
          if (data && "msg" in data) {
            Toast(data.msg);
          }
          // this.logining = false
        }
      }
    );
      this.lang=local.readInfo('setting_lang')==undefined||local.readInfo('setting_lang')==null?0:local.readInfo('setting_lang')
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1); //返回上一层
    },
    onLoad(name = false) {
      if (this.is_jia) {
        return false;
      }
      this.is_jia = true;
      if (name) {
        this.list = [];
        this.page = 1;
        this.is_has = 1;
        this.finished = false;
      }
      api.team(
        {
          token: local.readInfo("jwt"),
          type: this.active,
          page: this.page,
        },
        (err, data) => {
          this.loading = false;
          this.refreshing = false;
          this.is_jia = false;
          if (!err && data.code === 1) {
            let oldArr = this.list;
            let newArr = data.data.data;
            this.list = oldArr.concat(newArr);
            this.page++;
            if (data.data.last_page - data.data.current_page <= 0) {
              this.finished = true; //this.is_has=0
            }
            if (data.data.total <= 0) {
              this.is_has = 0;
            }
          } else {
            console.log(data);
            if (data && "msg" in data) {
              Toast(data.msg);
            }
            // this.logining = false
          }
        }
      );
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      if (this.logining) {
        return;
      }
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad(true);
    },
    onClickRight: function () {
      let _this = this;
      _this.$router.push({ name: "watering_record", query: { redId: 1111 } });
    },
    onClick(name) {
      // Toast(name + "已被禁用" + title);
      if (this.logining) {
        return;
      }
      this.list = [];
      this.active = name;
      this.onLoad(true);
    },
    detail(id1) {
      let _this = this;
      _this.$router.push({ name: "watering", query: { id: id1 } });
    },
  },
};
</script>

<style  scoped>
.home222 {
  background-color: #79c752;
  max-width: 750px;
  margin: 0 auto;
}
.content {
  width: 100%;
  height: auto;
  min-height: 1624px;
  background: url("../assets/img1/my_bg.jpg") no-repeat #79c752;
  background-size: 100%;
}
.people {
  width: 700px;
  margin: 0 auto;
  background: white;
  border-radius: 20px;
  overflow: hidden;
  height: 280px;
}
.shuye {
  width: 100%;
  height: 122px;
  position: fixed;
  bottom: 0px;
  left: 0;
}
.shuye img {
  width: 100%;
}
.tuandui_jiaoshui {
  width: 700px;
  margin: 0 auto;
  background: white;
  border-radius: 20px;
  overflow: hidden;
  min-height: 380px;
  background: rgba(255, 255, 255, 0.5);
}
.tuandui_jiaoshui .public_li {
  width: 680px;
  margin: 0 auto;
  height: 160px;
  border-radius: 19px;
  padding: 5px;
  box-sizing: border-box;
  margin-top: 10px;
  background: #fff;
  box-shadow: 3px 5px 10px 1px #dbdbdb;
}
.tuandui_jiaoshui .public_li .zz {
  height: 120px;
}
.tuandui_jiaoshui .public_li .li1 {
  width: 80px;
}
.tuandui_jiaoshui .public_li .li1 .head {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  overflow: hidden;
}
.tuandui_jiaoshui .public_li .li1 .head img {
  width: 100%;
}
.tuandui_jiaoshui .public_li .li2 {
  width: 414px;
  padding-left: 16px;
}
.tuandui_jiaoshui .public_li .li2 p {
  color: #666;
  text-align: left;
  font-size: 15px;
  line-height: 22px;
}
.tuandui_jiaoshui .public_li .li3 {
  width: 160px;
}
.tuandui_jiaoshui .public_li .user {
  height: 30px;
  border-bottom: 1px solid rgb(219, 216, 216);
}
.tuandui_jiaoshui .public_li .user div {
  color: #ff8917;
  width: 50%;
}
.tuandui_jiaoshui .public_li .user .name {
  text-align: left;
}
.tuandui_jiaoshui .public_li .user .date {
  text-align: right;
}
</style>